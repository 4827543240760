import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetChatBotsBaseVariables, FirstChatBotsBaseVariables, CountChatBotsBaseVariables, FindChatBotsBaseVariables, SearchChatBotsBaseVariables, ExportSchemaChatBotsBaseVariables, ExportDataChatBotsBaseVariables, CustomQueryChatBotsBaseVariables, CustomQueryIdChatBotsBaseVariables, UsedChatBotsBaseVariables, DisplayChatBotChatBotsBaseVariables, HasAiToolsChatBotsBaseVariables, ExistChatBotsBaseVariables, FindRecyclesChatBotsBaseVariables, CountRecyclesChatBotsBaseVariables, ReadOnlyChatBotsBaseVariables, FindArchivedChatBotsBaseVariables, CountAllChatBotsBaseVariables, FindDynamicPropertyFieldsChatBotsBaseVariables, InsertChatBotsBaseVariables, UpdateChatBotsBaseVariables, ImportDataChatBotsBaseVariables, UpdateManyChatBotsBaseVariables, DeleteChatBotsBaseVariables, WikitExpandChatBotsBaseVariables, WikitCorrectChatBotsBaseVariables, WikitTranslateChatBotsBaseVariables, WikitShortenChatBotsBaseVariables, WikitSimplifyChatBotsBaseVariables, WikitChangeToneChatBotsBaseVariables, WikitTranslateSpecificLanguageChatBotsBaseVariables, RestoreChatBotsBaseVariables, RecycleChatBotsBaseVariables, RestoreArchivedChatBotsBaseVariables, ArchivedChatBotsBaseVariables, AddFileDynamicFieldChatBotsBaseVariables, RemoveFileDynamicFieldChatBotsBaseVariables } from '../gqls'
import { GetChatBotsDocument, FirstChatBotsDocument, CountChatBotsDocument, FindChatBotsDocument, SearchChatBotsDocument, ExportSchemaChatBotsDocument, ExportDataChatBotsDocument, CustomQueryChatBotsDocument, CustomQueryIdChatBotsDocument, UsedChatBotsDocument, DisplayChatBotChatBotsDocument, HasAiToolsChatBotsDocument, ExistChatBotsDocument, FindRecyclesChatBotsDocument, CountRecyclesChatBotsDocument, ReadOnlyChatBotsDocument, FindArchivedChatBotsDocument, CountAllChatBotsDocument, FindDynamicPropertyFieldsChatBotsDocument, InsertChatBotsDocument, UpdateChatBotsDocument, ImportDataChatBotsDocument, UpdateManyChatBotsDocument, DeleteChatBotsDocument, WikitExpandChatBotsDocument, WikitCorrectChatBotsDocument, WikitTranslateChatBotsDocument, WikitShortenChatBotsDocument, WikitSimplifyChatBotsDocument, WikitChangeToneChatBotsDocument, WikitTranslateSpecificLanguageChatBotsDocument, RestoreChatBotsDocument, RecycleChatBotsDocument, RestoreArchivedChatBotsDocument, ArchivedChatBotsDocument, AddFileDynamicFieldChatBotsDocument, RemoveFileDynamicFieldChatBotsDocument } from '../gqls'
import { ServiceSingleResultOfChatBot, QueryContextOfChatBot, FilterOfChatBot, ServiceSingleResultOfInt64, ServiceListResultOfChatBot, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfChatBot, ChatBotInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ChatBotBaseService {
    
public modelName = 'chatBot';
public modelPluralName = 'chatBots';

	private getChatBotsQuery: GetChatBotsDocument;
	private firstChatBotsQuery: FirstChatBotsDocument;
	private countChatBotsQuery: CountChatBotsDocument;
	private findChatBotsQuery: FindChatBotsDocument;
	private searchChatBotsQuery: SearchChatBotsDocument;
	private exportSchemaChatBotsQuery: ExportSchemaChatBotsDocument;
	private exportDataChatBotsQuery: ExportDataChatBotsDocument;
	private customQueryChatBotsQuery: CustomQueryChatBotsDocument;
	private customQueryIdChatBotsQuery: CustomQueryIdChatBotsDocument;
	private usedChatBotsQuery: UsedChatBotsDocument;
	private displayChatBotChatBotsQuery: DisplayChatBotChatBotsDocument;
	private hasAiToolsChatBotsQuery: HasAiToolsChatBotsDocument;
	private existChatBotsQuery: ExistChatBotsDocument;
	private findRecyclesChatBotsQuery: FindRecyclesChatBotsDocument;
	private countRecyclesChatBotsQuery: CountRecyclesChatBotsDocument;
	private readOnlyChatBotsQuery: ReadOnlyChatBotsDocument;
	private findArchivedChatBotsQuery: FindArchivedChatBotsDocument;
	private countAllChatBotsQuery: CountAllChatBotsDocument;
	private findDynamicPropertyFieldsChatBotsQuery: FindDynamicPropertyFieldsChatBotsDocument;
	private insertChatBotsMutation: InsertChatBotsDocument;
	private updateChatBotsMutation: UpdateChatBotsDocument;
	private importDataChatBotsMutation: ImportDataChatBotsDocument;
	private updateManyChatBotsMutation: UpdateManyChatBotsDocument;
	private deleteChatBotsMutation: DeleteChatBotsDocument;
	private wikitExpandChatBotsMutation: WikitExpandChatBotsDocument;
	private wikitCorrectChatBotsMutation: WikitCorrectChatBotsDocument;
	private wikitTranslateChatBotsMutation: WikitTranslateChatBotsDocument;
	private wikitShortenChatBotsMutation: WikitShortenChatBotsDocument;
	private wikitSimplifyChatBotsMutation: WikitSimplifyChatBotsDocument;
	private wikitChangeToneChatBotsMutation: WikitChangeToneChatBotsDocument;
	private wikitTranslateSpecificLanguageChatBotsMutation: WikitTranslateSpecificLanguageChatBotsDocument;
	private restoreChatBotsMutation: RestoreChatBotsDocument;
	private recycleChatBotsMutation: RecycleChatBotsDocument;
	private restoreArchivedChatBotsMutation: RestoreArchivedChatBotsDocument;
	private archivedChatBotsMutation: ArchivedChatBotsDocument;
	private addFileDynamicFieldChatBotsMutation: AddFileDynamicFieldChatBotsDocument;
	private removeFileDynamicFieldChatBotsMutation: RemoveFileDynamicFieldChatBotsDocument;

	constructor(private injector: Injector) {
		this.getChatBotsQuery = this.injector.get(GetChatBotsDocument);
		this.firstChatBotsQuery = this.injector.get(FirstChatBotsDocument);
		this.countChatBotsQuery = this.injector.get(CountChatBotsDocument);
		this.findChatBotsQuery = this.injector.get(FindChatBotsDocument);
		this.searchChatBotsQuery = this.injector.get(SearchChatBotsDocument);
		this.exportSchemaChatBotsQuery = this.injector.get(ExportSchemaChatBotsDocument);
		this.exportDataChatBotsQuery = this.injector.get(ExportDataChatBotsDocument);
		this.customQueryChatBotsQuery = this.injector.get(CustomQueryChatBotsDocument);
		this.customQueryIdChatBotsQuery = this.injector.get(CustomQueryIdChatBotsDocument);
		this.usedChatBotsQuery = this.injector.get(UsedChatBotsDocument);
		this.displayChatBotChatBotsQuery = this.injector.get(DisplayChatBotChatBotsDocument);
		this.hasAiToolsChatBotsQuery = this.injector.get(HasAiToolsChatBotsDocument);
		this.existChatBotsQuery = this.injector.get(ExistChatBotsDocument);
		this.findRecyclesChatBotsQuery = this.injector.get(FindRecyclesChatBotsDocument);
		this.countRecyclesChatBotsQuery = this.injector.get(CountRecyclesChatBotsDocument);
		this.readOnlyChatBotsQuery = this.injector.get(ReadOnlyChatBotsDocument);
		this.findArchivedChatBotsQuery = this.injector.get(FindArchivedChatBotsDocument);
		this.countAllChatBotsQuery = this.injector.get(CountAllChatBotsDocument);
		this.findDynamicPropertyFieldsChatBotsQuery = this.injector.get(FindDynamicPropertyFieldsChatBotsDocument);
		this.insertChatBotsMutation = this.injector.get(InsertChatBotsDocument);
		this.updateChatBotsMutation = this.injector.get(UpdateChatBotsDocument);
		this.importDataChatBotsMutation = this.injector.get(ImportDataChatBotsDocument);
		this.updateManyChatBotsMutation = this.injector.get(UpdateManyChatBotsDocument);
		this.deleteChatBotsMutation = this.injector.get(DeleteChatBotsDocument);
		this.wikitExpandChatBotsMutation = this.injector.get(WikitExpandChatBotsDocument);
		this.wikitCorrectChatBotsMutation = this.injector.get(WikitCorrectChatBotsDocument);
		this.wikitTranslateChatBotsMutation = this.injector.get(WikitTranslateChatBotsDocument);
		this.wikitShortenChatBotsMutation = this.injector.get(WikitShortenChatBotsDocument);
		this.wikitSimplifyChatBotsMutation = this.injector.get(WikitSimplifyChatBotsDocument);
		this.wikitChangeToneChatBotsMutation = this.injector.get(WikitChangeToneChatBotsDocument);
		this.wikitTranslateSpecificLanguageChatBotsMutation = this.injector.get(WikitTranslateSpecificLanguageChatBotsDocument);
		this.restoreChatBotsMutation = this.injector.get(RestoreChatBotsDocument);
		this.recycleChatBotsMutation = this.injector.get(RecycleChatBotsDocument);
		this.restoreArchivedChatBotsMutation = this.injector.get(RestoreArchivedChatBotsDocument);
		this.archivedChatBotsMutation = this.injector.get(ArchivedChatBotsDocument);
		this.addFileDynamicFieldChatBotsMutation = this.injector.get(AddFileDynamicFieldChatBotsDocument);
		this.removeFileDynamicFieldChatBotsMutation = this.injector.get(RemoveFileDynamicFieldChatBotsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChatBot> {
    
    		let variables: GetChatBotsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.get));
            }
            else{
                let result:ServiceSingleResultOfChatBot={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChatBot,
		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChatBot> {
    
    		let variables: FirstChatBotsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountChatBotsBaseVariables = {
    			filter: filter
    		}
    				return this.countChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChatBot,
		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChatBot> {
    
    		let variables: FindChatBotsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfChatBot,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChatBot> {
    
    		let variables: SearchChatBotsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaChatBotsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataChatBotsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfChatBot,
		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChatBot> {
    
    		let variables: CustomQueryChatBotsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfChatBot,
		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChatBot> {
    
    		let variables: CustomQueryIdChatBotsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedChatBotsBaseVariables = {
    			ids: ids
    		}
    				return this.usedChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.used));
    	}

    	/**  */
    	@InjectArgs
    	public displayChatBot(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('forHelpMe') forHelpMe: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChatBot> {
    
    		let variables: DisplayChatBotChatBotsBaseVariables = {
    			forHelpMe: forHelpMe
    		}
    				return this.displayChatBotChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.displayChatBot));
    	}

    	/**  */
    	@InjectArgs
    	public hasAiTools(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: HasAiToolsChatBotsBaseVariables = {
    
    		}
    				return this.hasAiToolsChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.hasAiTools));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistChatBotsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChatBot,
		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChatBot> {
    
    		let variables: FindRecyclesChatBotsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesChatBotsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyChatBotsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfChatBot,
		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfChatBot> {
    
    		let variables: FindArchivedChatBotsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllChatBotsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsChatBotsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsChatBotsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ChatBotInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChatBot> {
    
    		let variables: InsertChatBotsBaseVariables = {
    			entity: entity
    		}
    				return this.insertChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfChatBot> {
    
    		let variables: UpdateChatBotsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataChatBotsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfChatBot,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyChatBotsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteChatBotsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.delete));
    	}

    	/**  */
    	@InjectArgs
    	public wikitExpand(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitExpandChatBotsBaseVariables = {
    			input: input
    		}
    				return this.wikitExpandChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitExpand));
    	}

    	/**  */
    	@InjectArgs
    	public wikitCorrect(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitCorrectChatBotsBaseVariables = {
    			input: input
    		}
    				return this.wikitCorrectChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitCorrect));
    	}

    	/**  */
    	@InjectArgs
    	public wikitTranslate(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitTranslateChatBotsBaseVariables = {
    			input: input
    		}
    				return this.wikitTranslateChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitTranslate));
    	}

    	/**  */
    	@InjectArgs
    	public wikitShorten(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitShortenChatBotsBaseVariables = {
    			input: input
    		}
    				return this.wikitShortenChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitShorten));
    	}

    	/**  */
    	@InjectArgs
    	public wikitSimplify(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitSimplifyChatBotsBaseVariables = {
    			input: input
    		}
    				return this.wikitSimplifyChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitSimplify));
    	}

    	/**  */
    	@InjectArgs
    	public wikitChangeTone(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('tone?') tone?: string,
		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitChangeToneChatBotsBaseVariables = {
    			input: input,
			tone: tone
    		}
    				return this.wikitChangeToneChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitChangeTone));
    	}

    	/**  */
    	@InjectArgs
    	public wikitTranslateSpecificLanguage(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('lang?') lang?: string,
		@Args('input?') input?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: WikitTranslateSpecificLanguageChatBotsBaseVariables = {
    			input: input,
			lang: lang
    		}
    				return this.wikitTranslateSpecificLanguageChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.wikitTranslateSpecificLanguage));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreChatBotsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleChatBotsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedChatBotsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedChatBotsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldChatBotsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldChatBotsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldChatBotsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.chatBots.removeFileDynamicField));
    	}
    
}