import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportFileFormat } from '@clarilog/core';
import { ValidationError } from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { DxFileUploaderComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export type ImportDataItem = { id: string; name: string };

@Component({
  selector: 'clc-import-data-popup',
  templateUrl: './import-data-popup.component.html',
})
export class CoreImportDataPopupComponent implements OnInit {
  importSource: ImportDataItem[];
  title: string;

  exportSchemaFn: any;
  importDataFn: any;
  fileSuffix: string = '';
  @Input() serviceName: string;

  popupVisible: boolean = false;

  //chargement de l'import
  loadPanelShow: boolean = false;

  @Input()
  downloadSchema: boolean = false;

  @Input()
  service: any;

  @ViewChild(DxFileUploaderComponent, { static: true })
  fileUploader: DxFileUploaderComponent;

  @Output()
  onSuccess: EventEmitter<any> = new EventEmitter();

  errors: ValidationError[] = [];

  public SetSuffix(value: string) {
    this.fileSuffix = value;
  }

  public open() {
    this.popupVisible = true;
    this.errors.clear();
    this.fileUploader.instance.reset();
  }

  public import(e) {
    switch (e.itemData.id) {
      case 'importSchema':
        this.exportSchemaTemplate();
        break;
      case 'importData':
        this.open();
        break;
    }
  }

  private toCamelCase(str: string): string {
    return str[0].toLowerCase() + str.substr(1, str.length - 1);
  }

  public addInstructionToFillForm(
    defaultName: string,
    filedToTranslate: string,
  ) {
    let listFieldWithInstruction = ['HelpDeskActivated'];
    let exist = listFieldWithInstruction.includes(filedToTranslate);
    if (exist) {
      filedToTranslate = filedToTranslate + 'Instruction';
    }
    return TranslateService.get(
      'entities/' + defaultName + '/' + this.toCamelCase(filedToTranslate),
    );
  }
  private s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  public exportSchemaTemplate() {
    let format = ImportFileFormat.Csv;
    if (
      location.pathname.includes('assets') ||
      location.pathname.includes('inventory/scan-configurations') ||
      location.pathname.includes('article-knowledge') ||
      location.pathname.includes('modelManufacturer') ||
      location.pathname.includes('supplier') ||
      location.pathname.includes('contract') ||
      location.pathname.includes('asset-models') ||
      location.pathname.includes('ticket-categories') ||
      location.pathname.includes('channels') ||
      location.pathname.includes('impacts') ||
      location.pathname.includes('priorities') ||
      location.pathname.includes('resolution-categories') ||
      location.pathname.includes('urgencies') ||
      location.pathname.includes('ticket-status') ||
      location.pathname.includes('task-status') ||
      location.pathname.includes('/incidents') ||
      location.pathname.includes('/problems') ||
      location.pathname.includes('/requests') ||
      location.pathname.includes('/users') ||
      location.pathname.includes('/status-reasons') ||
      location.pathname.includes('/favorite-tickets') ||
      location.pathname.includes('/tasks') ||
      location.pathname.includes('/request') ||
      location.pathname.includes('/organizational-units') ||
      location.pathname.includes('/locations') ||
      location.pathname.includes('activities') ||
      location.pathname.includes('stocks/in-stocks/edit') ||
      location.pathname.includes('assets/settings/cost-center') ||
      location.pathname.includes('news') ||
      location.pathname.includes('stocks/stock-transfer-reason') ||
      location.pathname.includes('/rule/location-charters') ||
      location.pathname.includes('softwares/softwareGroups') ||
      location.pathname.includes('service-desk/days-off') ||
      location.pathname.includes('financials/budgets') ||
      location.pathname.includes('access/operator-team') ||
      location.pathname.includes('access/populations') ||
      location.pathname.includes('/origins') ||
      location.pathname.includes('/message-model') ||
      location.pathname.includes('/concerned-projects') ||
      location.pathname.includes('/warning-message')
    ) {
      format = ImportFileFormat.Xlsx;
    }

    this.exportSchemaFn(
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      format,
    ).subscribe((res) => {
      const element = document.createElement('a');
      const serviceName =
        this.serviceName == undefined
          ? this.service.modelName.replace(/Service$/gm, '')
          : this.serviceName;
      // ''
      let type =
        format === ImportFileFormat.Xlsx
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          : 'text/csv;charset=utf-16le';
      let ext = format === ImportFileFormat.Xlsx ? '.xlsx' : '.csv';
      const blob = new Blob(
        format === ImportFileFormat.Xlsx
          ? [this.s2ab(atob(res.data))]
          : ['\uFEFF' + atob(res.data)],
        { type: type },
      );
      const date = new Date();
      const dateString =
        date.getFullYear() +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        ('0' + date.getDate()).slice(-2) +
        ('0' + date.getHours()).slice(-2) +
        ('0' + date.getMinutes()).slice(-2);

      element.href = URL.createObjectURL(blob);
      element.download =
        serviceName +
        (this.fileSuffix !== '' ? '_' + this.fileSuffix : '') +
        '_import_' +
        dateString +
        ext;
      document.body.appendChild(element);
      element.click();
      setTimeout(() => {
        element.remove();
      });
    });
  }

  // preview maybe popup
  uploadFile(e) {
    let fileType: ImportFileFormat;
    if ((e.value as Array<any>).length === 0) {
      return;
    }
    if (
      (e.value[0].type as string).includes('csv') ||
      (e.value[0].type as string).includes('application/vnd.ms-excel') ||
      (e.value[0].name as string).endsWith('csv')
    ) {
      fileType = ImportFileFormat.Csv;
    } else if (
      (e.value[0].type as string).includes('openxmlformats-officedocument') ||
      (e.value[0].name as string).endsWith('xls') ||
      (e.value[0].name as string).endsWith('xlsx')
    ) {
      fileType = ImportFileFormat.Xlsx;
    }

    if (e.value && e.value[0] && fileType != undefined) {
      this.loadPanelShow = true;
      let reader = new FileReader();
      reader.onload = () => {
        let file;
        switch (fileType) {
          case ImportFileFormat.Csv:
            let dataArray = reader.result.toString().split('\n');
            dataArray.splice(0, 1);
            file = btoa(unescape(encodeURIComponent(dataArray.join('\n'))));
            break;
          case ImportFileFormat.Xlsx:
            file = new Uint8Array(reader.result as ArrayBuffer);
            file = btoa((file as Uint8Array).join(' '));
            break;
          default:
            notify('Format de fichier non pris en charge', 'error', 5000);
            return;
        }

        this.importDataFn(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          fileType,
          file,
        )
          .pipe(
            catchError((val) => {
              this.fileUploader.instance.reset();

              this.loadPanelShow = false;
              this.popupVisible = false;

              notify(val, 'error', 5000);
              return of(`I caught: ${val}`);
            }),
          )
          .subscribe((res) => {
            this.fileUploader.instance.reset();

            this.loadPanelShow = false;

            if (res.errors != undefined && res.errors.length > 0) {
              this.errors = res.errors;
              notify(res.errors[0].messageError, 'error', 5000);
              this.onSuccess.emit();
            } else {
              notify('Données importées correctement', 'success', 5000);
              this.popupVisible = false;
              this.onSuccess.emit();
            }
          });
      };
      switch (fileType) {
        case ImportFileFormat.Csv:
          reader.readAsText(e.value[0]);
          break;
        case ImportFileFormat.Xlsx:
          reader.readAsArrayBuffer(e.value[0]);
          break;
        default:
          notify('Format de fichier non pris en charge', 'error', 5000);
          return;
      }
    }
  }

  constructor(private route: ActivatedRoute, private router: Router) {
    this.importSource = [
      {
        id: 'importSchema',
        name: TranslateService.get('globals/getImportSchema'),
      },
      {
        id: 'importData',
        name: TranslateService.get('globals/importData'),
      },
    ];
    this.title = TranslateService.get('globals/fileImport');
  }

  /** Permet de définir le service d'import export */

  public setService(svc, bind: boolean = true) {
    this.service = svc;
    if (bind === true) {
      this.exportSchemaFn = this.service.exportSchema.bind(this.service);
      this.importDataFn = this.service.importData.bind(this.service);
    }
  }

  ngOnInit() {
    if (
      this.service != undefined &&
      this.service.exportSchema != undefined &&
      this.service.importData != undefined
    ) {
      this.exportSchemaFn = this.service.exportSchema.bind(this.service);
      this.importDataFn = this.service.importData.bind(this.service);
    }
    this.fileUploader.onValueChanged.subscribe((e) => {
      this.uploadFile(e);
    });
  }

  public changeTitle(title: string) {
    this.title = TranslateService.get('globals/fileImport') + ': ' + title;
  }
}
