import {
  Component,
  Injector,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { EnvironmentService } from '@clarilog/core';
import { SubFormNewMenuItem } from '@clarilog/shared2';
import { Column } from '@clarilog/shared2/models/schema';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { DxTemplateHost } from 'devextreme-angular';
import { camelCase, indexOf } from 'lodash';

import {
  CoreAuthorizationSettings,
  CorePolicyValidator,
} from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import {
  NotificationCoreService,
  WorkflowCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { TicketCoreService } from '@clarilog/core/services2/graphql/generated-types/services/ticket.service';
import {
  ConsumableMovement,
  MailboxObserverState,
  Notification,
  WarningMessageCategory,
  ClarilogServerEmailAction,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { TemplatesService } from '@clarilog/shared2/components/templates/templates.service';
import * as pluralize from 'pluralize';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

/** Représente un composant de gestion des templates. */
@Component({
  selector: 'clc-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  providers: [DxTemplateHost],
})
export class CoreTemplatesComponent {
  console = console;
  /** Permet de mettre en cache les templates. */
  _cache: { [key: string]: TemplateRef<any> } = {};
  /** Obtient ou définit la listes des templates. */
  @ViewChildren(TemplateRef) templates: QueryList<TemplateRef<any>>;

  mentions;

  getAllAction: SubFormNewMenuItem[];

  constructor(
    private injector: Injector,
    private _envService: EnvironmentService,
    private policyValidatorTicket: CorePolicyValidator,
    public translatedFieldHelperService: TranslatedFieldHelperService,
    public templatesService: TemplatesService,
    public ticketCoreService: TicketCoreService,
    public notificationService: NotificationCoreService,
    public router: Router,
    public serializer: UrlSerializer,
    public workflowCoreService: WorkflowCoreService,
    private localStorageService: LocalStorageService,
  ) {
    this.mentions = this.ticketCoreService.findMentionEmail();
  }

  manageNotificationOpening(notification: Notification) {
    let res = this.notificationService.manageNotificationOpening(
      this.router,
      notification,
      this.serializer,
      true,
    );
    return res;
  }

  /** Génère l'url. */
  generateUrl(value: string, externalUrl: boolean): string {
    if (externalUrl) {
      if (value != undefined && value.toLocaleLowerCase().indexOf('http') < 0) {
        value = 'http://' + value;
      }
      return value;
    } else {
      return `${window.location.protocol}//${value}.${this._envService.baseURL}:${window.location.port}`;
    }
  }

  /** Récupère un template via son id. */
  get(name: string): TemplateRef<any> {
    if (name == undefined) return undefined;
    if (this._cache[name] === undefined) {
      let templates = this.templates.toArray().filter(
        (
          f: TemplateRef<any> & {
            _declarationTContainer: { localNames: [any] };
          },
        ) => {
          if (
            f._declarationTContainer.localNames != undefined &&
            f._declarationTContainer.localNames.includes != undefined
          ) {
            return f._declarationTContainer.localNames.includes(name);
          }
        },
      );
      this._cache[name] = templates.length > 0 ? templates[0] : undefined;
    }
    return this._cache[name];
  }

  navigate(data: any, column: Column) {
    if (data != undefined && data['allowSelection'] === false) {
      // Verrou de creation si non sélectionnable
      return;
    }

    let router = this.injector.get(Router);
    let route = this.injector.get(ActivatedRoute);

    let segments = router.parseUrl(router.url).root.children.primary.segments;

    let url: string = '';
    for (let i = 0; i < segments.length; i++) {
      url = `${url}${
        router.parseUrl(router.url).root.children.primary.segments[i].path
      }/`;
    }
    let queryParams = {};
    queryParams[column.parentIdExpr] = data[column.keyExpr];

    router.navigate([`${url}/new`], {
      queryParams: queryParams,
      relativeTo: route,
    });
  }

  getCurrentUrl() {
    let router = this.injector.get(Router);
    return router.url;
  }

  getCurrentBlankUrl() {
    let router = this.injector.get(Router);
    let segments = router.parseUrl(router.url).root.children.primary.segments;
    let url: string = '';
    for (let i = 0; i < segments.length; i++) {
      url = `${url}${
        router.parseUrl(router.url).root.children.primary.segments[i].path
      }/`;
    }
    return url;
  }

  public displayWithoutHtml(value: string): String {
    let search = '</p>';
    let replaceWith = ' </p>';
    if (value != undefined && value != null) {
      value = value.toString();

      if (value.includes('</p>')) {
        value = value.split(search).join(replaceWith);
      }
      let contentHTML = new DOMParser().parseFromString(value, 'text/html')
        .documentElement.textContent;
      return contentHTML;
    }
    return '';
  }

  itemClick(e, data: any, column: Column, component: any) {
    let router = this.injector.get(Router);
    let route = this.injector.get(ActivatedRoute);

    let segments = router.parseUrl(router.url).root.children.primary.segments;
    let url: string = '';
    for (let i = 0; i < segments.length; i++) {
      url = `${url}${
        router.parseUrl(router.url).root.children.primary.segments[i].path
      }/`;
    }
    let queryParams = {
      ...route.root.snapshot.queryParams,
    };
    if (column.parentIdExpr != undefined) {
      queryParams[column.parentIdExpr] = data[column.keyExpr];
      component.addRowExpandedTreeList(data[column.keyExpr]);
    }
    if (column.targetIdExpr != undefined) {
      queryParams[column.targetIdExpr] = e.itemData[column.keyExpr];
    }
    router.navigate([`${url}/new`], {
      queryParams: queryParams,
      relativeTo: route,
    });
  }

  /** Permet d'arrondir ou d'avoir 2 décimal si la aleur est < 10 */
  roundValue(value) {
    let round = Math.round(value);
    let fixed = value.toFixed(2);
    if (round == fixed) {
      return round;
    }

    return fixed;
  }

  /** Permet de convertir la date de fin de garantie  */
  convertUTCToDate(e, format: string, forceNull: boolean = false) {
    if (e != undefined) {
      const isoDate = new Date(e).toISOString();
      let date = new Date(isoDate.substring(0, 10));
      return date.toLocaleDateString();
    }
  }

  /** Permet de convertir une taille en valeur affichées  */
  convertToOctet(e, format: string, forceNull: boolean = false) {
    if (e != undefined) {
      //Permet de convertir la valeur initial
      if (format == 'KiloOctet' && e > 0) {
        //PAsse la valeur d'octet en Kilo-octet
        e = e * 1024;
      }
      if (format == 'MegaOctet' && e > 0) {
        //PAsse la valeur d'octet en Kilo-octet
        e = e * 1024 * 1024;
      }

      if (e == 0) {
        if (forceNull) {
          return ' 0 o';
        }
        return ' ';
      } else if (e < 1024) {
        // octet

        return this.roundValue(e) + ' o';
      } else if (e < 1024 * 1024) {
        // Ko
        return this.roundValue(e / 1024) + ' Ko';
      } else if (e < 1024 * 1024 * 1024) {
        // Mo
        return this.roundValue(e / 1024 / 1024) + ' Mo';
      } else {
        // Go
        return this.roundValue(e / 1024 / 1024 / 1024) + ' Go';
      }
    }

    if (forceNull) {
      return ' 0 o';
    }
    return ' ';
  }

  /** Permet de convertir une vitesse en valeur affichées  */
  convertToSpeed(e, format: string) {
    let unit = 'Hz';
    if (e != undefined) {
      if (format == 'bits' && e > 0) {
        //PAsse la valeur d'octet en Kilo-octet
        unit = 'bit/s';
        if (e == 0) {
          return '0 Hz';
        } else if (e < 1000000) {
          return e + ' ' + unit;
        } else if (e < 1000000000) {
          return (e / 1000000).toFixed(0) + ' M' + unit;
        } else {
          return (e / 1000000000).toFixed(0) + ' G' + unit;
        }
      } else {
        if (e == 0) {
          return '0 Hz';
        } else if (e < 1000) {
          return e + ' M' + unit;
        } else if (e < 1000 * 1000) {
          return (e / 1000).toFixed(2) + ' G' + unit;
        }
      }
    }

    return '0 Hz';
  }

  DisplayAmountOutStock(data): string {
    let amountValue: string = '';
    let getField = data.column.dataField.split('.')[1];
    data.data.carts.map((element) => {
      amountValue = element[getField];
    });
    return amountValue;
  }

  /** Permet de valider le code couleur pour la progression */
  getPercentColor(value, data, format) {
    if (value != undefined) {
      if (data?.colorCode != undefined) {
        switch (data?.colorCode) {
          case '1':
            return '#BAF8F8';
          case '2':
            return '#F8BAF8';
          case '3':
            return '#FFFBBB';
          case '4':
            return '#000';
        }
      } else {
        if (format == 'Disk') {
          return value > 90
            ? '#BA4D51'
            : getComputedStyle(document.documentElement).getPropertyValue(
                '--primary',
              ); // "#45A7B9";
        }
      }
    }
    return getComputedStyle(document.documentElement).getPropertyValue(
      '--primary',
    ); //#45A7B9";
  }

  /** Calcul de la valeur */
  getPercentValue(value, data) {
    let valuePercent = 0;
    if (data?.maxLevel != undefined && value != undefined) {
      valuePercent = (value * data?.maxLevel) / 100;
    } else {
      valuePercent = value;
    }
    if (valuePercent > 100) {
      valuePercent = 100;
    }
    return valuePercent;
  }

  /** Permet de convertir une valeur seconde en Jour  Heure Minute */
  milliSecondToTime(e) {
    if (e != undefined) {
      var seconds = e / 1000;

      var days = Math.floor(seconds / (3600 * 24));
      seconds -= days * 3600 * 24;
      var hrs = Math.floor(seconds / 3600);
      seconds -= hrs * 3600;
      var mnts = Math.floor(seconds / 60);
      seconds -= mnts * 60;
      let result = '';
      if (days > 0) {
        result = result + days + ' ' + TranslateService.get('globals/days');
      }
      if (hrs > 0) {
        result =
          result + ' ' + hrs + ' ' + TranslateService.get('globals/hours');
      }
      if (mnts > 0) {
        result =
          result + ' ' + mnts + ' ' + TranslateService.get('globals/minutes');
      }
      if (seconds > 0) {
        result =
          result +
          ' ' +
          Math.round(seconds) +
          ' ' +
          TranslateService.get('globals/seconds');
      }

      return result;
    }
  }

  /** Permet de verifier si notre value n'est pas polluer (spe: tagbox workflow)*/
  getDisplay(e: string): string {
    if (e != undefined && e.indexOf('[_') > 0) {
      // Récupération de la variable

      let key = e.substring(e.indexOf('[_') + 2);
      key = key.substring(0, key.length - 1);
      let findMention = this.mentions.filter((s) => s.key == key);
      if (findMention != undefined && findMention.length > 0) {
        return TranslateService.get(findMention[0].translate);
      }

      return e.substring(0, e.indexOf('[_'));
    }
    return e.trim();
  }

  /** Permet de vérifier si l'agent est toujours connecté */
  stateAgent(clarilogServer) {
    if (clarilogServer != undefined) {
      return this.isConnectedAgent(clarilogServer);
    }

    return false;
  }
  /** Permet de vérifier si l'agent est toujours connecté */
  isConnectedAgent(data) {
    if (data != undefined && data?.data != undefined) {
      if (data?.data?.isConnected != undefined) {
        return data?.data?.isConnected;
      }
      if (
        data?.data?.clarilogServer != undefined &&
        data?.data?.clarilogServer?.data != undefined &&
        data?.data?.clarilogServer?.data?.isConnected != undefined
      ) {
        return data?.data?.clarilogServer?.data?.isConnected;
      }
      if (
        data?.data?.clarilogServer != undefined &&
        data?.data?.clarilogServer.isConnected != undefined
      ) {
        return data?.data?.clarilogServer.isConnected;
      }
      if (
        data?.data?.clarilogLocalAgent != undefined &&
        data?.data?.clarilogLocalAgent.isConnected != undefined
      ) {
        return data?.data?.clarilogLocalAgent.isConnected;
      }
      if (
        data?.data?.clarilogLocalAgent != undefined &&
        data?.data?.clarilogLocalAgent.isConnected != undefined
      ) {
        return data?.data?.clarilogLocalAgent.isConnected;
      }
    } else if (data != undefined && data?.isConnected != undefined) {
      return data?.isConnected;
    }
    return false;
  }

  getAttachment(e) {
    return `/file/viewer/${e}`; //'file/viewer/' `${this._envService.apiURL}/file/Viewer/${e}`;
  }

  fromTimespanToTime(value) {
    let hours: number = 0;
    let minutes: number = 0;
    let secondes: number = 0;
    let days: number = 0;
    let minus: boolean;

    let regex =
      /^([+-])?P(?!$)(\d+Y)?(\d+M)?(\d+W)?(\d+D)?(T(?=\d*\.?\d*[DHMS])(\d*\.?\d*D)?(\d*\.?\d*H)?(\d*\.?\d*M)?(\d*\.?\d*S)?)?$/gm;
    let m = regex.exec(value.value);
    if (m != undefined) {
      if (m[5] != undefined) {
        days = Number(m[5].split('D')[0]);
      }
      if (m[8] != undefined) {
        hours = Number(m[8].split('H')[0]);
      }
      if (m[9] != undefined) {
        minutes = Number(m[9].split('M')[0]);
      }
      if (m[10] != undefined) {
        secondes = Number(m[10].split('S')[0].split('.')[0]);
      }
      if (m[1] != undefined) {
        minus = m[1] === '+' ? false : true;
      }
    }
    let dateTimeObject = {
      daysValue: days,
      hoursValue: hours,
      minutesValue: minutes,
    };
    return dateTimeObject;
  }

  fromTimespanToMinutes(value) {
    let daysHoursMinutes = this.fromTimespanToTime(value);
    let minutes =
      (daysHoursMinutes.daysValue * 24 + daysHoursMinutes.hoursValue) * 60 +
      daysHoursMinutes.minutesValue;
    return minutes;
  }

  fromTimespan(value) {
    let daysHoursMinutes = this.fromTimespanToTime(value);
    let result = '';
    if (daysHoursMinutes.daysValue > 0) {
      result +=
        daysHoursMinutes.daysValue +
        ' ' +
        TranslateService.get('enums/timeType/days') +
        ' ';
    }
    if (daysHoursMinutes.hoursValue > 0) {
      result +=
        daysHoursMinutes.hoursValue +
        ' ' +
        TranslateService.get('enums/timeType/hours') +
        ' ';
    }
    if (daysHoursMinutes.minutesValue > 0) {
      result +=
        daysHoursMinutes.minutesValue +
        ' ' +
        TranslateService.get('enums/timeType/minutes') +
        ' ';
    }
    return result;
  }

  estimatedTimeSpan(value: string, onlyMinutes: Boolean = false) {
    let result = '';
    if (value !== undefined && value != null) {
      let days, hours, minutes;

      if (onlyMinutes) {
        if (Number.parseInt(value) > 0) {
          result =
            `${value} ${
              Number.parseInt(value) == 1
                ? TranslateService.get('enums/timeType/minute')
                : TranslateService.get('enums/timeType/minutes')
            }` + ` `;
        }
      } else {
        if (value.includes('D')) {
          days = value.match(/(?<=[pP])(\d)*(?=[dD])/g)[0];
          if (days > 0) {
            result +=
              `${days} ${
                days == 1
                  ? TranslateService.get('enums/timeType/day')
                  : TranslateService.get('enums/timeType/days')
              }` + ` `;
          }
        }

        if (value.includes('H')) {
          hours = value.match(/(?<=[tT])(\d)*(?=[hH])/g)[0];

          if (hours > 0) {
            result +=
              `${hours} ${
                hours == 1
                  ? TranslateService.get('enums/timeType/hour')
                  : TranslateService.get('enums/timeType/hours')
              }` + ` `;
          }
        }

        if (value.includes('M')) {
          minutes = value.match(/(?<=[tThH])(\d)*(?=[mM])/g)[0];
          if (minutes > 0) {
            result +=
              `${minutes} ${
                minutes == 1
                  ? TranslateService.get('enums/timeType/minute')
                  : TranslateService.get('enums/timeType/minutes')
              }` + ` `;
          }
        }
      }
    }

    return result;
  }

  /** Génération de l'url d'un ticket en fonction de son tyoe */
  generateTicketUrl(data) {
    let route = 'incidents';

    if (data?.ticket?.data?.__typename != undefined) {
      if (camelCase(data?.ticket?.data?.__typename) !== 'incident') {
        route = `${camelCase(data?.ticket?.data?.__typename)}s`;
      }
    }

    let url = '/' + route + '/edit/' + data?.ticketId;
    url = this.policyValidatorTicket.manageUrlBasedOnPermissions(
      url,
      camelCase(data?.ticket?.data?.__typename),
    );

    return url;
  }
  /** Génération de l'url d'un utilisateur vers son compte identity */
  generateIdentityUserUrl(subId: string): string {
    return `${this._envService.identityURL}/identity-users/edit/${subId}`;
  }

  /** Obtiens l'url pour accéder au ticket */
  getUrlLinkTicket(type: string, id: string, editUrlTicket: boolean = false) {
    let router = this.injector.get(Router);

    let getUrlTree = router.parseUrl(router?.url);
    if (getUrlTree?.queryParams?.['searchFilter'] !== undefined) {
      return this.getUrlTicket(type, id, null);
    }

    if (
      router?.url != undefined &&
      type != undefined &&
      router.url.toLowerCase().indexOf(type.toLowerCase()) > 0
    ) {
      return router.url + `/edit/${id}`;
    } else {
      let initialRoute = null;
      if (editUrlTicket) {
        initialRoute = router.url;
      }

      return this.getUrlTicket(type, id, initialRoute);
    }
  }

  getUrlLinkToParentIncident(type: string = 'Incident', id: string) {
    let parentUrl = `/${pluralize.plural(camelCase(type))}` + `/all/edit/${id}`;
    return parentUrl;
  }

  getUrlTicket(type: string, id: string, initialeRoute: string = null) {
    let finalUrl = `/${pluralize.plural(camelCase(type))}/edit/${id}`;

    if (initialeRoute != null) {
      let url = this.searchInsideUrl(initialeRoute);
      finalUrl = `/${pluralize.plural(camelCase(type))}${url}/edit/${id}`;
    }

    finalUrl = this.policyValidatorTicket.manageUrlBasedOnPermissions(
      finalUrl,
      camelCase(type),
    );

    return finalUrl;
  }

  /**Chercher sur quel menu on ouvre la fiche d'un ticket (ex: mes incidents, incidents de mes équipes...) */
  private searchInsideUrl(initialeRoute: string): string {
    let getUrlInfo: string = '/all';
    if (!initialeRoute.includes('research')) {
      getUrlInfo = initialeRoute.split('/tickets')[1]?.trim();
    }
    return getUrlInfo;
  }

  getPolicyEditTicket(type) {
    let authorizationSettings = this.injector.get(CoreAuthorizationSettings);
    var policies = authorizationSettings.settings._keys;
    return policies.includes(`${camelCase(type)}.read`);
  }
  getTranslate(field) {
    if (field != undefined) {
      let value = field[this.translatedFieldHelperService.getTranslateKey()];
      if (value != undefined) {
        return value;
      }
    }

    return field;
  }

  getlogStateTemplateColor(e, valueError) {
    if (e?.value == valueError) {
      if (e?.data?.error != undefined && e?.data?.error != '') {
        return 'orange';
      }
      if (e?.data?.errorMessage != undefined && e?.data?.errorMessage != '') {
        return 'orange';
      }

      if (
        e?.data?.clarilogServerEmailAction !== undefined &&
        e?.data?.clarilogServerEmailAction === ClarilogServerEmailAction.InQueue
      ) {
        return '#45a7b9';
      }

      return 'green';
    } else {
      if (
        e?.data?.clarilogServerEmailAction !== undefined &&
        e?.data?.clarilogServerEmailAction === ClarilogServerEmailAction.InQueue
      ) {
        return '#45a7b9';
      }

      return 'red';
    }
  }
  getlogStateTemplateIco(e, valueError) {
    if (e?.value == valueError) {
      if (
        e?.data?.useClarilogServer === true &&
        e?.data?.clarilogServerEmailAction !== undefined &&
        e?.data?.clarilogServerEmailAction !==
          ClarilogServerEmailAction.Finished
      ) {
        return 'fas fa-hourglass-start';
      }

      return 'fas fa-check-circle';
    } else {
      if (
        e?.data?.clarilogServerEmailAction !== undefined &&
        e?.data?.clarilogServerEmailAction === ClarilogServerEmailAction.InQueue
      ) {
        return 'fas fa-hourglass-start';
      }

      return 'fas fa-exclamation-circle';
    }
  }

  hasErrorMailBoxObserverIco(e, valueError) {
    if (e?.value == valueError) {
      if (e?.data?.mailboxObserverState === MailboxObserverState.Finished) {
        return 'fas fa-check-circle';
      } else {
        return 'fas fa-hourglass-start';
      }
    } else {
      return 'fas fa-exclamation-circle';
    }
  }

  getConventionValue(column: string, data: any) {
    if (!data?.value?.includes('data-id="INCREMENT"')) {
      return null;
    }
    if (column === 'incrementLength') {
      return data?.incrementLength;
    } else return data?.incrementNextValue;
  }

  dependsOnTemplates(e) {
    let columnName = e.column.dataField;

    let valueToRender = null;
    if (columnName.toLowerCase() == 'manufacturerId'.toLowerCase()) {
      valueToRender = e.data.manufacturer?.data?.name;
    } else {
      valueToRender = e.data.model?.data?.name;
    }
    return valueToRender;
  }

  getTicketOrTicketTaskDescriptionValue(data) {
    return data?.data?.__typename === 'TicketTask'
      ? this.getTranslate(data?.value)
      : data?.value;
  }

  isTicketChidlClassTemplate(data) {
    if (data.data.parentId != null) {
      return 'ticket-child';
    }
    if (data.data.isParent == true) {
      return 'ticket-parent';
    }
  }

  //Création des liens user et asset pour les prêts
  getAssetOrUserUrl(type: string, id: string) {
    return `/${pluralize.plural(camelCase(type))}/edit/${id}`;
  }

  getSupplierUrl(id: string, type: string = 'suppliers'): string {
    let url = '/suppliers';
    if (type === 'contacts') {
      url = '/suppliers/contacts';
    }
    return `${url}/edit/${id}`;
  }

  financialsRoute(data): string {
    let id = data?.id;
    var typename = (data?.__typename as string)?.toLowerCase();
    if (typename == 'contract') {
      id = data?.budget?.data?.id;
    }
    return `/financials/budgets/edit/${id}`;
  }

  getLoanAssetsToltip(assets) {
    if (assets.length > 0) {
      let names = '';

      for (let asset of assets) {
        let isLastValue = indexOf(assets, asset) == assets.length - 1;

        names += asset.name;
        names += !isLastValue ? ', ' : '';
      }

      return names;
    }
  }

  getLabelFieldObjectTypeLdap(value, data) {
    let type = 'users';
    switch (data.data.type) {
      case 'USER':
        type = 'user';
        break;
      case 'ORGANIZATION':
        type = 'organizationalUnit';
        break;
      case 'ASSET':
        type = 'asset';
        break;
    }
    let translate = this.getLabelField(camelCase(value), type);

    if (translate.startsWith('[')) {
      translate = this.getLabelField(camelCase(value), 'address');
    }
    if (translate.startsWith('[')) {
      translate = this.getLabelField(camelCase(value), 'address');
    }

    return translate;
  }
  getLabelField(data, type) {
    let value;
    if (typeof data == 'string') {
      value = data;
    } else {
      value = data?.value;
    }

    if (data?.data?.entityType != undefined && data?.data?.entityType != type) {
      type = data?.data?.entityType;
    }

    if (value == null || type == null) return '';
    type = type[0].toLowerCase() + type.slice(1);

    // Si la valeur semble être un champs personnalisé, on retourne la valeur directement
    if (value.startsWith('dynamicProperties.')) {
      return data?.data?.translated; // Retourner la valeur traduite telle quelle
    }

    let label = TranslateService.get('entities/' + type + '/' + value);

    if (type == 'request' && label.startsWith('[')) {
      label = TranslateService.get('entities/incident/' + value);
    }

    if (label.startsWith('[')) {
      if (label.endsWith('Id]')) {
        label = TranslateService.get(
          'entities/' + type + '/' + value.replace(new RegExp('Id' + '$'), ''),
        );

        if (label.includes('.')) {
          value = value.replace(/\./g, '/');

          if (type == 'ticketEmail') {
            label = TranslateService.get(
              'entities/' + type + '/' + value.replace(/Id$/, ''),
            );
          }

          if (type == 'message') {
            label = TranslateService.get(
              'entities/' + value.replace(/Id$/, ''),
            );
          }
        }

        if (type == 'request' && label.startsWith('[')) {
          label = TranslateService.get(
            'entities/incident/' + value.replace(new RegExp('Id' + '$'), ''),
          );
        }

        if (
          label.startsWith('[') &&
          (type == 'incident' || type == 'request')
        ) {
          label = TranslateService.get(
            'entities/ticket/' + value.replace(new RegExp('Id' + '$'), ''),
          );
        }
      } else {
        let parts = (value as string).split('.');
        let toTranslate = '';

        parts = parts.filter((value) => value !== 'data');

        parts.forEach((element) => {
          toTranslate = toTranslate.concat('/').concat(element);
        });

        let label_parent = TranslateService.get(
          'entities/' + parts[0] + '/_title/singular',
        );

        label = label_parent.concat('/');

        if (label_parent.includes('[')) label = '';

        if (
          toTranslate.includes('created') ||
          toTranslate.includes('rating') ||
          toTranslate.includes('commentary')
        ) {
          toTranslate = toTranslate.replace('/ticket', '');
        }

        label = label.concat(TranslateService.get('entities' + toTranslate));
      }
    }

    return label;
  }

  getWarningMessageType(value) {
    if (WarningMessageCategory.Operator == value) {
      return TranslateService.get('entities/warningMessage/operator');
    }
    return TranslateService.get('entities/helpme/_title/singular');
  }

  workflowLogLink(data: any) {
    let type = data?.entityType;
    let id = data?.entityId;

    if (
      data?.entityType != undefined &&
      (data?.entityType as string).toLowerCase() === 'tickettask' &&
      data?.ticketId != undefined
    ) {
      type = data?.ticketType;
      id = data?.ticketId;
    }

    if (type != undefined && id != undefined) {
      return this.getUrlTicket(type, id);
    }
  }

  queryParamsLogLink(type: string, id: string) {
    if (type != undefined && type.toLowerCase() === 'tickettask') {
      return {
        navigateTo: 'treatment',
        tabs: 'tasks',
        taskId: id,
      };
    }
    return null;
  }

  getUrlForEmailLink(data) {
    if (data.data.emailType == 'Workflow') {
      return '/administration/service-desk/workflow/edit/' + data.data.linkId;
    }
    if (data.data.emailType == 'EmailPrototype') {
      return '/administration/service-desk/prototypes/edit/' + data.data.linkId;
    }
  }

  getDistinguishName(name: string) {
    let nameParts = name?.split(',');
    let cnName = nameParts[0];

    if (cnName.includes('CN=')) {
      return cnName.replace('CN=', '');
    }

    return name;
  }

  getRoutingTerms(data) {
    let defaultRouting: string = `/loans/terms-loan/edit/${data?.id}`;
    if (data?.entityType === 'user') {
      defaultRouting = `/users/terms-user/edit/${data?.id}`;
    }
    return defaultRouting;
  }

  getSoftwareRoute() {
    return !this.router.url.includes('/asset-affected/');
  }

  taskLink(data: any) {
    let type = data?.__typename;
    let id = data?.ticketId;

    if (
      type != undefined &&
      (type as string).toLowerCase() === 'tickettask' &&
      data?.ticketId != undefined
    ) {
      type = data?.ticket.data.__typename;
    }

    if (type != undefined && id != undefined) {
      return this.getUrlTicket(type, id);
    }
  }

  workflowRoute(workflowType: string, workflowId: string) {
    let workflowRuleList = ['loan'];

    if (
      workflowType == null &&
      workflowType == undefined &&
      workflowId == null &&
      workflowId == undefined
    ) {
      return;
    }
    if (
      workflowType != undefined &&
      workflowRuleList.includes(workflowType.toLowerCase())
    ) {
      return `/administration/rule/workflow-rules/edit/${workflowId}`;
    }
    return `/administration/service-desk/workflow/edit/${workflowId}`;
  }

  getValue(value: any): string {
    if (value?.toLowerCase != undefined) {
      return value.toLowerCase();
    }

    return '';
  }

  generateMovementUrl(data: ConsumableMovement) {
    const locale = this.translatedFieldHelperService.getTranslateKey();

    const entry = TranslateService.get(
      'entities/stock/consumableMovement/entry',
    );
    const out = TranslateService.get('entities/stock/consumableMovement/out');
    const correction = TranslateService.get(
      'entities/stock/consumableMovement/correction',
    );

    // Livraison
    if (entry == data.movementType[locale] && data.inStockMovement != null) {
      return `/stocks/in-stocks/edit/${data.movementId}`;
    }
    // Sortie
    if (out == data.movementType[locale] && data.outStockMovement != null) {
      return `/stocks/out-stocks/edit/${data.movementId}`;
    }
    //Correction
    if (correction == data.movementType[locale]) {
      return `/stocks/correction-stocks/edit/${data.movementId}`;
    }
    // Transfert
    if (data.inStockMovement == null && data.outStockMovement == null) {
      return `/stocks/transfer-stocks/edit/${data.movementId}`;
    }
  }

  getQualifications(qualification: Array<string>) {
    let qualificationTranslate: Array<string> = [];
    if (qualification?.length > 0) {
      qualification.map((element) => {
        switch (element) {
          case 'incident': {
            let incidentTranslate = TranslateService.get(
              'entities/incident/_title/singular',
            );
            qualificationTranslate.push(incidentTranslate);
            break;
          }
          case 'request': {
            let requestTranslate = TranslateService.get(
              'entities/request/_title/singular',
            );
            qualificationTranslate.push(requestTranslate);
            break;
          }
          case 'problem': {
            let problemTranslate = TranslateService.get(
              'entities/problem/_title/singular',
            );
            qualificationTranslate.push(problemTranslate);
            break;
          }
          default:
            break;
        }
      });
      return qualificationTranslate.length > 0
        ? qualificationTranslate.sort()
        : qualification;
    }
    return qualification;
  }

  getWorkflowType(workflowType: string) {
    let lowerWorkflowType = workflowType?.toLowerCase();
    if (lowerWorkflowType != null && lowerWorkflowType != undefined) {
      return this.workflowCoreService
        .getAllWorkflowType()
        .find((x) => x.id == lowerWorkflowType).name;
    }
    return workflowType;
  }

  getEmailConnectorType(emailConnectorType: string) {
    let lowerEmailConnectorType = emailConnectorType?.toLowerCase();
    if (
      lowerEmailConnectorType != null &&
      lowerEmailConnectorType != undefined
    ) {
      let emailConnectorTypeList: { id: string; name: string }[] =
        this.workflowCoreService.getAllWorkflowType();
      emailConnectorTypeList.push(
        {
          id: 'alarm',
          name: TranslateService.get('entities/alarm/_title/singular'),
        },
        {
          id: 'querybuilder',
          name: TranslateService.get('entities/queryBuilder/_title/singular'),
        },
      );

      return emailConnectorTypeList.find((x) => x.id == lowerEmailConnectorType)
        .name;
    }
    return emailConnectorType;
  }

  getEntityName(value: string) {
    let translateType = `entities/${value.charAt(0).toLowerCase()}${value.slice(
      1,
    )}/_title/singular`;

    let translate = TranslateService.get(translateType);

    switch (value.toLocaleLowerCase()) {
      case 'outofpark': {
        translate = `${TranslateService.get(
          'entities/asset/_title/singular',
        )} (${TranslateService.get('entities/asset/outOfPark')})`;
        break;
      }
      case 'consumablemovement': {
        translate = TranslateService.get(
          'entities/consumableMovement/_title/singular',
        );
        break;
      }
      case 'tickettask': {
        translate = TranslateService.get('entities/ticketTask/_title/singular');
        break;
      }
      default:
        break;
    }
    return translate;
  }

  /**
   * Construction de l'url en fonction du typename
   * @param ticket ticket
   * @param id identifiant du ticket
   * @returns url du ticket
   */
  getTicketLinkUrl(ticket: any, ticketId: string) {
    const paths: Record<string, string> = {
      Incident: '/incidents/edit/',
      Request: '/requests/edit/',
    };

    return `${paths[ticket.__typename] || ''}${ticketId}`;
  }

  workflowLink(typename: string, workflowId: string) {
    let type = 'status-workflow';
    if (typename.toLowerCase() == 'taskstatusworkflow') {
      type = 'task-status-workflow';
    }

    return `administration/service-desk/${type}/edit/${workflowId}`;
  }

  /**
   * Envoi de l'évènement au composant pour l'ouverture de la pop-up
   * @param id identifiant de l'actualité
   */
  openNews(id: string) {
    this.localStorageService.ModelState.on.emit({
      eventName: 'openNewsPopUp',
      eventData: {
        showViewerPopUp: true,
        newsId: id,
      },
    });
  }

  getWorkflowActions(e) {
    let values = [];
    if (this.getAllAction == undefined) {
      this.getAllAction = this.workflowCoreService.getAllAction();
    }
    if (e != undefined && this.getAllAction != undefined) {
      e.forEach((g) => {
        let index = this.getAllAction.find(
          (f) => f.id.toLowerCase() == g.toLowerCase(),
        );
        if (index != undefined && !values.includes(index.name)) {
          values.push(index.name);
        } else if (g.toLowerCase() == 'WorkflowManaged'.toLocaleLowerCase()) {
          values.push(
            TranslateService.get('entities/workflowManaged/_title/singular'),
          );
        }
      });
    }
    return values;
  }
}
