import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMailboxObserverTasksBaseVariables, FirstMailboxObserverTasksBaseVariables, CountMailboxObserverTasksBaseVariables, FindMailboxObserverTasksBaseVariables, SearchMailboxObserverTasksBaseVariables, ExportSchemaMailboxObserverTasksBaseVariables, ExportDataMailboxObserverTasksBaseVariables, CustomQueryMailboxObserverTasksBaseVariables, CustomQueryIdMailboxObserverTasksBaseVariables, UsedMailboxObserverTasksBaseVariables, ExistMailboxObserverTasksBaseVariables, InsertMailboxObserverTasksBaseVariables, UpdateMailboxObserverTasksBaseVariables, ImportDataMailboxObserverTasksBaseVariables, UpdateManyMailboxObserverTasksBaseVariables, DeleteMailboxObserverTasksBaseVariables } from '../gqls'
import { GetMailboxObserverTasksDocument, FirstMailboxObserverTasksDocument, CountMailboxObserverTasksDocument, FindMailboxObserverTasksDocument, SearchMailboxObserverTasksDocument, ExportSchemaMailboxObserverTasksDocument, ExportDataMailboxObserverTasksDocument, CustomQueryMailboxObserverTasksDocument, CustomQueryIdMailboxObserverTasksDocument, UsedMailboxObserverTasksDocument, ExistMailboxObserverTasksDocument, InsertMailboxObserverTasksDocument, UpdateMailboxObserverTasksDocument, ImportDataMailboxObserverTasksDocument, UpdateManyMailboxObserverTasksDocument, DeleteMailboxObserverTasksDocument } from '../gqls'
import { ServiceSingleResultOfMailboxObserverTask, QueryContextOfMailboxObserverTask, FilterOfMailboxObserverTask, ServiceSingleResultOfInt64, ServiceListResultOfMailboxObserverTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, MailboxObserverTaskInput, FieldUpdateOperatorOfMailboxObserverTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MailboxObserverTaskBaseService {
    
public modelName = 'mailboxObserverTask';
public modelPluralName = 'mailboxObserverTasks';

	private getMailboxObserverTasksQuery: GetMailboxObserverTasksDocument;
	private firstMailboxObserverTasksQuery: FirstMailboxObserverTasksDocument;
	private countMailboxObserverTasksQuery: CountMailboxObserverTasksDocument;
	private findMailboxObserverTasksQuery: FindMailboxObserverTasksDocument;
	private searchMailboxObserverTasksQuery: SearchMailboxObserverTasksDocument;
	private exportSchemaMailboxObserverTasksQuery: ExportSchemaMailboxObserverTasksDocument;
	private exportDataMailboxObserverTasksQuery: ExportDataMailboxObserverTasksDocument;
	private customQueryMailboxObserverTasksQuery: CustomQueryMailboxObserverTasksDocument;
	private customQueryIdMailboxObserverTasksQuery: CustomQueryIdMailboxObserverTasksDocument;
	private usedMailboxObserverTasksQuery: UsedMailboxObserverTasksDocument;
	private existMailboxObserverTasksQuery: ExistMailboxObserverTasksDocument;
	private insertMailboxObserverTasksMutation: InsertMailboxObserverTasksDocument;
	private updateMailboxObserverTasksMutation: UpdateMailboxObserverTasksDocument;
	private importDataMailboxObserverTasksMutation: ImportDataMailboxObserverTasksDocument;
	private updateManyMailboxObserverTasksMutation: UpdateManyMailboxObserverTasksDocument;
	private deleteMailboxObserverTasksMutation: DeleteMailboxObserverTasksDocument;

	constructor(private injector: Injector) {
		this.getMailboxObserverTasksQuery = this.injector.get(GetMailboxObserverTasksDocument);
		this.firstMailboxObserverTasksQuery = this.injector.get(FirstMailboxObserverTasksDocument);
		this.countMailboxObserverTasksQuery = this.injector.get(CountMailboxObserverTasksDocument);
		this.findMailboxObserverTasksQuery = this.injector.get(FindMailboxObserverTasksDocument);
		this.searchMailboxObserverTasksQuery = this.injector.get(SearchMailboxObserverTasksDocument);
		this.exportSchemaMailboxObserverTasksQuery = this.injector.get(ExportSchemaMailboxObserverTasksDocument);
		this.exportDataMailboxObserverTasksQuery = this.injector.get(ExportDataMailboxObserverTasksDocument);
		this.customQueryMailboxObserverTasksQuery = this.injector.get(CustomQueryMailboxObserverTasksDocument);
		this.customQueryIdMailboxObserverTasksQuery = this.injector.get(CustomQueryIdMailboxObserverTasksDocument);
		this.usedMailboxObserverTasksQuery = this.injector.get(UsedMailboxObserverTasksDocument);
		this.existMailboxObserverTasksQuery = this.injector.get(ExistMailboxObserverTasksDocument);
		this.insertMailboxObserverTasksMutation = this.injector.get(InsertMailboxObserverTasksDocument);
		this.updateMailboxObserverTasksMutation = this.injector.get(UpdateMailboxObserverTasksDocument);
		this.importDataMailboxObserverTasksMutation = this.injector.get(ImportDataMailboxObserverTasksDocument);
		this.updateManyMailboxObserverTasksMutation = this.injector.get(UpdateManyMailboxObserverTasksDocument);
		this.deleteMailboxObserverTasksMutation = this.injector.get(DeleteMailboxObserverTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverTask> {
    
    		let variables: GetMailboxObserverTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.get));
            }
            else{
                let result:ServiceSingleResultOfMailboxObserverTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverTask,
		@Args('filter?') filter?: FilterOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverTask> {
    
    		let variables: FirstMailboxObserverTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMailboxObserverTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverTask,
		@Args('filter?') filter?: FilterOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverTask> {
    
    		let variables: FindMailboxObserverTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMailboxObserverTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverTask> {
    
    		let variables: SearchMailboxObserverTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMailboxObserverTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMailboxObserverTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMailboxObserverTask,
		@Args('filter?') filter?: FilterOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverTask> {
    
    		let variables: CustomQueryMailboxObserverTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMailboxObserverTask,
		@Args('filter?') filter?: FilterOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverTask> {
    
    		let variables: CustomQueryIdMailboxObserverTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMailboxObserverTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMailboxObserverTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMailboxObserverTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MailboxObserverTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverTask> {
    
    		let variables: InsertMailboxObserverTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertMailboxObserverTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverTask> {
    
    		let variables: UpdateMailboxObserverTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMailboxObserverTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMailboxObserverTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMailboxObserverTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailboxObserverTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMailboxObserverTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMailboxObserverTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMailboxObserverTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMailboxObserverTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverTasks.delete));
    	}
    
}