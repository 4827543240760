<clc-manage-list
  [title]="model.model.title"
  [modelState]="model"
  [service]="emailPrototypeLogService"
  [canAdd]="false"
  [canEdit]="false"
  [canImport]="false"
>
  <cl-toolbar-items>
    <cl-toolbar-item-dropdown-button
      [text]="'globals/purge' | translate"
      icon="'far fa-link'"
      [source]="purgeDataSource"
      [keyExpr]="'id'"
      [displayExpr]="'name'"
      (onItemClick)="onItemClick($event)"
      [disabled]="false"
      [visible]="true"
    >
    </cl-toolbar-item-dropdown-button>
  </cl-toolbar-items>
</clc-manage-list>

<clc-dynamic-popup
  [height]="'35%'"
  [width]="'40%'"
  [title]="'globals/purgeDate' | translate"
  [(isVisible)]="displayPopUp"
  [inputModel]="purgeModel"
  [titleBtn]="'globals/purgeAction' | translate"
  [service]="emailPrototypeLogService"
  (onSaved)="retrieveFormData($event)"
>
</clc-dynamic-popup>
