import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WarningMessageCoreService } from '@clarilog/core/services2/graphql/generated-types/services/warning-message.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import DataSource from 'devextreme/data/data_source';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { map } from 'rxjs/internal/operators/map';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { WarningMessageCategory } from '@clarilog/core/services2/graphql/generated-types/types';

/** Représente la classe du composent cl-widget-warning-message. */
@Component({
  selector: 'clc-widget-warning-message',
  templateUrl: './widget-warning-message.component.html',
  styleUrls: ['./widget-warning-message.component.scss'],
})
export class CoreWidgetWarningMessageComponent implements OnInit {
  warningMessages: any[] = [];
  /** Obtient ou définit la source de données. */
  source: DataSource;
  @Input() hasWarning: boolean = false;

  gc: GC;
  timer: NodeJS.Timeout;

  @Input() reloadTimer: number;

  /**Obtient ou definit si le message d'avertissement est  */
  @Input() isAWarningMessageOperator: boolean = false;
  fields = [
    GqlSubField.create('data', [
      GqlField.create('id'),
      GqlSubField.create(
        'title',
        this.translatedFieldHelperService.translatedFields(),
      ),
      GqlSubField.create(
        'content',
        this.translatedFieldHelperService.translatedFields(),
      ),
    ]),
  ];

  constructor(
    private router: Router,
    private warningMessageService: WarningMessageCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private gcFactory: GCFactory,
  ) {
    this.gc = gcFactory.create();
  }

  /** @inheritdoc */
  async ngOnInit() {
    let warningMessageCategory: WarningMessageCategory =
      WarningMessageCategory.HelpMe;
    if (this.isAWarningMessageOperator) {
      warningMessageCategory = WarningMessageCategory.Operator;
    }

    let sourceContext = new ModelDataSourceContext({
      serviceName: 'WarningMessageCoreService',
      methodName: 'findEnable',
    });
    this.source = CoreGraphQLSource.create({
      context: sourceContext,
      query: (filter?: any, options?: any) => {
        if (this.isAWarningMessageOperator == false) {
          return this.warningMessageService.findEnable(this.fields).pipe(
            map((result) => {
              this.hasWarning = result != null && result.data?.length > 0;
              return result;
            }),
          );
        }

        return this.warningMessageService
          .findWarningMessageOperator(this.fields)
          .pipe(
            map((result) => {
              return result;
            }),
          );
      },
    });

    sourceContext.datasource = this.source;
    this.source.load();

    if (this.reloadTimer != undefined) {
      // Refrsh auto
      this.timer = setInterval(() => {
        if (this.source != undefined) {
          this.source.load();
        }
      }, this.reloadTimer);
    }
  }

  ngOnDestroy() {
    this.gc.dispose();
    if (this.timer != undefined) {
      clearTimeout(this.timer);
    }
  }

  getTranlate(field) {
    if (field != undefined) {
      return field[this.translatedFieldHelperService.getTranslateKey()];
    }
    return field;
  }
}
