import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMailboxObserverLogsBaseVariables, FirstMailboxObserverLogsBaseVariables, CountMailboxObserverLogsBaseVariables, FindMailboxObserverLogsBaseVariables, SearchMailboxObserverLogsBaseVariables, ExportSchemaMailboxObserverLogsBaseVariables, ExportDataMailboxObserverLogsBaseVariables, CustomQueryMailboxObserverLogsBaseVariables, CustomQueryIdMailboxObserverLogsBaseVariables, FindFilesMailboxObserverLogsBaseVariables, UsedMailboxObserverLogsBaseVariables, FindByClarilogServerMailboxObserverLogsBaseVariables, FindByEmailConnectorMailboxObserverLogsBaseVariables, ExistMailboxObserverLogsBaseVariables, InsertMailboxObserverLogsBaseVariables, UpdateMailboxObserverLogsBaseVariables, ImportDataMailboxObserverLogsBaseVariables, UpdateManyMailboxObserverLogsBaseVariables, DeleteMailboxObserverLogsBaseVariables, UploadChunkFileMailboxObserverLogsBaseVariables, TryAddEmlMailboxObserverLogsBaseVariables } from '../gqls'
import { GetMailboxObserverLogsDocument, FirstMailboxObserverLogsDocument, CountMailboxObserverLogsDocument, FindMailboxObserverLogsDocument, SearchMailboxObserverLogsDocument, ExportSchemaMailboxObserverLogsDocument, ExportDataMailboxObserverLogsDocument, CustomQueryMailboxObserverLogsDocument, CustomQueryIdMailboxObserverLogsDocument, FindFilesMailboxObserverLogsDocument, UsedMailboxObserverLogsDocument, FindByClarilogServerMailboxObserverLogsDocument, FindByEmailConnectorMailboxObserverLogsDocument, ExistMailboxObserverLogsDocument, InsertMailboxObserverLogsDocument, UpdateMailboxObserverLogsDocument, ImportDataMailboxObserverLogsDocument, UpdateManyMailboxObserverLogsDocument, DeleteMailboxObserverLogsDocument, UploadChunkFileMailboxObserverLogsDocument, TryAddEmlMailboxObserverLogsDocument } from '../gqls'
import { ServiceSingleResultOfMailboxObserverLog, QueryContextOfMailboxObserverLog, FilterOfMailboxObserverLog, ServiceSingleResultOfInt64, ServiceListResultOfMailboxObserverLog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, MailboxObserverLogInput, FieldUpdateOperatorOfMailboxObserverLog, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MailboxObserverLogBaseService {
    
public modelName = 'mailboxObserverLog';
public modelPluralName = 'mailboxObserverLogs';

	private getMailboxObserverLogsQuery: GetMailboxObserverLogsDocument;
	private firstMailboxObserverLogsQuery: FirstMailboxObserverLogsDocument;
	private countMailboxObserverLogsQuery: CountMailboxObserverLogsDocument;
	private findMailboxObserverLogsQuery: FindMailboxObserverLogsDocument;
	private searchMailboxObserverLogsQuery: SearchMailboxObserverLogsDocument;
	private exportSchemaMailboxObserverLogsQuery: ExportSchemaMailboxObserverLogsDocument;
	private exportDataMailboxObserverLogsQuery: ExportDataMailboxObserverLogsDocument;
	private customQueryMailboxObserverLogsQuery: CustomQueryMailboxObserverLogsDocument;
	private customQueryIdMailboxObserverLogsQuery: CustomQueryIdMailboxObserverLogsDocument;
	private findFilesMailboxObserverLogsQuery: FindFilesMailboxObserverLogsDocument;
	private usedMailboxObserverLogsQuery: UsedMailboxObserverLogsDocument;
	private findByClarilogServerMailboxObserverLogsQuery: FindByClarilogServerMailboxObserverLogsDocument;
	private findByEmailConnectorMailboxObserverLogsQuery: FindByEmailConnectorMailboxObserverLogsDocument;
	private existMailboxObserverLogsQuery: ExistMailboxObserverLogsDocument;
	private insertMailboxObserverLogsMutation: InsertMailboxObserverLogsDocument;
	private updateMailboxObserverLogsMutation: UpdateMailboxObserverLogsDocument;
	private importDataMailboxObserverLogsMutation: ImportDataMailboxObserverLogsDocument;
	private updateManyMailboxObserverLogsMutation: UpdateManyMailboxObserverLogsDocument;
	private deleteMailboxObserverLogsMutation: DeleteMailboxObserverLogsDocument;
	private uploadChunkFileMailboxObserverLogsMutation: UploadChunkFileMailboxObserverLogsDocument;
	private tryAddEmlMailboxObserverLogsMutation: TryAddEmlMailboxObserverLogsDocument;

	constructor(private injector: Injector) {
		this.getMailboxObserverLogsQuery = this.injector.get(GetMailboxObserverLogsDocument);
		this.firstMailboxObserverLogsQuery = this.injector.get(FirstMailboxObserverLogsDocument);
		this.countMailboxObserverLogsQuery = this.injector.get(CountMailboxObserverLogsDocument);
		this.findMailboxObserverLogsQuery = this.injector.get(FindMailboxObserverLogsDocument);
		this.searchMailboxObserverLogsQuery = this.injector.get(SearchMailboxObserverLogsDocument);
		this.exportSchemaMailboxObserverLogsQuery = this.injector.get(ExportSchemaMailboxObserverLogsDocument);
		this.exportDataMailboxObserverLogsQuery = this.injector.get(ExportDataMailboxObserverLogsDocument);
		this.customQueryMailboxObserverLogsQuery = this.injector.get(CustomQueryMailboxObserverLogsDocument);
		this.customQueryIdMailboxObserverLogsQuery = this.injector.get(CustomQueryIdMailboxObserverLogsDocument);
		this.findFilesMailboxObserverLogsQuery = this.injector.get(FindFilesMailboxObserverLogsDocument);
		this.usedMailboxObserverLogsQuery = this.injector.get(UsedMailboxObserverLogsDocument);
		this.findByClarilogServerMailboxObserverLogsQuery = this.injector.get(FindByClarilogServerMailboxObserverLogsDocument);
		this.findByEmailConnectorMailboxObserverLogsQuery = this.injector.get(FindByEmailConnectorMailboxObserverLogsDocument);
		this.existMailboxObserverLogsQuery = this.injector.get(ExistMailboxObserverLogsDocument);
		this.insertMailboxObserverLogsMutation = this.injector.get(InsertMailboxObserverLogsDocument);
		this.updateMailboxObserverLogsMutation = this.injector.get(UpdateMailboxObserverLogsDocument);
		this.importDataMailboxObserverLogsMutation = this.injector.get(ImportDataMailboxObserverLogsDocument);
		this.updateManyMailboxObserverLogsMutation = this.injector.get(UpdateManyMailboxObserverLogsDocument);
		this.deleteMailboxObserverLogsMutation = this.injector.get(DeleteMailboxObserverLogsDocument);
		this.uploadChunkFileMailboxObserverLogsMutation = this.injector.get(UploadChunkFileMailboxObserverLogsDocument);
		this.tryAddEmlMailboxObserverLogsMutation = this.injector.get(TryAddEmlMailboxObserverLogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverLog> {
    
    		let variables: GetMailboxObserverLogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.get));
            }
            else{
                let result:ServiceSingleResultOfMailboxObserverLog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverLog> {
    
    		let variables: FirstMailboxObserverLogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMailboxObserverLogsBaseVariables = {
    			filter: filter
    		}
    				return this.countMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: FindMailboxObserverLogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: SearchMailboxObserverLogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMailboxObserverLogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMailboxObserverLogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: CustomQueryMailboxObserverLogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: CustomQueryIdMailboxObserverLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesMailboxObserverLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMailboxObserverLogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByClarilogServer(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: FindByClarilogServerMailboxObserverLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findByClarilogServerMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.findByClarilogServer));
    	}

    	/**  */
    	@InjectArgs
    	public findByEmailConnector(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: FindByEmailConnectorMailboxObserverLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findByEmailConnectorMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.findByEmailConnector));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMailboxObserverLogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMailboxObserverLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MailboxObserverLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverLog> {
    
    		let variables: InsertMailboxObserverLogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailboxObserverLog> {
    
    		let variables: UpdateMailboxObserverLogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMailboxObserverLogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMailboxObserverLogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMailboxObserverLogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.delete));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileMailboxObserverLogsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public tryAddEml(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('mailboxObserverLog?') mailboxObserverLog?: MailboxObserverLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: TryAddEmlMailboxObserverLogsBaseVariables = {
    			mailboxObserverLog: mailboxObserverLog
    		}
    				return this.tryAddEmlMailboxObserverLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailboxObserverLogs.tryAddEml));
    	}
    
}