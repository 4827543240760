import { ChatBotBaseService } from '../service-bases';
import { Injectable, Injector, Renderer2 } from '@angular/core';
import { Authorize, ServiceSingleResult } from '@clarilog/core';
import { AccountCoreService } from '@clarilog/core/services2/graphql/generated-types/services/account.service';
import { AuthorizationCoreService } from '@clarilog/core/services2';
import { GqlField, GqlSubField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { dxButtonOptions } from 'devextreme/ui/button';
import { ModelFieldCompilerService, TranslateService } from '@clarilog/shared2';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { from, merge, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';

@Injectable({ providedIn: 'root' })
@Authorize('clarilog.administrator')
export class ChatBotCoreService extends ChatBotBaseService {
  constructor(injector: Injector, private accountService: AccountCoreService, private authorizationService: AuthorizationCoreService) {
    super(injector);
  }

  cleanChatBot(document: Document) {
    let botDiv = document.getElementById('botDiv');

    if (botDiv != undefined) {
      let wikitScript = document.getElementById('WIKIT-CHATBOT-SCRIPT');

      wikitScript.parentElement.removeChild(wikitScript);
      botDiv.parentElement.removeChild(botDiv);
    }
  }

  iaToolsProcessPopupResult(res: any, input: string, loadingWikitFunc: (state: boolean) => void): Observable<any> {
    console.log(res)
    if (res != null) {
      let func = null;

      if (res === 'changeTone') {
        func = (fields, input) => {
          //let tone = 'professional';
          return from(this.iaToolsChangeTonePopUp()).pipe(mergeMap(res => {
            if (res == null) {
              return of(null)
            }
            loadingWikitFunc(true);
            return this.wikitChangeTone(fields, res, input);
          }));
        };
      } else if (res === 'translate') {
        func = (fields, input) => {

          return from(this.iaToolTranslatePopUp()).pipe(mergeMap(res => {
            if (res == null) {
              return of(null)
            }
            loadingWikitFunc(true);
            return this.wikitTranslateSpecificLanguage(fields, res, input);
          }));

        };

      } else {
        loadingWikitFunc(true);

        if (res === 'simplify') {
          func = this.wikitSimplify.bind(this);
        }

        if (res === 'shorten') {
          func = this.wikitShorten.bind(this);
        }

        if (res === 'correct') {
          func = this.wikitCorrect.bind(this);
        }
        if (res === 'expand') {
          func = this.wikitExpand.bind(this);
        }
      }

      return func(ModelFieldCompilerService.createServiceSingleResultScalar(), input).pipe(map((output: ServiceSingleResult<any>) => {
        if (output != null) {
          return null;
        }
        loadingWikitFunc(false);

        // return null;
        if (output.errors?.length > 0) {
          notify(output.errors[0].messageError, 'error',
            5000);
          return null;
        } else {
          output.data = output.data.replaceAll('\\n', '<br>');
          let parse = JSON.parse(output.data);
          return parse.output;
        }
      }));
    }
    return of(null);
  }

  iaToolTranslatePopUp(): Promise<any> {

    let buttons: dxButtonOptions[] = [];

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/en'),
      onClick: (e) => {
        return 'english';
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/fr'),
      onClick: (e) => {
        return 'french';
      },
    });


    buttons.push({
      type: 'default',
      text: TranslateService.get('cancel'),
      onClick: (e) => {
        return null;
      },
    });
    return custom({
      title: TranslateService.get('entities/iaTools/popupTranslateTitle'),
      messageHtml: TranslateService.get('entities/iaTools/popupTranslateContent'),
      buttons: buttons,
    }).show();
  }

  iaToolsChangeTonePopUp(): Promise<any> {

    let buttons: dxButtonOptions[] = [];

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/professional'),
      onClick: (e) => {
        return 'professional';
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/friendly'),
      onClick: (e) => {
        return 'friendly';
      },
    });


    buttons.push({
      type: 'default',
      text: TranslateService.get('cancel'),
      onClick: (e) => {
        return null;
      },
    });
    return custom({
      title: TranslateService.get('entities/iaTools/popupChangeToneTitle'),
      messageHtml: TranslateService.get('entities/iaTools/popupChangeToneContent'),
      buttons: buttons,
    }).show();
  }


  iaToolsPopUp(): Promise<any> {

    let buttons: dxButtonOptions[] = [];

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/simplify'),
      onClick: (e) => {
        return this.wikitSimplify.bind(this);
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/shorten'),
      onClick: (e) => {
        return this.wikitShorten.bind(this);
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/translate'),
      onClick: (e) => {
        return this.wikitTranslate.bind(this);
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/correct'),
      onClick: (e) => {
        return this.wikitCorrect.bind(this);
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/expand'),
      onClick: (e) => {
        return this.wikitExpand.bind(this);
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('entities/iaTools/changeTone'),
      onClick: (e) => {
        return (fields, input) => {
          return this.wikitChangeTone(fields, null, input);
        };
      },
    });

    buttons.push({
      type: 'default',
      text: TranslateService.get('cancel'),
      onClick: (e) => {
        return null;
      },
    });
    return custom({
      title: TranslateService.get('entities/iaTools/popupTitle'),
      messageHtml: TranslateService.get('entities/iaTools/popupContent'),
      buttons: buttons,
    }).show();
  }

  createWikitToolsDatasource() {
    return new DataSource([{ name: TranslateService.get('entities/iaTools/simplify'), id: 'simplify' }, {
      name: TranslateService.get('entities/iaTools/shorten'), id: 'shorten',
    }, {
      name: TranslateService.get('entities/iaTools/translate'), id: 'translate',
    }, {
      name: TranslateService.get('entities/iaTools/correct'), id: 'correct',
    }, {
      name: TranslateService.get('entities/iaTools/expand'), id: 'expand',
    }, {
      name: TranslateService.get('entities/iaTools/changeTone'), id: 'changeTone',
    },
    ]);
  }

  processWikitChatBot(renderer2: Renderer2, document: Document, forHelpMe: boolean) {
    this.displayChatBot([GqlSubField.create('data', [GqlField.create('name'), GqlField.create('script')])], forHelpMe).subscribe(res => {

      if (res == undefined || res.data == undefined) {
        return;
      }


      let chatbotScript = res.data.script;
      let userId = this.authorizationService.user.getClaim(
        'name',
      );
      let userFirstName = this.authorizationService.user.getClaim(
        'family_name',
      );
      let userLastName = this.authorizationService.user.getClaim(
        'family_name',
      );
      let host = window.location.host;

      chatbotScript = chatbotScript.replace('CL_USER_ID', userId);
      chatbotScript = chatbotScript.replace('CL_USER_FIRST_NAME', userFirstName);
      chatbotScript = chatbotScript.replace('CL_USER_LAST_NAME', userLastName);
      chatbotScript = chatbotScript.replace('CL_ORIGIN_ID', host);
      let script = renderer2.createElement('script');
      script.type = `text/javascript`;
      script.id = 'WIKIT-CHATBOT-SCRIPT';
      script.text = chatbotScript;
      renderer2.appendChild(document.body, script);
    });
  }

}
