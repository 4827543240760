import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetClarilogServersBaseVariables, FirstClarilogServersBaseVariables, CountClarilogServersBaseVariables, FindClarilogServersBaseVariables, SearchClarilogServersBaseVariables, ExportSchemaClarilogServersBaseVariables, ExportDataClarilogServersBaseVariables, CustomQueryClarilogServersBaseVariables, CustomQueryIdClarilogServersBaseVariables, UsedClarilogServersBaseVariables, DefaultClarilogServersBaseVariables, TokenClarilogServersBaseVariables, ExistClarilogServersBaseVariables, FindRecyclesClarilogServersBaseVariables, CountRecyclesClarilogServersBaseVariables, ReadOnlyClarilogServersBaseVariables, FindArchivedClarilogServersBaseVariables, CountAllClarilogServersBaseVariables, FindDynamicPropertyFieldsClarilogServersBaseVariables, FindUnassociatedScanConfigurationsClarilogServersBaseVariables, FindUnassociatedEmailConnectorsClarilogServersBaseVariables, FindUnassociatedAlertsClarilogServersBaseVariables, FindUnassociatedMailboxObserverLogsClarilogServersBaseVariables, InsertClarilogServersBaseVariables, UpdateClarilogServersBaseVariables, ImportDataClarilogServersBaseVariables, UpdateManyClarilogServersBaseVariables, DeleteClarilogServersBaseVariables, RestoreClarilogServersBaseVariables, RecycleClarilogServersBaseVariables, RestoreArchivedClarilogServersBaseVariables, ArchivedClarilogServersBaseVariables, AddFileDynamicFieldClarilogServersBaseVariables, RemoveFileDynamicFieldClarilogServersBaseVariables, AddAlertsClarilogServersBaseVariables, RemoveAlertsClarilogServersBaseVariables, AddEmailConnectorsClarilogServersBaseVariables, RemoveEmailConnectorsClarilogServersBaseVariables, AddMailboxObserverLogsClarilogServersBaseVariables, RemoveMailboxObserverLogsClarilogServersBaseVariables, AddScanConfigurationsClarilogServersBaseVariables, RemoveScanConfigurationsClarilogServersBaseVariables } from '../gqls'
import { GetClarilogServersDocument, FirstClarilogServersDocument, CountClarilogServersDocument, FindClarilogServersDocument, SearchClarilogServersDocument, ExportSchemaClarilogServersDocument, ExportDataClarilogServersDocument, CustomQueryClarilogServersDocument, CustomQueryIdClarilogServersDocument, UsedClarilogServersDocument, DefaultClarilogServersDocument, TokenClarilogServersDocument, ExistClarilogServersDocument, FindRecyclesClarilogServersDocument, CountRecyclesClarilogServersDocument, ReadOnlyClarilogServersDocument, FindArchivedClarilogServersDocument, CountAllClarilogServersDocument, FindDynamicPropertyFieldsClarilogServersDocument, FindUnassociatedScanConfigurationsClarilogServersDocument, FindUnassociatedEmailConnectorsClarilogServersDocument, FindUnassociatedAlertsClarilogServersDocument, FindUnassociatedMailboxObserverLogsClarilogServersDocument, InsertClarilogServersDocument, UpdateClarilogServersDocument, ImportDataClarilogServersDocument, UpdateManyClarilogServersDocument, DeleteClarilogServersDocument, RestoreClarilogServersDocument, RecycleClarilogServersDocument, RestoreArchivedClarilogServersDocument, ArchivedClarilogServersDocument, AddFileDynamicFieldClarilogServersDocument, RemoveFileDynamicFieldClarilogServersDocument, AddAlertsClarilogServersDocument, RemoveAlertsClarilogServersDocument, AddEmailConnectorsClarilogServersDocument, RemoveEmailConnectorsClarilogServersDocument, AddMailboxObserverLogsClarilogServersDocument, RemoveMailboxObserverLogsClarilogServersDocument, AddScanConfigurationsClarilogServersDocument, RemoveScanConfigurationsClarilogServersDocument } from '../gqls'
import { ServiceSingleResultOfClarilogServer, QueryContextOfClarilogServer, FilterOfClarilogServer, ServiceSingleResultOfInt64, ServiceListResultOfClarilogServer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfClarilogServer, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfEmailConnector, FilterOfEmailConnector, ServiceListResultOfEmailConnector, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfMailboxObserverLog, FilterOfMailboxObserverLog, ServiceListResultOfMailboxObserverLog, ClarilogServerInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ClarilogServerBaseService {
    
public modelName = 'clarilogServer';
public modelPluralName = 'clarilogServers';

	private getClarilogServersQuery: GetClarilogServersDocument;
	private firstClarilogServersQuery: FirstClarilogServersDocument;
	private countClarilogServersQuery: CountClarilogServersDocument;
	private findClarilogServersQuery: FindClarilogServersDocument;
	private searchClarilogServersQuery: SearchClarilogServersDocument;
	private exportSchemaClarilogServersQuery: ExportSchemaClarilogServersDocument;
	private exportDataClarilogServersQuery: ExportDataClarilogServersDocument;
	private customQueryClarilogServersQuery: CustomQueryClarilogServersDocument;
	private customQueryIdClarilogServersQuery: CustomQueryIdClarilogServersDocument;
	private usedClarilogServersQuery: UsedClarilogServersDocument;
	private defaultClarilogServersQuery: DefaultClarilogServersDocument;
	private tokenClarilogServersQuery: TokenClarilogServersDocument;
	private existClarilogServersQuery: ExistClarilogServersDocument;
	private findRecyclesClarilogServersQuery: FindRecyclesClarilogServersDocument;
	private countRecyclesClarilogServersQuery: CountRecyclesClarilogServersDocument;
	private readOnlyClarilogServersQuery: ReadOnlyClarilogServersDocument;
	private findArchivedClarilogServersQuery: FindArchivedClarilogServersDocument;
	private countAllClarilogServersQuery: CountAllClarilogServersDocument;
	private findDynamicPropertyFieldsClarilogServersQuery: FindDynamicPropertyFieldsClarilogServersDocument;
	private findUnassociatedScanConfigurationsClarilogServersQuery: FindUnassociatedScanConfigurationsClarilogServersDocument;
	private findUnassociatedEmailConnectorsClarilogServersQuery: FindUnassociatedEmailConnectorsClarilogServersDocument;
	private findUnassociatedAlertsClarilogServersQuery: FindUnassociatedAlertsClarilogServersDocument;
	private findUnassociatedMailboxObserverLogsClarilogServersQuery: FindUnassociatedMailboxObserverLogsClarilogServersDocument;
	private insertClarilogServersMutation: InsertClarilogServersDocument;
	private updateClarilogServersMutation: UpdateClarilogServersDocument;
	private importDataClarilogServersMutation: ImportDataClarilogServersDocument;
	private updateManyClarilogServersMutation: UpdateManyClarilogServersDocument;
	private deleteClarilogServersMutation: DeleteClarilogServersDocument;
	private restoreClarilogServersMutation: RestoreClarilogServersDocument;
	private recycleClarilogServersMutation: RecycleClarilogServersDocument;
	private restoreArchivedClarilogServersMutation: RestoreArchivedClarilogServersDocument;
	private archivedClarilogServersMutation: ArchivedClarilogServersDocument;
	private addFileDynamicFieldClarilogServersMutation: AddFileDynamicFieldClarilogServersDocument;
	private removeFileDynamicFieldClarilogServersMutation: RemoveFileDynamicFieldClarilogServersDocument;
	private addAlertsClarilogServersMutation: AddAlertsClarilogServersDocument;
	private removeAlertsClarilogServersMutation: RemoveAlertsClarilogServersDocument;
	private addEmailConnectorsClarilogServersMutation: AddEmailConnectorsClarilogServersDocument;
	private removeEmailConnectorsClarilogServersMutation: RemoveEmailConnectorsClarilogServersDocument;
	private addMailboxObserverLogsClarilogServersMutation: AddMailboxObserverLogsClarilogServersDocument;
	private removeMailboxObserverLogsClarilogServersMutation: RemoveMailboxObserverLogsClarilogServersDocument;
	private addScanConfigurationsClarilogServersMutation: AddScanConfigurationsClarilogServersDocument;
	private removeScanConfigurationsClarilogServersMutation: RemoveScanConfigurationsClarilogServersDocument;

	constructor(private injector: Injector) {
		this.getClarilogServersQuery = this.injector.get(GetClarilogServersDocument);
		this.firstClarilogServersQuery = this.injector.get(FirstClarilogServersDocument);
		this.countClarilogServersQuery = this.injector.get(CountClarilogServersDocument);
		this.findClarilogServersQuery = this.injector.get(FindClarilogServersDocument);
		this.searchClarilogServersQuery = this.injector.get(SearchClarilogServersDocument);
		this.exportSchemaClarilogServersQuery = this.injector.get(ExportSchemaClarilogServersDocument);
		this.exportDataClarilogServersQuery = this.injector.get(ExportDataClarilogServersDocument);
		this.customQueryClarilogServersQuery = this.injector.get(CustomQueryClarilogServersDocument);
		this.customQueryIdClarilogServersQuery = this.injector.get(CustomQueryIdClarilogServersDocument);
		this.usedClarilogServersQuery = this.injector.get(UsedClarilogServersDocument);
		this.defaultClarilogServersQuery = this.injector.get(DefaultClarilogServersDocument);
		this.tokenClarilogServersQuery = this.injector.get(TokenClarilogServersDocument);
		this.existClarilogServersQuery = this.injector.get(ExistClarilogServersDocument);
		this.findRecyclesClarilogServersQuery = this.injector.get(FindRecyclesClarilogServersDocument);
		this.countRecyclesClarilogServersQuery = this.injector.get(CountRecyclesClarilogServersDocument);
		this.readOnlyClarilogServersQuery = this.injector.get(ReadOnlyClarilogServersDocument);
		this.findArchivedClarilogServersQuery = this.injector.get(FindArchivedClarilogServersDocument);
		this.countAllClarilogServersQuery = this.injector.get(CountAllClarilogServersDocument);
		this.findDynamicPropertyFieldsClarilogServersQuery = this.injector.get(FindDynamicPropertyFieldsClarilogServersDocument);
		this.findUnassociatedScanConfigurationsClarilogServersQuery = this.injector.get(FindUnassociatedScanConfigurationsClarilogServersDocument);
		this.findUnassociatedEmailConnectorsClarilogServersQuery = this.injector.get(FindUnassociatedEmailConnectorsClarilogServersDocument);
		this.findUnassociatedAlertsClarilogServersQuery = this.injector.get(FindUnassociatedAlertsClarilogServersDocument);
		this.findUnassociatedMailboxObserverLogsClarilogServersQuery = this.injector.get(FindUnassociatedMailboxObserverLogsClarilogServersDocument);
		this.insertClarilogServersMutation = this.injector.get(InsertClarilogServersDocument);
		this.updateClarilogServersMutation = this.injector.get(UpdateClarilogServersDocument);
		this.importDataClarilogServersMutation = this.injector.get(ImportDataClarilogServersDocument);
		this.updateManyClarilogServersMutation = this.injector.get(UpdateManyClarilogServersDocument);
		this.deleteClarilogServersMutation = this.injector.get(DeleteClarilogServersDocument);
		this.restoreClarilogServersMutation = this.injector.get(RestoreClarilogServersDocument);
		this.recycleClarilogServersMutation = this.injector.get(RecycleClarilogServersDocument);
		this.restoreArchivedClarilogServersMutation = this.injector.get(RestoreArchivedClarilogServersDocument);
		this.archivedClarilogServersMutation = this.injector.get(ArchivedClarilogServersDocument);
		this.addFileDynamicFieldClarilogServersMutation = this.injector.get(AddFileDynamicFieldClarilogServersDocument);
		this.removeFileDynamicFieldClarilogServersMutation = this.injector.get(RemoveFileDynamicFieldClarilogServersDocument);
		this.addAlertsClarilogServersMutation = this.injector.get(AddAlertsClarilogServersDocument);
		this.removeAlertsClarilogServersMutation = this.injector.get(RemoveAlertsClarilogServersDocument);
		this.addEmailConnectorsClarilogServersMutation = this.injector.get(AddEmailConnectorsClarilogServersDocument);
		this.removeEmailConnectorsClarilogServersMutation = this.injector.get(RemoveEmailConnectorsClarilogServersDocument);
		this.addMailboxObserverLogsClarilogServersMutation = this.injector.get(AddMailboxObserverLogsClarilogServersDocument);
		this.removeMailboxObserverLogsClarilogServersMutation = this.injector.get(RemoveMailboxObserverLogsClarilogServersDocument);
		this.addScanConfigurationsClarilogServersMutation = this.injector.get(AddScanConfigurationsClarilogServersDocument);
		this.removeScanConfigurationsClarilogServersMutation = this.injector.get(RemoveScanConfigurationsClarilogServersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: GetClarilogServersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.get));
            }
            else{
                let result:ServiceSingleResultOfClarilogServer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: FirstClarilogServersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountClarilogServersBaseVariables = {
    			filter: filter
    		}
    				return this.countClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfClarilogServer> {
    
    		let variables: FindClarilogServersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfClarilogServer> {
    
    		let variables: SearchClarilogServersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaClarilogServersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataClarilogServersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfClarilogServer> {
    
    		let variables: CustomQueryClarilogServersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfClarilogServer> {
    
    		let variables: CustomQueryIdClarilogServersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedClarilogServersBaseVariables = {
    			ids: ids
    		}
    				return this.usedClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.used));
    	}

    	/**  */
    	@InjectArgs
    	public default(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: DefaultClarilogServersBaseVariables = {
    
    		}
    				return this.defaultClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.default));
    	}

    	/**  */
    	@InjectArgs
    	public token(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: TokenClarilogServersBaseVariables = {
    
    		}
    				return this.tokenClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.token));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistClarilogServersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfClarilogServer> {
    
    		let variables: FindRecyclesClarilogServersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesClarilogServersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyClarilogServersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfClarilogServer,
		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfClarilogServer> {
    
    		let variables: FindArchivedClarilogServersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllClarilogServersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsClarilogServersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsClarilogServersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedEmailConnectors(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfEmailConnector,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailConnector,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfEmailConnector> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('emailConnectors', fields, null, [
			GqlSubFieldArg.create('filterOfEmailConnectors', 'filter'),
			GqlSubFieldArg.create('optionsOfEmailConnectors', 'options'),
		]),
		])
		extendedVariables['filterOfEmailConnectors'] = filter;
		extendedVariables['optionsOfEmailConnectors'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.emailConnectors));
            }
            else{
                let result: ServiceListResultOfEmailConnector = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedEmailConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailConnector,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnector> {
    
    		let variables: FindUnassociatedEmailConnectorsClarilogServersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedEmailConnectorsClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findUnassociatedEmailConnectors));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsClarilogServersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedMailboxObserverLogs(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfMailboxObserverLog> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('mailboxObserverLogs', fields, null, [
			GqlSubFieldArg.create('filterOfMailboxObserverLogs', 'filter'),
			GqlSubFieldArg.create('optionsOfMailboxObserverLogs', 'options'),
		]),
		])
		extendedVariables['filterOfMailboxObserverLogs'] = filter;
		extendedVariables['optionsOfMailboxObserverLogs'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.mailboxObserverLogs));
            }
            else{
                let result: ServiceListResultOfMailboxObserverLog = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedMailboxObserverLogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailboxObserverLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfMailboxObserverLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailboxObserverLog> {
    
    		let variables: FindUnassociatedMailboxObserverLogsClarilogServersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedMailboxObserverLogsClarilogServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.findUnassociatedMailboxObserverLogs));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ClarilogServerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: InsertClarilogServersBaseVariables = {
    			entity: entity
    		}
    				return this.insertClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: UpdateClarilogServersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataClarilogServersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfClarilogServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyClarilogServersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteClarilogServersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreClarilogServersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleClarilogServersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedClarilogServersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedClarilogServersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldClarilogServersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldClarilogServersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAlertsClarilogServersBaseVariables = {
    			id: id,
			alertIds: alertIds
    		}
    				return this.addAlertsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.addAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public removeAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAlertsClarilogServersBaseVariables = {
    			alertIds: alertIds
    		}
    				return this.removeAlertsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.removeAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public addEmailConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailConnectorIds') emailConnectorIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddEmailConnectorsClarilogServersBaseVariables = {
    			id: id,
			emailConnectorIds: emailConnectorIds
    		}
    				return this.addEmailConnectorsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.addEmailConnectors));
    	}

    	/**  */
    	@InjectArgs
    	public removeEmailConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('emailConnectorIds') emailConnectorIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveEmailConnectorsClarilogServersBaseVariables = {
    			emailConnectorIds: emailConnectorIds
    		}
    				return this.removeEmailConnectorsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.removeEmailConnectors));
    	}

    	/**  */
    	@InjectArgs
    	public addMailboxObserverLogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('mailboxObserverLogIds') mailboxObserverLogIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddMailboxObserverLogsClarilogServersBaseVariables = {
    			id: id,
			mailboxObserverLogIds: mailboxObserverLogIds
    		}
    				return this.addMailboxObserverLogsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.addMailboxObserverLogs));
    	}

    	/**  */
    	@InjectArgs
    	public removeMailboxObserverLogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('mailboxObserverLogIds') mailboxObserverLogIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveMailboxObserverLogsClarilogServersBaseVariables = {
    			mailboxObserverLogIds: mailboxObserverLogIds
    		}
    				return this.removeMailboxObserverLogsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.removeMailboxObserverLogs));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsClarilogServersBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsClarilogServersBaseVariables = {
    			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsClarilogServersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogServers.removeScanConfigurations));
    	}
    
}