import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';
import { UserCoreService } from '../user.service';

/** Classe de base des type de storage */
export class BaseStorageService {
  _userService: UserCoreService;

  constructor(
    userService: UserCoreService,
    private authorizationService: AuthorizationCoreService,
  ) {
    this._userService = userService;
  }

  getUserId() {
    return this.authorizationService.user.getClaim('userId');
  }
}
