import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketEmailTasksBaseVariables, FirstTicketEmailTasksBaseVariables, CountTicketEmailTasksBaseVariables, FindTicketEmailTasksBaseVariables, SearchTicketEmailTasksBaseVariables, ExportSchemaTicketEmailTasksBaseVariables, ExportDataTicketEmailTasksBaseVariables, CustomQueryTicketEmailTasksBaseVariables, CustomQueryIdTicketEmailTasksBaseVariables, UsedTicketEmailTasksBaseVariables, ExistTicketEmailTasksBaseVariables, InsertTicketEmailTasksBaseVariables, UpdateTicketEmailTasksBaseVariables, ImportDataTicketEmailTasksBaseVariables, UpdateManyTicketEmailTasksBaseVariables, DeleteTicketEmailTasksBaseVariables } from '../gqls'
import { GetTicketEmailTasksDocument, FirstTicketEmailTasksDocument, CountTicketEmailTasksDocument, FindTicketEmailTasksDocument, SearchTicketEmailTasksDocument, ExportSchemaTicketEmailTasksDocument, ExportDataTicketEmailTasksDocument, CustomQueryTicketEmailTasksDocument, CustomQueryIdTicketEmailTasksDocument, UsedTicketEmailTasksDocument, ExistTicketEmailTasksDocument, InsertTicketEmailTasksDocument, UpdateTicketEmailTasksDocument, ImportDataTicketEmailTasksDocument, UpdateManyTicketEmailTasksDocument, DeleteTicketEmailTasksDocument } from '../gqls'
import { ServiceSingleResultOfTicketEmailTask, QueryContextOfTicketEmailTask, FilterOfTicketEmailTask, ServiceSingleResultOfInt64, ServiceListResultOfTicketEmailTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketEmailTaskInput, FieldUpdateOperatorOfTicketEmailTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketEmailTaskBaseService {
    
public modelName = 'ticketEmailTask';
public modelPluralName = 'ticketEmailTasks';

	private getTicketEmailTasksQuery: GetTicketEmailTasksDocument;
	private firstTicketEmailTasksQuery: FirstTicketEmailTasksDocument;
	private countTicketEmailTasksQuery: CountTicketEmailTasksDocument;
	private findTicketEmailTasksQuery: FindTicketEmailTasksDocument;
	private searchTicketEmailTasksQuery: SearchTicketEmailTasksDocument;
	private exportSchemaTicketEmailTasksQuery: ExportSchemaTicketEmailTasksDocument;
	private exportDataTicketEmailTasksQuery: ExportDataTicketEmailTasksDocument;
	private customQueryTicketEmailTasksQuery: CustomQueryTicketEmailTasksDocument;
	private customQueryIdTicketEmailTasksQuery: CustomQueryIdTicketEmailTasksDocument;
	private usedTicketEmailTasksQuery: UsedTicketEmailTasksDocument;
	private existTicketEmailTasksQuery: ExistTicketEmailTasksDocument;
	private insertTicketEmailTasksMutation: InsertTicketEmailTasksDocument;
	private updateTicketEmailTasksMutation: UpdateTicketEmailTasksDocument;
	private importDataTicketEmailTasksMutation: ImportDataTicketEmailTasksDocument;
	private updateManyTicketEmailTasksMutation: UpdateManyTicketEmailTasksDocument;
	private deleteTicketEmailTasksMutation: DeleteTicketEmailTasksDocument;

	constructor(private injector: Injector) {
		this.getTicketEmailTasksQuery = this.injector.get(GetTicketEmailTasksDocument);
		this.firstTicketEmailTasksQuery = this.injector.get(FirstTicketEmailTasksDocument);
		this.countTicketEmailTasksQuery = this.injector.get(CountTicketEmailTasksDocument);
		this.findTicketEmailTasksQuery = this.injector.get(FindTicketEmailTasksDocument);
		this.searchTicketEmailTasksQuery = this.injector.get(SearchTicketEmailTasksDocument);
		this.exportSchemaTicketEmailTasksQuery = this.injector.get(ExportSchemaTicketEmailTasksDocument);
		this.exportDataTicketEmailTasksQuery = this.injector.get(ExportDataTicketEmailTasksDocument);
		this.customQueryTicketEmailTasksQuery = this.injector.get(CustomQueryTicketEmailTasksDocument);
		this.customQueryIdTicketEmailTasksQuery = this.injector.get(CustomQueryIdTicketEmailTasksDocument);
		this.usedTicketEmailTasksQuery = this.injector.get(UsedTicketEmailTasksDocument);
		this.existTicketEmailTasksQuery = this.injector.get(ExistTicketEmailTasksDocument);
		this.insertTicketEmailTasksMutation = this.injector.get(InsertTicketEmailTasksDocument);
		this.updateTicketEmailTasksMutation = this.injector.get(UpdateTicketEmailTasksDocument);
		this.importDataTicketEmailTasksMutation = this.injector.get(ImportDataTicketEmailTasksDocument);
		this.updateManyTicketEmailTasksMutation = this.injector.get(UpdateManyTicketEmailTasksDocument);
		this.deleteTicketEmailTasksMutation = this.injector.get(DeleteTicketEmailTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmailTask> {
    
    		let variables: GetTicketEmailTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.get));
            }
            else{
                let result:ServiceSingleResultOfTicketEmailTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmailTask,
		@Args('filter?') filter?: FilterOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmailTask> {
    
    		let variables: FirstTicketEmailTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketEmailTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketEmailTask,
		@Args('filter?') filter?: FilterOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmailTask> {
    
    		let variables: FindTicketEmailTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketEmailTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmailTask> {
    
    		let variables: SearchTicketEmailTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketEmailTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketEmailTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketEmailTask,
		@Args('filter?') filter?: FilterOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmailTask> {
    
    		let variables: CustomQueryTicketEmailTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketEmailTask,
		@Args('filter?') filter?: FilterOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketEmailTask> {
    
    		let variables: CustomQueryIdTicketEmailTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketEmailTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketEmailTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketEmailTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketEmailTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmailTask> {
    
    		let variables: InsertTicketEmailTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketEmailTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketEmailTask> {
    
    		let variables: UpdateTicketEmailTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketEmailTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketEmailTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketEmailTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketEmailTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketEmailTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketEmailTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketEmailTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketEmailTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketEmailTasks.delete));
    	}
    
}