import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetQueryBuildersBaseVariables, FirstQueryBuildersBaseVariables, CountQueryBuildersBaseVariables, FindQueryBuildersBaseVariables, SearchQueryBuildersBaseVariables, ExportSchemaQueryBuildersBaseVariables, ExportDataQueryBuildersBaseVariables, CustomQueryQueryBuildersBaseVariables, CustomQueryIdQueryBuildersBaseVariables, FindUnassociatedSecurityGroupsQueryBuildersBaseVariables, UsedQueryBuildersBaseVariables, SendMailResultQueryBuildersBaseVariables, FindQueryBuilderAppointmentsQueryBuildersBaseVariables, FindQueryBuilderWithEmailProtoQueryBuildersBaseVariables, ExistQueryBuildersBaseVariables, FindUnassociatedEmailPrototypesQueryBuildersBaseVariables, FindUnassociatedSftpConnectorsQueryBuildersBaseVariables, InsertQueryBuildersBaseVariables, UpdateQueryBuildersBaseVariables, ImportDataQueryBuildersBaseVariables, UpdateManyQueryBuildersBaseVariables, DeleteQueryBuildersBaseVariables, AddSecurityGroupQueryBuildersBaseVariables, RemoveSecurityGroupQueryBuildersBaseVariables, DuplicateQueryBuilderQueryBuildersBaseVariables, AddAppointmentsQueryBuildersBaseVariables, AddAppointmentQueryBuildersBaseVariables, UpdateAppointmentsQueryBuildersBaseVariables, UpdateAppointmentQueryBuildersBaseVariables, RemoveAppointmentsQueryBuildersBaseVariables, DeleteAppointmentsQueryBuildersBaseVariables, AddFavoriteQueryBuildersBaseVariables, RemoveFavoriteQueryBuildersBaseVariables, AddEmailPrototypesQueryBuildersBaseVariables, RemoveEmailPrototypesQueryBuildersBaseVariables, AddSftpConnectorsQueryBuildersBaseVariables, RemoveSftpConnectorsQueryBuildersBaseVariables } from '../gqls'
import { GetQueryBuildersDocument, FirstQueryBuildersDocument, CountQueryBuildersDocument, FindQueryBuildersDocument, SearchQueryBuildersDocument, ExportSchemaQueryBuildersDocument, ExportDataQueryBuildersDocument, CustomQueryQueryBuildersDocument, CustomQueryIdQueryBuildersDocument, FindUnassociatedSecurityGroupsQueryBuildersDocument, UsedQueryBuildersDocument, SendMailResultQueryBuildersDocument, FindQueryBuilderAppointmentsQueryBuildersDocument, FindQueryBuilderWithEmailProtoQueryBuildersDocument, ExistQueryBuildersDocument, FindUnassociatedEmailPrototypesQueryBuildersDocument, FindUnassociatedSftpConnectorsQueryBuildersDocument, InsertQueryBuildersDocument, UpdateQueryBuildersDocument, ImportDataQueryBuildersDocument, UpdateManyQueryBuildersDocument, DeleteQueryBuildersDocument, AddSecurityGroupQueryBuildersDocument, RemoveSecurityGroupQueryBuildersDocument, DuplicateQueryBuilderQueryBuildersDocument, AddAppointmentsQueryBuildersDocument, AddAppointmentQueryBuildersDocument, UpdateAppointmentsQueryBuildersDocument, UpdateAppointmentQueryBuildersDocument, RemoveAppointmentsQueryBuildersDocument, DeleteAppointmentsQueryBuildersDocument, AddFavoriteQueryBuildersDocument, RemoveFavoriteQueryBuildersDocument, AddEmailPrototypesQueryBuildersDocument, RemoveEmailPrototypesQueryBuildersDocument, AddSftpConnectorsQueryBuildersDocument, RemoveSftpConnectorsQueryBuildersDocument } from '../gqls'
import { ServiceSingleResultOfQueryBuilder, QueryContextOfQueryBuilder, FilterOfQueryBuilder, ServiceSingleResultOfInt64, ServiceListResultOfQueryBuilder, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceSingleResultOfBoolean, QueryContextOfQueryBuilderQueriesResolver, ServiceListResultOfQueryBuilderAppointment, QueryContextOfQueryBuilderAppointment, FilterOfQueryBuilderAppointment, QueryContextOfEmailPrototype, FilterOfEmailPrototype, ServiceListResultOfEmailPrototype, QueryContextOfSftpConnector, FilterOfSftpConnector, ServiceListResultOfSftpConnector, FieldUpdateOperatorOfQueryBuilder, QueryBuilderAppointmentInput, FieldUpdatesOperatorOfQueryBuilderAppointment } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class QueryBuilderBaseService {
    
public modelName = 'queryBuilder';
public modelPluralName = 'queryBuilders';

	private getQueryBuildersQuery: GetQueryBuildersDocument;
	private firstQueryBuildersQuery: FirstQueryBuildersDocument;
	private countQueryBuildersQuery: CountQueryBuildersDocument;
	private findQueryBuildersQuery: FindQueryBuildersDocument;
	private searchQueryBuildersQuery: SearchQueryBuildersDocument;
	private exportSchemaQueryBuildersQuery: ExportSchemaQueryBuildersDocument;
	private exportDataQueryBuildersQuery: ExportDataQueryBuildersDocument;
	private customQueryQueryBuildersQuery: CustomQueryQueryBuildersDocument;
	private customQueryIdQueryBuildersQuery: CustomQueryIdQueryBuildersDocument;
	private findUnassociatedSecurityGroupsQueryBuildersQuery: FindUnassociatedSecurityGroupsQueryBuildersDocument;
	private usedQueryBuildersQuery: UsedQueryBuildersDocument;
	private sendMailResultQueryBuildersQuery: SendMailResultQueryBuildersDocument;
	private findQueryBuilderAppointmentsQueryBuildersQuery: FindQueryBuilderAppointmentsQueryBuildersDocument;
	private findQueryBuilderWithEmailProtoQueryBuildersQuery: FindQueryBuilderWithEmailProtoQueryBuildersDocument;
	private existQueryBuildersQuery: ExistQueryBuildersDocument;
	private findUnassociatedEmailPrototypesQueryBuildersQuery: FindUnassociatedEmailPrototypesQueryBuildersDocument;
	private findUnassociatedSftpConnectorsQueryBuildersQuery: FindUnassociatedSftpConnectorsQueryBuildersDocument;
	private insertQueryBuildersMutation: InsertQueryBuildersDocument;
	private updateQueryBuildersMutation: UpdateQueryBuildersDocument;
	private importDataQueryBuildersMutation: ImportDataQueryBuildersDocument;
	private updateManyQueryBuildersMutation: UpdateManyQueryBuildersDocument;
	private deleteQueryBuildersMutation: DeleteQueryBuildersDocument;
	private addSecurityGroupQueryBuildersMutation: AddSecurityGroupQueryBuildersDocument;
	private removeSecurityGroupQueryBuildersMutation: RemoveSecurityGroupQueryBuildersDocument;
	private duplicateQueryBuilderQueryBuildersMutation: DuplicateQueryBuilderQueryBuildersDocument;
	private addAppointmentsQueryBuildersMutation: AddAppointmentsQueryBuildersDocument;
	private addAppointmentQueryBuildersMutation: AddAppointmentQueryBuildersDocument;
	private updateAppointmentsQueryBuildersMutation: UpdateAppointmentsQueryBuildersDocument;
	private updateAppointmentQueryBuildersMutation: UpdateAppointmentQueryBuildersDocument;
	private removeAppointmentsQueryBuildersMutation: RemoveAppointmentsQueryBuildersDocument;
	private deleteAppointmentsQueryBuildersMutation: DeleteAppointmentsQueryBuildersDocument;
	private addFavoriteQueryBuildersMutation: AddFavoriteQueryBuildersDocument;
	private removeFavoriteQueryBuildersMutation: RemoveFavoriteQueryBuildersDocument;
	private addEmailPrototypesQueryBuildersMutation: AddEmailPrototypesQueryBuildersDocument;
	private removeEmailPrototypesQueryBuildersMutation: RemoveEmailPrototypesQueryBuildersDocument;
	private addSftpConnectorsQueryBuildersMutation: AddSftpConnectorsQueryBuildersDocument;
	private removeSftpConnectorsQueryBuildersMutation: RemoveSftpConnectorsQueryBuildersDocument;

	constructor(private injector: Injector) {
		this.getQueryBuildersQuery = this.injector.get(GetQueryBuildersDocument);
		this.firstQueryBuildersQuery = this.injector.get(FirstQueryBuildersDocument);
		this.countQueryBuildersQuery = this.injector.get(CountQueryBuildersDocument);
		this.findQueryBuildersQuery = this.injector.get(FindQueryBuildersDocument);
		this.searchQueryBuildersQuery = this.injector.get(SearchQueryBuildersDocument);
		this.exportSchemaQueryBuildersQuery = this.injector.get(ExportSchemaQueryBuildersDocument);
		this.exportDataQueryBuildersQuery = this.injector.get(ExportDataQueryBuildersDocument);
		this.customQueryQueryBuildersQuery = this.injector.get(CustomQueryQueryBuildersDocument);
		this.customQueryIdQueryBuildersQuery = this.injector.get(CustomQueryIdQueryBuildersDocument);
		this.findUnassociatedSecurityGroupsQueryBuildersQuery = this.injector.get(FindUnassociatedSecurityGroupsQueryBuildersDocument);
		this.usedQueryBuildersQuery = this.injector.get(UsedQueryBuildersDocument);
		this.sendMailResultQueryBuildersQuery = this.injector.get(SendMailResultQueryBuildersDocument);
		this.findQueryBuilderAppointmentsQueryBuildersQuery = this.injector.get(FindQueryBuilderAppointmentsQueryBuildersDocument);
		this.findQueryBuilderWithEmailProtoQueryBuildersQuery = this.injector.get(FindQueryBuilderWithEmailProtoQueryBuildersDocument);
		this.existQueryBuildersQuery = this.injector.get(ExistQueryBuildersDocument);
		this.findUnassociatedEmailPrototypesQueryBuildersQuery = this.injector.get(FindUnassociatedEmailPrototypesQueryBuildersDocument);
		this.findUnassociatedSftpConnectorsQueryBuildersQuery = this.injector.get(FindUnassociatedSftpConnectorsQueryBuildersDocument);
		this.insertQueryBuildersMutation = this.injector.get(InsertQueryBuildersDocument);
		this.updateQueryBuildersMutation = this.injector.get(UpdateQueryBuildersDocument);
		this.importDataQueryBuildersMutation = this.injector.get(ImportDataQueryBuildersDocument);
		this.updateManyQueryBuildersMutation = this.injector.get(UpdateManyQueryBuildersDocument);
		this.deleteQueryBuildersMutation = this.injector.get(DeleteQueryBuildersDocument);
		this.addSecurityGroupQueryBuildersMutation = this.injector.get(AddSecurityGroupQueryBuildersDocument);
		this.removeSecurityGroupQueryBuildersMutation = this.injector.get(RemoveSecurityGroupQueryBuildersDocument);
		this.duplicateQueryBuilderQueryBuildersMutation = this.injector.get(DuplicateQueryBuilderQueryBuildersDocument);
		this.addAppointmentsQueryBuildersMutation = this.injector.get(AddAppointmentsQueryBuildersDocument);
		this.addAppointmentQueryBuildersMutation = this.injector.get(AddAppointmentQueryBuildersDocument);
		this.updateAppointmentsQueryBuildersMutation = this.injector.get(UpdateAppointmentsQueryBuildersDocument);
		this.updateAppointmentQueryBuildersMutation = this.injector.get(UpdateAppointmentQueryBuildersDocument);
		this.removeAppointmentsQueryBuildersMutation = this.injector.get(RemoveAppointmentsQueryBuildersDocument);
		this.deleteAppointmentsQueryBuildersMutation = this.injector.get(DeleteAppointmentsQueryBuildersDocument);
		this.addFavoriteQueryBuildersMutation = this.injector.get(AddFavoriteQueryBuildersDocument);
		this.removeFavoriteQueryBuildersMutation = this.injector.get(RemoveFavoriteQueryBuildersDocument);
		this.addEmailPrototypesQueryBuildersMutation = this.injector.get(AddEmailPrototypesQueryBuildersDocument);
		this.removeEmailPrototypesQueryBuildersMutation = this.injector.get(RemoveEmailPrototypesQueryBuildersDocument);
		this.addSftpConnectorsQueryBuildersMutation = this.injector.get(AddSftpConnectorsQueryBuildersDocument);
		this.removeSftpConnectorsQueryBuildersMutation = this.injector.get(RemoveSftpConnectorsQueryBuildersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilder> {
    
    		let variables: GetQueryBuildersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.get));
            }
            else{
                let result:ServiceSingleResultOfQueryBuilder={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilder> {
    
    		let variables: FirstQueryBuildersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountQueryBuildersBaseVariables = {
    			filter: filter
    		}
    				return this.countQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilder> {
    
    		let variables: FindQueryBuildersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilder> {
    
    		let variables: SearchQueryBuildersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaQueryBuildersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataQueryBuildersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilder> {
    
    		let variables: CustomQueryQueryBuildersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilder> {
    
    		let variables: CustomQueryIdQueryBuildersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsQueryBuildersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.findUnassociatedSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedQueryBuildersBaseVariables = {
    			ids: ids
    		}
    				return this.usedQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.used));
    	}

    	/** Permet l'envoi d'un email avec le résultat dans un excel */
    	@InjectArgs
    	public sendMailResult(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfQueryBuilderQueriesResolver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SendMailResultQueryBuildersBaseVariables = {
    			id: id,
			options: options
    		}
    				return this.sendMailResultQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.sendMailResult));
    	}

    	/** Permet de trouver les plannifications de requetteur */
    	@InjectArgs
    	public findQueryBuilderAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilderAppointment,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfQueryBuilderAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilderAppointment> {
    
    		let variables: FindQueryBuilderAppointmentsQueryBuildersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findQueryBuilderAppointmentsQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.findQueryBuilderAppointments));
    	}

    	/** Permet de trouver les requêteurs avec au moins un email proto. */
    	@InjectArgs
    	public findQueryBuilderWithEmailProto(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilder> {
    
    		let variables: FindQueryBuilderWithEmailProtoQueryBuildersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findQueryBuilderWithEmailProtoQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.findQueryBuilderWithEmailProto));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistQueryBuildersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.exist));
    	}

	@InjectArgs
	public findAssociatedEmailPrototypes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfEmailPrototype,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototype,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfEmailPrototype> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('emailPrototypes', fields, null, [
			GqlSubFieldArg.create('filterOfEmailPrototypes', 'filter'),
			GqlSubFieldArg.create('optionsOfEmailPrototypes', 'options'),
		]),
		])
		extendedVariables['filterOfEmailPrototypes'] = filter;
		extendedVariables['optionsOfEmailPrototypes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.emailPrototypes));
            }
            else{
                let result: ServiceListResultOfEmailPrototype = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedEmailPrototypes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototype,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototype,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototype> {
    
    		let variables: FindUnassociatedEmailPrototypesQueryBuildersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedEmailPrototypesQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.findUnassociatedEmailPrototypes));
    	}

	@InjectArgs
	public findAssociatedSftpConnectors(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSftpConnector,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSftpConnector> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('sftpConnectors', fields, null, [
			GqlSubFieldArg.create('filterOfSftpConnectors', 'filter'),
			GqlSubFieldArg.create('optionsOfSftpConnectors', 'options'),
		]),
		])
		extendedVariables['filterOfSftpConnectors'] = filter;
		extendedVariables['optionsOfSftpConnectors'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.sftpConnectors));
            }
            else{
                let result: ServiceListResultOfSftpConnector = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSftpConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnector> {
    
    		let variables: FindUnassociatedSftpConnectorsQueryBuildersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSftpConnectorsQueryBuildersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.findUnassociatedSftpConnectors));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: QueryBuilderInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilder> {
    
    		let variables: InsertQueryBuildersBaseVariables = {
    			entity: entity
    		}
    				return this.insertQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilder> {
    
    		let variables: UpdateQueryBuildersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataQueryBuildersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyQueryBuildersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteQueryBuildersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupQueryBuildersBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupQueryBuildersBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.removeSecurityGroup));
    	}

    	/** Permet de dupliquer un requêteur. */
    	@InjectArgs
    	public duplicateQueryBuilder(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DuplicateQueryBuilderQueryBuildersBaseVariables = {
    			ids: ids,
			name: name,
			type: type
    		}
    				return this.duplicateQueryBuilderQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.duplicateQueryBuilder));
    	}

    	/** Permet d'ajouter une planification sur des requêteurs. */
    	@InjectArgs
    	public addAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('queryBuilderAppointments?') queryBuilderAppointments?: Array<Maybe<QueryBuilderAppointmentInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAppointmentsQueryBuildersBaseVariables = {
    			queryBuilderAppointments: queryBuilderAppointments,
			id: id
    		}
    				return this.addAppointmentsQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.addAppointments));
    	}

    	/** Permet d'ajouter une planification sur un requêteurs */
    	@InjectArgs
    	public addAppointment(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilderAppointment?') queryBuilderAppointment?: QueryBuilderAppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAppointmentQueryBuildersBaseVariables = {
    			queryBuilderAppointment: queryBuilderAppointment
    		}
    				return this.addAppointmentQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.addAppointment));
    	}

    	/** Permet de modifier des planification de requetteur */
    	@InjectArgs
    	public updateAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entry?') entry?: Array<Maybe<FieldUpdatesOperatorOfQueryBuilderAppointment>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateAppointmentsQueryBuildersBaseVariables = {
    			entry: entry
    		}
    				return this.updateAppointmentsQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.updateAppointments));
    	}

    	/** Permet de modifier une planification de requetteur */
    	@InjectArgs
    	public updateAppointment(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('modified?') modified?: QueryBuilderAppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateAppointmentQueryBuildersBaseVariables = {
    			id: id,
			modified: modified
    		}
    				return this.updateAppointmentQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.updateAppointment));
    	}

    	/** Permet de supprimer des planification de requetteur */
    	@InjectArgs
    	public removeAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilderAppointments') queryBuilderAppointments: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAppointmentsQueryBuildersBaseVariables = {
    			queryBuilderAppointments: queryBuilderAppointments
    		}
    				return this.removeAppointmentsQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.removeAppointments));
    	}

    	/** Permet de supprimer des planification de requetteur */
    	@InjectArgs
    	public deleteAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAppointmentsQueryBuildersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAppointmentsQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.deleteAppointments));
    	}

    	/**  */
    	@InjectArgs
    	public addFavorite(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFavoriteQueryBuildersBaseVariables = {
    			id: id
    		}
    				return this.addFavoriteQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.addFavorite));
    	}

    	/**  */
    	@InjectArgs
    	public removeFavorite(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFavoriteQueryBuildersBaseVariables = {
    			id: id
    		}
    				return this.removeFavoriteQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.removeFavorite));
    	}

    	/**  */
    	@InjectArgs
    	public addEmailPrototypes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailPrototypeIds') emailPrototypeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddEmailPrototypesQueryBuildersBaseVariables = {
    			id: id,
			emailPrototypeIds: emailPrototypeIds
    		}
    				return this.addEmailPrototypesQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.addEmailPrototypes));
    	}

    	/**  */
    	@InjectArgs
    	public removeEmailPrototypes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailPrototypeIds') emailPrototypeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveEmailPrototypesQueryBuildersBaseVariables = {
    			id: id,
			emailPrototypeIds: emailPrototypeIds
    		}
    				return this.removeEmailPrototypesQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.removeEmailPrototypes));
    	}

    	/**  */
    	@InjectArgs
    	public addSftpConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('sftpConnectorIds') sftpConnectorIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSftpConnectorsQueryBuildersBaseVariables = {
    			id: id,
			sftpConnectorIds: sftpConnectorIds
    		}
    				return this.addSftpConnectorsQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.addSftpConnectors));
    	}

    	/**  */
    	@InjectArgs
    	public removeSftpConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('sftpConnectorIds') sftpConnectorIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSftpConnectorsQueryBuildersBaseVariables = {
    			id: id,
			sftpConnectorIds: sftpConnectorIds
    		}
    				return this.removeSftpConnectorsQueryBuildersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilders.removeSftpConnectors));
    	}
    
}