import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CoreNavMenuComponent, CoreNavMenuItemComponent } from '.';

@Injectable({
  providedIn: 'root',
})
export class NavMenuNavigator {
  constructor(private router: Router, private route: ActivatedRoute) {}
  private subscription: Subscription;
  public navigate(menu: CoreNavMenuComponent) {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.router.events.subscribe((event) => {
      // NavigationStart
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
      if (event instanceof NavigationEnd) {
        this.internalNavigate(menu);
      }
    });
    this.internalNavigate(menu);
  }

  /** Permet de calculer si le router doit être afficher en tant que sélectionner
   *  le <exact> permet de dire si le chemin doit être exact ou si il peut être plus permissif
   */
  public isActiveRouter(route: string, exact: boolean): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    const actionPath = ['new', 'trash', 'edit', 'deployments'];
    let result = false;
    if (exact) {
      if (baseUrl === route) {
        return true;
      }
      // if (route.indexOf('/licenses/generate') > -1 && baseUrl.indexOf('/licenses/generate/') > -1) {
      //   return true;
      // }
      for (let action of actionPath) {
        const actionUrl =
          route + (route.charAt(route.length) === '/' ? '' : '/') + action;

        if (baseUrl.includes(actionUrl)) {
          result = true;
        }
      }
    } else {
      var splitted = baseUrl.split(route);

      if (splitted.length > 0 && splitted[1] === '') {
        return true;
      }

      if (splitted.length > 0 && baseUrl.includes(route)) {
        for (let action of actionPath) {
          action = '/' + action;

          if (baseUrl.includes(action)) {
            return true;
          }
        }

        // Correction sélection si sous menu avec route enfant
        if (baseUrl.startsWith(route)) {
          //queryParamsIndex === -1 &&
          return true;
        }
      }
    }

    return result;
  }

  public dispose() {}

  private getAllItems(
    menu: CoreNavMenuComponent,
  ): Array<CoreNavMenuItemComponent> {
    let items = [];

    for (let group of menu.groups.toArray()) {
      for (let item of group.items.toArray()) {
        if (item.visibled !== false) items.push(item);
      }
    }
    return items;
  }

  private internalNavigate(menu: CoreNavMenuComponent) {
    setTimeout(() => {
      let firstItem: CoreNavMenuItemComponent = undefined;
      let hasActive = false;
      if (menu == undefined) {
        return;
      }
      let allItems = this.getAllItems(menu);
      let groups = menu.groups;
      firstItem = allItems[0];
      console.log('set first element');
      if (firstItem != undefined) {
        //TODO CLICK OF NO ACTIVE

        for (let item of allItems) {
          if (
            item.active === true ||
            item.elementRef.nativeElement.classList.contains('cl-active')
          ) {
            hasActive = true;
          }
        }

        if (hasActive === false) {
          firstItem.click();
        }

        //item.elementRef.nativeElement.classList.remove("cl-active");
      } else {
        this.router.navigateByUrl('/unauthorized');
      }
    });
  }
}
