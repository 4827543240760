import { TicketEmailBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ModelFieldCompilerService } from '@clarilog/shared2';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['incident', 'request'],
  operator: 'or',
})
export class TicketEmailCoreService extends TicketEmailBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService(): TicketEmailCoreService {
    return this;
  }

  /** Validation  */
  public oneOrMore(
    state: ModelState,
    control: AbstractControl,
  ): Observable<boolean> {
    // si nouveau
    let id = state.sharedContext.params.get('id');
    if (id == undefined) {
      return of(false);
    } else {
      // count email
      let result = this.count(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        {
          ticketId: {
            eq: id,
          },
        },
      ).pipe(
        map((r) => {
          if (r?.data != undefined && r.data > 0) {
            return true;
          }

          return false;
        }),
      );

      return result;
    }
  }
}
