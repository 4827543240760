import { Injectable } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { AuthentificationService } from '@clarilog/core/services/authentification/authentification.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../graphql/generated-types/helpers';
import { OrganizationStorageService } from '../graphql/generated-types/services/local-storage-service/organization-storage-service';
import { MyOrganizationCoreService } from '../graphql/generated-types/services/my-organization.service';
import {
  AreaListCustomization,
  ContentCustomization,
  CustomFeature,
} from '../graphql/generated-types/types';
import { DynamicPropertyFieldCoreService } from '../graphql/generated-types/services/dynamic-property-field.service';

/** Représente l'initialisation des autorisations de l'utilisateur connecté en fonction de l'organisation. */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationCoreService {
  private _user: ClaimsPrincipal = undefined;
  constructor(
    private authentificationService: AuthentificationService,
    private myOrganisationService: MyOrganizationCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private organizationStorageService: OrganizationStorageService,
    private dynamicPropertyCoreService: DynamicPropertyFieldCoreService,
  ) {}

  get user(): ClaimsPrincipal {
    if (this._user == undefined) {
      return (this._user = new ClaimsPrincipal(
        this.authentificationService.userInfo,
      ));
    }
    return this._user;
  }
  /** Initialise les autorisation. À appeler une seule fois. */
  initialize(hostName: string): Observable<boolean> {
    let errorFields = GqlSubField.create('errors', [
      GqlField.create('messageError'),
      GqlField.create('property'),
      GqlField.create('validationCategory'),
    ]);
    sessionStorage.removeItem('prevState');

    return new Observable<boolean>((observer) => {
      let fields: Array<GqlSubField | GqlField> = [
        errorFields,
        GqlSubField.create('data', [
          GqlField.create('tenantId'),
          GqlField.create('roles'),
          GqlField.create('roleTypes'),
          GqlField.create('userId'),
          GqlField.create('permissions'),
          GqlSubField.create('organization', [
            GqlField.create('fileId'),
            GqlField.create('fileIncidentId'),
            GqlField.create('fileRequestId'),
            GqlField.create('urlImgIncident'),
            GqlField.create('urlImgRequest'),
            GqlSubField.create('contentCustomization', [
              GqlSubField.create('helpMe', [
                GqlSubField.create(
                  'littleText',
                  this.translatedFieldHelperService.translatedFields(),
                ),
                GqlSubField.create(
                  'boldTexte',
                  this.translatedFieldHelperService.translatedFields(),
                ),
                GqlSubField.create(
                  'accessTicketsObserverLabel',
                  this.translatedFieldHelperService.translatedFields(),
                ),
                GqlField.create('primaryColor'),
                GqlField.create('secondaryColor'),
                GqlField.create('headBandColor'),
                GqlField.create('name'),
                GqlField.create('timerRefresh'),
                GqlField.create('timerRefreshEnable'),
              ]),
              GqlSubField.create('helpMeAreaList', [
                GqlField.create('messageNumber'),
                GqlField.create('messageNumberEnable'),
                GqlField.create('articleKbNumber'),
                GqlField.create('articleKbNumberEnable'),
                GqlField.create('newsNumber'),
                GqlField.create('newsNumberEnable'),
              ]),
            ]),
            GqlField.create('maxFileSize'),
            GqlField.create('allowedFileExtension'),
            GqlField.create('forbiddenFileExtension'),
            GqlSubField.create('findDynamicProperties', [
              GqlSubField.create(
                'data',
                this.dynamicPropertyCoreService.getFields(),
              ),
            ]),
            GqlSubField.create('customFeatures', [
              GqlField.create('enable'),
              GqlField.create('name'),
            ]),
          ]),
          GqlField.create('userIsReponsible'),
        ]),
      ];

      this.myOrganisationService.organizationByUrl(fields, hostName).subscribe(
        (result) => {
          if (result?.data != undefined) {
            let organizationByUrl = result;

            this.organizationStorageService.setDynamicPropertyFields(
              result.data.organization?.findDynamicProperties?.data,
            );

            this.organizationStorageService.setMaxFileSize(
              result.data.organization.maxFileSize,
            );
            this.organizationStorageService.setAllowedFileExtension(
              result.data.organization.allowedFileExtension,
            );
            this.organizationStorageService.setForbiddenFileExtension(
              result.data.organization.forbiddenFileExtension,
            );
            if (organizationByUrl != undefined) {
              this._user = new ClaimsPrincipal({
                ...this.authentificationService.userInfo,
                ...organizationByUrl.data,
              });
              observer.next(true);
            } else {
              observer.next(false);
            }
          } else {
            if (result?.errors != undefined && result.errors.length > 0) {
              console.error(result.errors[0].messageError);
            }
            observer.next(false);
          }

          observer.complete();
        },
        (err) => {
          observer.error(err);
        },
      );
    });
  }
}
export class ClaimsPrincipal {
  constructor(private claims: { [key: string]: any }) {}
  @InjectArgs
  getClaim(@Args('type') type: string): any {
    if (this.claims && this.claims[type]) {
      return this.claims[type];
    } else {
      return undefined;
    }
  }

  @InjectArgs
  getPermission(@Args('type') type: string): any {
    if (this.claims && this.claims['permissions']) {
      let permission = this.claims['permissions'];
      let result = permission[type];
      return permission[type];
    } else {
      return undefined;
    }
  }

  @InjectArgs
  hasClaim(@Args('type') type: string) {
    return this.claims && this.claims[type] != undefined;
  }

  /** Obtient si le compte est de type 'Manager' */
  @InjectArgs
  private getRoleTypes(type: string): boolean {
    return this.getClaim('roleTypes').filter((f) => f == type).length > 0;
  }

  /** Obtient si le compte est de type 'Manager' */
  @InjectArgs
  hasManager(): boolean {
    return this.getRoleTypes('Manager');
  }

  /** Obtient si le compte est de type 'HelpDeskOperator' */
  @InjectArgs
  hasHelpDeskOperator(): boolean {
    return this.getRoleTypes('HelpDeskOperator');
  }

  /** Obtient si le compte est de type 'HelpDeskUser' */
  @InjectArgs
  hasHelpDeskUser(): boolean {
    return this.getRoleTypes('HelpDeskUser');
  }

  /** Obtient le tenantId */
  @InjectArgs
  getTenantId(): string {
    return this.claims.tenantId;
  }

  /** Obtient les options de customisation de l'organisation */
  @InjectArgs
  getCustomization(): ContentCustomization {
    return this.claims.organization?.contentCustomization;
  }
  /** Obtient si le timer help me est activé */
  @InjectArgs
  hasTimerHelpMe(): boolean {
    var enable =
      this.claims.organization?.contentCustomization?.helpMe
        ?.timerRefreshEnable;
    return enable === true;
  }
  /** Obtient si le delais en minute du timer */
  @InjectArgs
  getTimerHelpMe(): number {
    if (this.hasTimerHelpMe()) {
      let timer =
        this.claims.organization?.contentCustomization?.helpMe?.timerRefresh;
      if (timer == undefined || timer < 5 || timer > 60) {
        timer = 5;
      }
      return timer * 1000 * 60;
    }
    return undefined;
  }

  /** Obtient si les options de customisation des cadres de l'area list du dashboard Help Me */
  getHelpMeAreaListCustomization(): AreaListCustomization {
    return this.claims.organization?.contentCustomization?.helpMeAreaList;
  }

  /** Obtient si le compte est responsable */
  @InjectArgs
  hasHelpDeskResponsible(): boolean {
    return this.getClaim('userIsReponsible');
  }

  /** Obtient le nom affiché */
  @InjectArgs
  displayName(): string {
    return this.getClaim('firstName') + ' ' + this.getClaim('family_name');
  }

  // /** Obtient l'id du logo */
  @InjectArgs
  getLogo(): string {
    return this.claims.organization.fileId;
  }
  @InjectArgs
  getImgIncident(): string {
    return this.claims.organization.fileIncidentId;
  }
  @InjectArgs
  getImgRequest(): string {
    return this.claims.organization.fileRequestId;
  }
  @InjectArgs
  getUrlImgIncident(): string {
    return this.claims.organization.urlImgIncident;
  }
  @InjectArgs
  getUrlImgRequest(): string {
    return this.claims.organization.urlImgRequest;
  }
  @InjectArgs
  getCustomFeature(): Array<object> {
    return this.claims.organization.customFeatures;
  }
}
