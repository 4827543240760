import { InterventionBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { IncidentCoreService } from './incident.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs/internal/Observable';
import {
  FilterOfOperatingSystem,
  FilterOfOperatorTeam,
  FilterOfUser,
  QueryContextOfOperatorTeam,
  QueryContextOfUser,
  ServiceListResultOfOperatorTeam,
  ServiceListResultOfUser,
} from '../types';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { of } from 'rxjs';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage-service/local-storage-service';
import { ModelRuleApplicationService } from '@clarilog/core/services2/model-rule-application/model-rule-application.service';
import { AbstractControl } from '@angular/forms';
import { OperatorTeamCoreService } from './operator-team.service';

@Injectable({ providedIn: 'root' })
@Authorize('intervention')
export class InterventionCoreService extends InterventionBaseService {
  constructor(
    injector: Injector,
    private incidentService: IncidentCoreService,
    private operatorTeamService: OperatorTeamCoreService,
    private localStorageService: LocalStorageService,
    private modelRulesApplicationService: ModelRuleApplicationService,
  ) {
    super(injector);
  }
  public getService(): InterventionCoreService {
    return this;
  }

  public oneOrMore(
    state: ModelState,
    control: AbstractControl,
  ): Observable<boolean> {
    let id = state.sharedContext.params.get('id');
    if (id == undefined) {
      return of(true);
    }
    return this.find([GqlField.create('totalCount')], null, {
      or: [
        {
          ticketId: {
            eq: id,
          },
          taskId: {
            eq: id,
          },
        },
      ],
    }).pipe(
      map((res) => {
        if (res.totalCount === 0) {
          return false;
        }
        return true;
      }),
    );
  }

  @InjectArgs
  public findOperator(
    @Args('fields') fields: GqlFields,
    @Args('options?') options?: QueryContextOfUser,
    @Args('levelSupport?') levelSupport?: number,
    @Args('operatorTeamId?') operatorTeamId?: string,
    @Args('levelSupportId?') levelSupportId?: number,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    return this.incidentService.findFilteredOperatorAffected(
      fields,
      options,
      operatorTeamId,
      levelSupport,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findOperatorTeamByOperator(
    @Args('fields') fields: GqlFields,
    @Args('options?') options?: QueryContextOfOperatorTeam,
    @Args('levelSupport?') levelSupport?: number,
    @Args('operatorId?') operatorId?: string,
    @Args('levelSupportId?') levelSupportId?: number,
    @Args('filter?') filter?: FilterOfOperatorTeam,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOperatorTeam> {
    return this.operatorTeamService.findOperatorTeamAffectedTicketAffectation(
      fields,
      levelSupport,
      options,
      operatorId,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  /** Obtient les rule d'affichage */
  getIncidentRules(
    @Args('rawModel') rawModel: any,
    @Args('subFormId') subFormId: string,
    @Args('formGroup') formGroup: any,
  ) {
    return this.localStorageService.user
      .getIncidentModelRule()
      .subscribe((res) => {
        let rule = JSON.parse(JSON.stringify(res.data[0]));

        rule.rules = rule?.interventionRules;
        rule.editRules = rule?.interventionEditRules;

        this.modelRulesApplicationService.applyModelRule(
          rawModel as any,
          rule,
          undefined,
          formGroup != undefined ? formGroup : undefined,
          subFormId,
        );
      });
  }

  @InjectArgs
  /** Obtient les rule d'affichage */
  getRequestRules(
    @Args('rawModel') rawModel: any,
    @Args('subFormId') subFormId: string,
    @Args('formGroup') formGroup: any,
  ) {
    return this.localStorageService.user
      .getRequestModelRule()
      .subscribe((res) => {
        let rule = JSON.parse(JSON.stringify(res.data[0]));

        rule.rules = rule?.interventionRules;
        rule.editRules = rule?.interventionEditRules;

        this.modelRulesApplicationService.applyModelRule(
          rawModel as any,
          rule,
          undefined,
          formGroup != undefined ? formGroup : undefined,
          subFormId,
        );
      });
  }
}
