export * from './access-point.gqls'
export * from './account.gqls'
export * from './activity.gqls'
export * from './affected-rule.gqls'
export * from './agent-configuration.gqls'
export * from './agent-history.gqls'
export * from './agent-token.gqls'
export * from './alarm-mail-task.gqls'
export * from './alarm.gqls'
export * from './alert.gqls'
export * from './alert-task.gqls'
export * from './analyse-scan-by-azure-ad-task.gqls'
export * from './anti-spyware-product.gqls'
export * from './anti-virus-product.gqls'
export * from './application-azure-ad.gqls'
export * from './app-log.gqls'
export * from './appointment.gqls'
export * from './article-knowledge-category.gqls'
export * from './article-knowledge-note.gqls'
export * from './article-knowledge.gqls'
export * from './asset-account.gqls'
export * from './asset-acquisition-mode.gqls'
export * from './asset-acquisition-state.gqls'
export * from './asset-affected-rule.gqls'
export * from './asset-category.gqls'
export * from './asset-category-rule.gqls'
export * from './asset-contract.gqls'
export * from './asset-cost-center.gqls'
export * from './asset-loan.gqls'
export * from './asset-model.gqls'
export * from './asset-model-stock-entry.gqls'
export * from './asset-property.gqls'
export * from './asset-property-tracking.gqls'
export * from './asset-provisional-commissioning.gqls'
export * from './asset.gqls'
export * from './asset-status.gqls'
export * from './asset-type-accounting.gqls'
export * from './asset-type-staffing.gqls'
export * from './async-task.gqls'
export * from './auto-run.gqls'
export * from './axel-datum.gqls'
export * from './base-board.gqls'
export * from './battery.gqls'
export * from './bio.gqls'
export * from './boot-configuration.gqls'
export * from './budget-category.gqls'
export * from './budget.gqls'
export * from './business-time.gqls'
export * from './calendar-connector.gqls'
export * from './campaign-history.gqls'
export * from './campaign.gqls'
export * from './c-d-rom-drive.gqls'
export * from './channel.gqls'
export * from './chat-bot.gqls'
export * from './check-file-task.gqls'
export * from './check-size-file-task.gqls'
export * from './clarilog-server.gqls'
export * from './codec-file.gqls'
export * from './command.gqls'
export * from './commentary-model.gqls'
export * from './computer-system-product.gqls'
export * from './computer-system.gqls'
export * from './concerned-project.gqls'
export * from './consumable-movement.gqls'
export * from './contract-category.gqls'
export * from './contract-license-type.gqls'
export * from './contract.gqls'
export * from './copy-count.gqls'
export * from './correction-stock-movement.gqls'
export * from './cost-center.gqls'
export * from './cron-job-hourly-task.gqls'
export * from './custom-field.gqls'
export * from './dashboard.gqls'
export * from './data-file.gqls'
export * from './day-off.gqls'
export * from './delete-file-task.gqls'
export * from './desktop.gqls'
export * from './disk-drive.gqls'
export * from './disk-ram-information.gqls'
export * from './domain.gqls'
export * from './dynamic-property-field.gqls'
export * from './dynamic-property-group.gqls'
export * from './dynamic-property-list.gqls'
export * from './email-connector.gqls'
export * from './email-connector-system-log.gqls'
export * from './email-connector-system.gqls'
export * from './email-credential.gqls'
export * from './email-prototype-log.gqls'
export * from './email-prototype.gqls'
export * from './encryptable-volume.gqls'
export * from './entry-table.gqls'
export * from './environment-variable.gqls'
export * from './esx-credential.gqls'
export * from './exchange-connector.gqls'
export * from './fax-count.gqls'
export * from './feedback.gqls'
export * from './field-mapping.gqls'
export * from './firewall-product.gqls'
export * from './font-info-action.gqls'
export * from './form-designer.gqls'
export * from './group-azure-ad.gqls'
export * from './group.gqls'
export * from './iamt-credential.gqls'
export * from './ii.gqls'
export * from './impact.gqls'
export * from './import-audit-task.gqls'
export * from './incident-model.gqls'
export * from './incident.gqls'
export * from './infrared-device.gqls'
export * from './inherit-location-charter.gqls'
export * from './in-stock-movement.gqls'
export * from './internet-explorer.gqls'
export * from './intervention-model.gqls'
export * from './intervention.gqls'
export * from './inventory-history.gqls'
export * from './keyboard.gqls'
export * from './label-format.gqls'
export * from './label-template.gqls'
export * from './layout-model.gqls'
export * from './ldap-analysis.gqls'
export * from './ldap-history.gqls'
export * from './ldap-organizational-unit.gqls'
export * from './ldap-property.gqls'
export * from './license-key.gqls'
export * from './life-cycle-destination.gqls'
export * from './life-cycle-reason.gqls'
export * from './life-cycle-ticket.gqls'
export * from './link-asset.gqls'
export * from './list-designer.gqls'
export * from './loan-appointment.gqls'
export * from './loan.gqls'
export * from './loan-status.gqls'
export * from './location-category.gqls'
export * from './location-charter.gqls'
export * from './location.gqls'
export * from './logical-disk-history.gqls'
export * from './logical-disk.gqls'
export * from './logon-history.gqls'
export * from './logon-session.gqls'
export * from './mailbox-observer-log.gqls'
export * from './mailbox-observer-task.gqls'
export * from './mail-slurp-black-list.gqls'
export * from './mail-slurp-webhook-task.gqls'
export * from './matrix-priority.gqls'
export * from './message-model.gqls'
export * from './message.gqls'
export * from './model-manufacturer.gqls'
export * from './model-rules-designer.gqls'
export * from './monitor.gqls'
export * from './naming-convention-history.gqls'
export * from './naming-convention.gqls'
export * from './network-adapter.gqls'
export * from './network-credential.gqls'
export * from './network-drive.gqls'
export * from './news.gqls'
export * from './note.gqls'
export * from './notification.gqls'
export * from './nt-log-event.gqls'
export * from './object-class-mapping.gqls'
export * from './odbc-driver.gqls'
export * from './one-event-log.gqls'
export * from './one-setting.gqls'
export * from './operating-system.gqls'
export * from './operator-team.gqls'
export * from './optional-feature.gqls'
export * from './order-bill.gqls'
export * from './order.gqls'
export * from './organizational-unit-category.gqls'
export * from './organizational-unit.gqls'
export * from './organization.gqls'
export * from './origin.gqls'
export * from './os-recovery-configuration.gqls'
export * from './out-stock-movement.gqls'
export * from './package.gqls'
export * from './page-count.gqls'
export * from './page-file.gqls'
export * from './parallel-port.gqls'
export * from './pat.gqls'
export * from './pcmcia-controller.gqls'
export * from './physical-memory-array.gqls'
export * from './physical-memory.gqls'
export * from './pnp-entity-base.gqls'
export * from './pnp-entity.gqls'
export * from './pnp-signed-driver.gqls'
export * from './pnp-vendor.gqls'
export * from './pointing-device.gqls'
export * from './population.gqls'
export * from './portable-battery.gqls'
export * from './port-connector.gqls'
export * from './port-open.gqls'
export * from './pots-modem.gqls'
export * from './printer-consumable.gqls'
export * from './printer.gqls'
export * from './priority.gqls'
export * from './privilege.gqls'
export * from './problem.gqls'
export * from './processor.gqls'
export * from './process.gqls'
export * from './program.gqls'
export * from './query-builder-appointment.gqls'
export * from './query-builder-appointment-task.gqls'
export * from './query-builder.gqls'
export * from './quick-fix-engineering.gqls'
export * from './registry.gqls'
export * from './report-cach.gqls'
export * from './report.gqls'
export * from './request.gqls'
export * from './resolution-category.gqls'
export * from './resolution-model.gqls'
export * from './result-scan-azure-ad.gqls'
export * from './role-account.gqls'
export * from './role.gqls'
export * from './satisfaction-setting.gqls'
export * from './scan-by-address-ip-range.gqls'
export * from './scan-by-azure-ad-appointment.gqls'
export * from './scan-by-azure-ad-appointment-task.gqls'
export * from './scan-by-azure-ad.gqls'
export * from './scan-by-azure-ad-task.gqls'
export * from './scan-by-host.gqls'
export * from './scan-by-ldap.gqls'
export * from './scan-by-local-agent.gqls'
export * from './scan-by-workgroup.gqls'
export * from './scan-configuration-appointment.gqls'
export * from './scan-configuration.gqls'
export * from './scan-data-file.gqls'
export * from './scan-exclude.gqls'
export * from './scan-import-option.gqls'
export * from './scan-method-profil.gqls'
export * from './scan-method.gqls'
export * from './scanner-count.gqls'
export * from './scan-registry-key.gqls'
export * from './scan-registry.gqls'
export * from './schedule-task-error.gqls'
export * from './schedule-task.gqls'
export * from './scsi-controller.gqls'
export * from './security-group.gqls'
export * from './security-group-rule.gqls'
export * from './serial-number.gqls'
export * from './serial-port.gqls'
export * from './service-level-agreement.gqls'
export * from './service-offer.gqls'
export * from './sftp-connector-history.gqls'
export * from './sftp-connector.gqls'
export * from './share.gqls'
export * from './smtp-connector-system.gqls'
export * from './snmp-credential.gqls'
export * from './snmp-map-information-kb.gqls'
export * from './snmp-map-information.gqls'
export * from './snmp-summary.gqls'
export * from './snmp-vendor.gqls'
export * from './software-attribute-type.gqls'
export * from './software-connection-mode.gqls'
export * from './software-group.gqls'
export * from './software-hosted-component.gqls'
export * from './software-hosted-link.gqls'
export * from './software-package.gqls'
export * from './software-property.gqls'
export * from './software-referent-link.gqls'
export * from './software-referent-type.gqls'
export * from './software.gqls'
export * from './sound-device.gqls'
export * from './sql-server.gqls'
export * from './ssh-credential.gqls'
export * from './statistic-task.gqls'
export * from './status-workflow.gqls'
export * from './stock-in-reason.gqls'
export * from './stock-out-reason.gqls'
export * from './stock-transfer-reason.gqls'
export * from './stock-transfer.gqls'
export * from './substitute.gqls'
export * from './supplier.gqls'
export * from './system-account.gqls'
export * from './system-enclosure.gqls'
export * from './system-slot.gqls'
export * from './tape-drive.gqls'
export * from './task-sequence.gqls'
export * from './task-status.gqls'
export * from './task-status-workflow.gqls'
export * from './template.gqls'
export * from './terms-contract.gqls'
export * from './ticket-affected-rule.gqls'
export * from './ticket-appointment.gqls'
export * from './ticket-backlog.gqls'
export * from './ticket-category.gqls'
export * from './ticket-daily-backlog.gqls'
export * from './ticket-duration-task.gqls'
export * from './ticket-email.gqls'
export * from './ticket-email-task.gqls'
export * from './ticket-monthly-backlog.gqls'
export * from './ticket.gqls'
export * from './ticket-statistic.gqls'
export * from './ticket-status-reason.gqls'
export * from './ticket-status.gqls'
export * from './ticket-tag.gqls'
export * from './ticket-tag-task.gqls'
export * from './ticket-task-model.gqls'
export * from './ticket-task.gqls'
export * from './ticket-weekly-backlog.gqls'
export * from './ticket-yearly-backlog.gqls'
export * from './tracking-entity.gqls'
export * from './tracking.gqls'
export * from './uptime-calendar.gqls'
export * from './urgency.gqls'
export * from './user-account-control.gqls'
export * from './user-account.gqls'
export * from './user-affected-rule.gqls'
export * from './user-privilege.gqls'
export * from './user-profile.gqls'
export * from './user-role.gqls'
export * from './user.gqls'
export * from './verifalia-email-check-metric.gqls'
export * from './verifalia-email-check.gqls'
export * from './verifalia-task.gqls'
export * from './video-controller.gqls'
export * from './virtual-machine.gqls'
export * from './vlan.gqls'
export * from './wall-socket.gqls'
export * from './warning-customer.gqls'
export * from './warning-message.gqls'
export * from './warning-view-customer.gqls'
export * from './warranty-link-vendor.gqls'
export * from './windows-firewall.gqls'
export * from './windows-service.gqls'
export * from './windows-update.gqls'
export * from './wmi-credential.gqls'
export * from './work-application.gqls'
export * from './workflow-action.gqls'
export * from './workflow-delete.gqls'
export * from './workflow-http-request.gqls'
export * from './workflow-interval-task.gqls'
export * from './workflow-invitation.gqls'
export * from './workflow-invitation-task.gqls'
export * from './workflow-link-item.gqls'
export * from './workflow-log.gqls'
export * from './workflow-mail.gqls'
export * from './workflow-mail-task.gqls'
export * from './workflow-managed.gqls'
export * from './workflow-notification-team.gqls'
export * from './workflow-property.gqls'
export * from './workflow-revocation.gqls'
export * from './workflow-revocation-without-email.gqls'
export * from './workflow.gqls'
export * from './workflow-schedule-task.gqls'
export * from './work-time.gqls'
export * from './my-organization.gqls'
export * from './agent.gqls'
export * from './common.gqls'
export * from './license.gqls'
export * from './contact.gqls'
export * from './clarilog-local-agent.gqls'
export * from './file-manager.gqls'
