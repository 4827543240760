import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWarningMessagesBaseVariables, FirstWarningMessagesBaseVariables, CountWarningMessagesBaseVariables, FindWarningMessagesBaseVariables, SearchWarningMessagesBaseVariables, ExportSchemaWarningMessagesBaseVariables, ExportDataWarningMessagesBaseVariables, CustomQueryWarningMessagesBaseVariables, CustomQueryIdWarningMessagesBaseVariables, FindUnassociatedSecurityGroupsWarningMessagesBaseVariables, FindAssociatedOperatorTeamSecurityGroupsWarningMessagesBaseVariables, FindAssociatedPopulationSecurityGroupsWarningMessagesBaseVariables, FindUnassociatedPopulationsWarningMessagesBaseVariables, UsedWarningMessagesBaseVariables, FindEnableWarningMessagesBaseVariables, FindWarningMessageOperatorWarningMessagesBaseVariables, ExistWarningMessagesBaseVariables, InsertWarningMessagesBaseVariables, UpdateWarningMessagesBaseVariables, ImportDataWarningMessagesBaseVariables, UpdateManyWarningMessagesBaseVariables, DeleteWarningMessagesBaseVariables, AddSecurityGroupWarningMessagesBaseVariables, RemoveSecurityGroupWarningMessagesBaseVariables, AddOperatorTeamSecurityGroupWarningMessagesBaseVariables, RemoveOperatorTeamSecurityGroupWarningMessagesBaseVariables, AddPopulationsWarningMessagesBaseVariables, RemovePopulationsWarningMessagesBaseVariables, AddPopulationSecurityGroupWarningMessagesBaseVariables, RemovePopulationSecurityGroupWarningMessagesBaseVariables, WarningMessageReadingWarningMessagesBaseVariables, WarningMessageMultipleReadingWarningMessagesBaseVariables } from '../gqls'
import { GetWarningMessagesDocument, FirstWarningMessagesDocument, CountWarningMessagesDocument, FindWarningMessagesDocument, SearchWarningMessagesDocument, ExportSchemaWarningMessagesDocument, ExportDataWarningMessagesDocument, CustomQueryWarningMessagesDocument, CustomQueryIdWarningMessagesDocument, FindUnassociatedSecurityGroupsWarningMessagesDocument, FindAssociatedOperatorTeamSecurityGroupsWarningMessagesDocument, FindAssociatedPopulationSecurityGroupsWarningMessagesDocument, FindUnassociatedPopulationsWarningMessagesDocument, UsedWarningMessagesDocument, FindEnableWarningMessagesDocument, FindWarningMessageOperatorWarningMessagesDocument, ExistWarningMessagesDocument, InsertWarningMessagesDocument, UpdateWarningMessagesDocument, ImportDataWarningMessagesDocument, UpdateManyWarningMessagesDocument, DeleteWarningMessagesDocument, AddSecurityGroupWarningMessagesDocument, RemoveSecurityGroupWarningMessagesDocument, AddOperatorTeamSecurityGroupWarningMessagesDocument, RemoveOperatorTeamSecurityGroupWarningMessagesDocument, AddPopulationsWarningMessagesDocument, RemovePopulationsWarningMessagesDocument, AddPopulationSecurityGroupWarningMessagesDocument, RemovePopulationSecurityGroupWarningMessagesDocument, WarningMessageReadingWarningMessagesDocument, WarningMessageMultipleReadingWarningMessagesDocument } from '../gqls'
import { ServiceSingleResultOfWarningMessage, QueryContextOfWarningMessage, FilterOfWarningMessage, ServiceSingleResultOfInt64, ServiceListResultOfWarningMessage, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfPopulation, QueryContextOfPopulation, FilterOfPopulation, ServiceSingleResultOfBoolean, WarningMessageInput, FieldUpdateOperatorOfWarningMessage } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WarningMessageBaseService {
    
public modelName = 'warningMessage';
public modelPluralName = 'warningMessages';

	private getWarningMessagesQuery: GetWarningMessagesDocument;
	private firstWarningMessagesQuery: FirstWarningMessagesDocument;
	private countWarningMessagesQuery: CountWarningMessagesDocument;
	private findWarningMessagesQuery: FindWarningMessagesDocument;
	private searchWarningMessagesQuery: SearchWarningMessagesDocument;
	private exportSchemaWarningMessagesQuery: ExportSchemaWarningMessagesDocument;
	private exportDataWarningMessagesQuery: ExportDataWarningMessagesDocument;
	private customQueryWarningMessagesQuery: CustomQueryWarningMessagesDocument;
	private customQueryIdWarningMessagesQuery: CustomQueryIdWarningMessagesDocument;
	private findUnassociatedSecurityGroupsWarningMessagesQuery: FindUnassociatedSecurityGroupsWarningMessagesDocument;
	private findAssociatedOperatorTeamSecurityGroupsWarningMessagesQuery: FindAssociatedOperatorTeamSecurityGroupsWarningMessagesDocument;
	private findAssociatedPopulationSecurityGroupsWarningMessagesQuery: FindAssociatedPopulationSecurityGroupsWarningMessagesDocument;
	private findUnassociatedPopulationsWarningMessagesQuery: FindUnassociatedPopulationsWarningMessagesDocument;
	private usedWarningMessagesQuery: UsedWarningMessagesDocument;
	private findEnableWarningMessagesQuery: FindEnableWarningMessagesDocument;
	private findWarningMessageOperatorWarningMessagesQuery: FindWarningMessageOperatorWarningMessagesDocument;
	private existWarningMessagesQuery: ExistWarningMessagesDocument;
	private insertWarningMessagesMutation: InsertWarningMessagesDocument;
	private updateWarningMessagesMutation: UpdateWarningMessagesDocument;
	private importDataWarningMessagesMutation: ImportDataWarningMessagesDocument;
	private updateManyWarningMessagesMutation: UpdateManyWarningMessagesDocument;
	private deleteWarningMessagesMutation: DeleteWarningMessagesDocument;
	private addSecurityGroupWarningMessagesMutation: AddSecurityGroupWarningMessagesDocument;
	private removeSecurityGroupWarningMessagesMutation: RemoveSecurityGroupWarningMessagesDocument;
	private addOperatorTeamSecurityGroupWarningMessagesMutation: AddOperatorTeamSecurityGroupWarningMessagesDocument;
	private removeOperatorTeamSecurityGroupWarningMessagesMutation: RemoveOperatorTeamSecurityGroupWarningMessagesDocument;
	private addPopulationsWarningMessagesMutation: AddPopulationsWarningMessagesDocument;
	private removePopulationsWarningMessagesMutation: RemovePopulationsWarningMessagesDocument;
	private addPopulationSecurityGroupWarningMessagesMutation: AddPopulationSecurityGroupWarningMessagesDocument;
	private removePopulationSecurityGroupWarningMessagesMutation: RemovePopulationSecurityGroupWarningMessagesDocument;
	private warningMessageReadingWarningMessagesMutation: WarningMessageReadingWarningMessagesDocument;
	private warningMessageMultipleReadingWarningMessagesMutation: WarningMessageMultipleReadingWarningMessagesDocument;

	constructor(private injector: Injector) {
		this.getWarningMessagesQuery = this.injector.get(GetWarningMessagesDocument);
		this.firstWarningMessagesQuery = this.injector.get(FirstWarningMessagesDocument);
		this.countWarningMessagesQuery = this.injector.get(CountWarningMessagesDocument);
		this.findWarningMessagesQuery = this.injector.get(FindWarningMessagesDocument);
		this.searchWarningMessagesQuery = this.injector.get(SearchWarningMessagesDocument);
		this.exportSchemaWarningMessagesQuery = this.injector.get(ExportSchemaWarningMessagesDocument);
		this.exportDataWarningMessagesQuery = this.injector.get(ExportDataWarningMessagesDocument);
		this.customQueryWarningMessagesQuery = this.injector.get(CustomQueryWarningMessagesDocument);
		this.customQueryIdWarningMessagesQuery = this.injector.get(CustomQueryIdWarningMessagesDocument);
		this.findUnassociatedSecurityGroupsWarningMessagesQuery = this.injector.get(FindUnassociatedSecurityGroupsWarningMessagesDocument);
		this.findAssociatedOperatorTeamSecurityGroupsWarningMessagesQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsWarningMessagesDocument);
		this.findAssociatedPopulationSecurityGroupsWarningMessagesQuery = this.injector.get(FindAssociatedPopulationSecurityGroupsWarningMessagesDocument);
		this.findUnassociatedPopulationsWarningMessagesQuery = this.injector.get(FindUnassociatedPopulationsWarningMessagesDocument);
		this.usedWarningMessagesQuery = this.injector.get(UsedWarningMessagesDocument);
		this.findEnableWarningMessagesQuery = this.injector.get(FindEnableWarningMessagesDocument);
		this.findWarningMessageOperatorWarningMessagesQuery = this.injector.get(FindWarningMessageOperatorWarningMessagesDocument);
		this.existWarningMessagesQuery = this.injector.get(ExistWarningMessagesDocument);
		this.insertWarningMessagesMutation = this.injector.get(InsertWarningMessagesDocument);
		this.updateWarningMessagesMutation = this.injector.get(UpdateWarningMessagesDocument);
		this.importDataWarningMessagesMutation = this.injector.get(ImportDataWarningMessagesDocument);
		this.updateManyWarningMessagesMutation = this.injector.get(UpdateManyWarningMessagesDocument);
		this.deleteWarningMessagesMutation = this.injector.get(DeleteWarningMessagesDocument);
		this.addSecurityGroupWarningMessagesMutation = this.injector.get(AddSecurityGroupWarningMessagesDocument);
		this.removeSecurityGroupWarningMessagesMutation = this.injector.get(RemoveSecurityGroupWarningMessagesDocument);
		this.addOperatorTeamSecurityGroupWarningMessagesMutation = this.injector.get(AddOperatorTeamSecurityGroupWarningMessagesDocument);
		this.removeOperatorTeamSecurityGroupWarningMessagesMutation = this.injector.get(RemoveOperatorTeamSecurityGroupWarningMessagesDocument);
		this.addPopulationsWarningMessagesMutation = this.injector.get(AddPopulationsWarningMessagesDocument);
		this.removePopulationsWarningMessagesMutation = this.injector.get(RemovePopulationsWarningMessagesDocument);
		this.addPopulationSecurityGroupWarningMessagesMutation = this.injector.get(AddPopulationSecurityGroupWarningMessagesDocument);
		this.removePopulationSecurityGroupWarningMessagesMutation = this.injector.get(RemovePopulationSecurityGroupWarningMessagesDocument);
		this.warningMessageReadingWarningMessagesMutation = this.injector.get(WarningMessageReadingWarningMessagesDocument);
		this.warningMessageMultipleReadingWarningMessagesMutation = this.injector.get(WarningMessageMultipleReadingWarningMessagesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningMessage> {
    
    		let variables: GetWarningMessagesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.get));
            }
            else{
                let result:ServiceSingleResultOfWarningMessage={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWarningMessage,
		@Args('filter?') filter?: FilterOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningMessage> {
    
    		let variables: FirstWarningMessagesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWarningMessagesBaseVariables = {
    			filter: filter
    		}
    				return this.countWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWarningMessage,
		@Args('filter?') filter?: FilterOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningMessage> {
    
    		let variables: FindWarningMessagesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWarningMessage,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningMessage> {
    
    		let variables: SearchWarningMessagesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWarningMessagesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWarningMessagesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWarningMessage,
		@Args('filter?') filter?: FilterOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningMessage> {
    
    		let variables: CustomQueryWarningMessagesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWarningMessage,
		@Args('filter?') filter?: FilterOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningMessage> {
    
    		let variables: CustomQueryIdWarningMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsWarningMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsWarningMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les population via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedPopulationSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindAssociatedPopulationSecurityGroupsWarningMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedPopulationSecurityGroupsWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.findAssociatedPopulationSecurityGroups));
    	}

	@InjectArgs
	public findAssociatedPopulations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfPopulation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPopulation,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfPopulation> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('populations', fields, null, [
			GqlSubFieldArg.create('filterOfPopulations', 'filter'),
			GqlSubFieldArg.create('optionsOfPopulations', 'options'),
		]),
		])
		extendedVariables['filterOfPopulations'] = filter;
		extendedVariables['optionsOfPopulations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.populations));
            }
            else{
                let result: ServiceListResultOfPopulation = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les populations non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedPopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPopulation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPopulation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindUnassociatedPopulationsWarningMessagesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedPopulationsWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.findUnassociatedPopulations));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWarningMessagesBaseVariables = {
    			ids: ids
    		}
    				return this.usedWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.used));
    	}

    	/**  */
    	@InjectArgs
    	public findEnable(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningMessage> {
    
    		let variables: FindEnableWarningMessagesBaseVariables = {
    
    		}
    				return this.findEnableWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.findEnable));
    	}

    	/**  */
    	@InjectArgs
    	public findWarningMessageOperator(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningMessage> {
    
    		let variables: FindWarningMessageOperatorWarningMessagesBaseVariables = {
    
    		}
    				return this.findWarningMessageOperatorWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.findWarningMessageOperator));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWarningMessagesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWarningMessagesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WarningMessageInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningMessage> {
    
    		let variables: InsertWarningMessagesBaseVariables = {
    			entity: entity
    		}
    				return this.insertWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningMessage> {
    
    		let variables: UpdateWarningMessagesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWarningMessagesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWarningMessage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWarningMessagesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWarningMessagesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupWarningMessagesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupWarningMessagesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupWarningMessagesBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupWarningMessagesBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationsWarningMessagesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.addPopulationsWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.addPopulations));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationsWarningMessagesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationsWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.removePopulations));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationSecurityGroupWarningMessagesBaseVariables = {
    			id: id,
			populationIds: populationIds,
			isNew: isNew
    		}
    				return this.addPopulationSecurityGroupWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.addPopulationSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationSecurityGroupWarningMessagesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationSecurityGroupWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.removePopulationSecurityGroup));
    	}

    	/** Note pour l'utilisateur qui à ouvert le message d'avertissement. */
    	@InjectArgs
    	public warningMessageReading(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: WarningMessageReadingWarningMessagesBaseVariables = {
    			id: id
    		}
    				return this.warningMessageReadingWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.warningMessageReading));
    	}

    	/**  */
    	@InjectArgs
    	public warningMessageMultipleReading(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: WarningMessageMultipleReadingWarningMessagesBaseVariables = {
    			ids: ids
    		}
    				return this.warningMessageMultipleReadingWarningMessagesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningMessages.warningMessageMultipleReading));
    	}
    
}