import { Injectable, Injector } from '@angular/core';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { CustomFieldCoreService } from '../custom-field.service';
import { IncidentCoreService } from '../incident.service';
import { ModelRulesDesignerCoreService } from '../model-rules-designer.service';
import { RequestCoreService } from '../request.service';
import { UserCoreService } from '../user.service';
import { CustomFieldStorageService } from './custom-field-storage-service';
import { LayoutStorageService } from './layout-storage-service';
import { PaginateStorageService } from './paginate-storage-service';
import { TicketStorageService } from './ticket-storage-service';
import { UserStorageService } from './user-storage-service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { ListDesignerCoreService } from '../list-designer.service';
import { DeviceDetectorService } from 'ngx-device-detector';

/** Représente les constante de valeur d'événement */
@Injectable({ providedIn: 'root' })
export class LocalStorageEventConst {
  public ReloadNavMenuConst = 'reload-nav-menu';
  public ReloadSelectSimpleList = 'reload-select-simple-list';
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private _layoutStorage: LayoutStorageService = undefined;
  private _paginateStorage: PaginateStorageService = undefined;
  private _customFieldStorage: CustomFieldStorageService = undefined;
  private _userStorage: UserStorageService = undefined;
  private _ticketStorage: TicketStorageService = undefined;
  private _deviceService: boolean;

  /** Crée un model state global a toute l'application en utilisant le service localstorage */
  private _modelState: ModelState;

  public static EventConst: LocalStorageEventConst =
    new LocalStorageEventConst();

  constructor(
    private userService: UserCoreService,
    private authorizationService: AuthorizationCoreService,
    private customFieldCoreService: CustomFieldCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private modelRulesDesignerService: ModelRulesDesignerCoreService,
    private incidentService: IncidentCoreService,
    private requestService: RequestCoreService,
    private serviceInjector: Injector,
    private listDesignerCoreService: ListDesignerCoreService,
    private deviceService: DeviceDetectorService,
  ) {
    this._modelState = new ModelState(serviceInjector);

    this._deviceService = this.deviceService.isMobile();
  }
  /** Obtient les layouts */
  get layoutStorage(): LayoutStorageService {
    if (this._layoutStorage == undefined) {
      // NEW
      this._layoutStorage = new LayoutStorageService(
        this.userService,
        this.authorizationService,
      );
    }
    return this._layoutStorage;
  }

  /** Obtient le state globale */
  get ModelState(): ModelState {
    return this._modelState;
  }

  /** Obtient la pagination */
  get paginateStorage(): PaginateStorageService {
    if (this._paginateStorage == undefined) {
      this._paginateStorage = new PaginateStorageService(
        this.userService,
        this.authorizationService,
      );
    }

    return this._paginateStorage;
  }

  /** Obtient les champs personnalisé */
  get customFieldStorage(): CustomFieldStorageService {
    if (this._customFieldStorage == undefined) {
      this._customFieldStorage = new CustomFieldStorageService(
        this.customFieldCoreService,
        this.translatedFieldHelperService,
        this.userService,
        this.authorizationService,
      );
    }

    return this._customFieldStorage;
  }

  /** Information de l'utilisateur */
  get user(): UserStorageService {
    if (this._userStorage == undefined) {
      this._userStorage = new UserStorageService(
        this.userService,
        this.authorizationService,
        this.modelRulesDesignerService,
        this.listDesignerCoreService,
        this.serviceInjector,
      );
    }

    return this._userStorage;
  }

  /** Ticket */
  get ticket(): TicketStorageService {
    if (this._ticketStorage == undefined) {
      this._ticketStorage = new TicketStorageService(
        this.userService,
        this.authorizationService,
        this.incidentService,
        this.requestService,
      );
    }

    return this._ticketStorage;
  }

  /** Obtient le state globale */
  get isMobile(): Boolean {
    return this._deviceService;
  }
}
