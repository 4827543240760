import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { UserCoreService } from '../user.service';
import { BaseStorageService } from './base-storage-service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';

/** Classe de sauvegarde des données de pagination */
export class PaginateStorageService extends BaseStorageService {
  private _pageSize = undefined;
  constructor(
    userService: UserCoreService,
    authorizationService: AuthorizationCoreService,
  ) {
    super(userService, authorizationService);
  }

  /** Permet de récupéré la pagination */
  async get(): Promise<number> {
    if (this._pageSize == undefined) {
      this._pageSize = (
        await this._userService
          .paginate(ModelFieldCompilerService.createServiceSingleResultScalar())
          .toPromise()
      ).data;
    }

    if (this._pageSize == undefined) {
      this._pageSize = 50;
    }
    if (this._pageSize != 20 && this._pageSize != 50 && this._pageSize != 100) {
      this._pageSize = 50;
    }
    return this._pageSize;
  }

  /** Enregistre la pagination */
  set(value: number) {
    if (this._pageSize != value) {
      this._userService
        .setPaginate(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          value,
        )
        .toPromise();
    }
    this._pageSize = value;
  }
}
