import { Component } from '@angular/core';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  WarningCustomerCoreService,
  WarningViewCustomerCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { WarningCustomer } from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { TranslateService } from '@clarilog/shared/services';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';

/** Représente un composant d'affichage de message d'avertissement client*/
@Component({
  selector: 'cl-warning-customer-item',
  templateUrl: './warning-customer-item.component.html',
  styleUrls: ['./warning-customer-item.component.scss'],
})
export class WarningCustomerItemComponent {
  /** Définit si le message de warning doit etre visble */
  showWarning: boolean = false;

  /** Permet d'afficher ou masque le pop de notification */
  showPopup: boolean = false;

  /** Propriété du buton */
  okButton: any;

  /** Liste des avertissements */
  warnings: WarningCustomer[] = [];

  /** Définit la langue */
  lang = 'fr';

  /** Définit l'id de l'utilisateur en cours */
  userId: string;

  /** Interval automatique d'actualisation */
  intervalCheck: any;

  constructor(
    private warningCustomerService: WarningCustomerCoreService,
    private warningViewService: WarningViewCustomerCoreService,
    private translatedService: TranslatedFieldHelperService,
    private authorizationService: AuthorizationCoreService,
  ) {
    this.okButton = {
      width: 300,
      text: TranslateService.get('ok'),
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        this.showPopup = false;
        // Enregistre la validation de vue
        warningViewService
          .save(
            ModelFieldCompilerService.createServiceSingleResultScalar(),
            this.warnings.map((m) => m.id),
          )
          .subscribe((f) => {});
      },
    };
  }

  /** Initialisation du composant */
  ngOnInit() {
    this.refresh(true);

    // 1 heure
    let interval = 1000 * 60 * 60;

    this.intervalCheck = setInterval(() => {
      this.refresh(false);
    }, interval);
  }

  /** Suppression du composant */
  ngOnDestroy(): void {
    clearInterval(this.intervalCheck);
  }

  /** Actualisation des avertissement */
  refresh(displayMessage) {
    this.lang = this.translatedService.getTranslateKey();
    this.userId = this.authorizationService.user.getClaim('userId');

    if (this.lang != 'fr' && this.lang != 'en') {
      this.lang = 'en';
    }
    let helpMe =
      localStorage.getItem('hasHelpDeskUser') == 'true' ? true : false;

    // Recherche des messages
    this.warningCustomerService
      .findEnable([
        GqlSubField.create('data', [
          GqlSubField.create('title', [GqlField.create(this.lang)]),
          GqlSubField.create('content', [GqlField.create(this.lang)]),
          GqlField.create('id'),
          GqlField.create('start'),
          GqlField.create('end'),
          GqlField.create('enable'),
          GqlField.create('availableForHelpMe'),
        ]),
      ])
      .subscribe((result) => {
        this.warnings = [];
        if (result?.data != undefined) {
          result.data.forEach((f) => {
            let date = new Date();
            let dateStart = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              0,
              0,
              0,
            );
            let endStart = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              23,
              59,
              59,
            );
            let canAdd = true;
            if (f.start != undefined) {
              let convertLocalDate = new Date(f.start);
              convertLocalDate = new Date(
                convertLocalDate.getFullYear(),
                convertLocalDate.getMonth(),
                convertLocalDate.getDate(),
                0,
                0,
                0,
              );
              if (convertLocalDate > dateStart) {
                canAdd = false;
              }
            }

            if (f.end != undefined) {
              let convertLocalDate = new Date(f.end);
              convertLocalDate = new Date(
                convertLocalDate.getFullYear(),
                convertLocalDate.getMonth(),
                convertLocalDate.getDate(),
                23,
                59,
                59,
              );
              if (convertLocalDate < endStart) {
                canAdd = false;
              }
            }

            if (canAdd) {
              this.warnings.push(f);
            }
          });

          // Cas Help me
          if (helpMe) {
            this.warnings = this.warnings.filter((f) => f.availableForHelpMe);
          }

          // Vérification si toutes la alerte on déjà été lu par l'utilisateur
          if (
            this.warnings.length > 0 &&
            this.userId != undefined &&
            displayMessage
          ) {
            setTimeout(() => {
              this.warningViewService
                .find(
                  [
                    GqlSubField.create('data', [
                      GqlField.create('warningCustomerId'),
                      GqlField.create('lastDisplay'),
                    ]),
                  ],
                  undefined,
                  {
                    userId: {
                      eq: this.userId,
                    },
                  },
                )
                .subscribe((result) => {
                  let allView = false;
                  if (result?.data != undefined) {
                    allView =
                      this.warnings.filter(
                        (f) =>
                          result.data.find(
                            (r) => r.warningCustomerId == f.id,
                          ) == undefined,
                      ).length > 0;
                  }

                  this.showPopup = allView;
                });
            }, 2000);
          }
        }

        this.showWarning = this.warnings.length > 0;
      });
  }

  /** Evenement de click */
  onMessageClick() {
    this.showPopup = true;
  }
}
