import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

/**Définit des "helper method" */
export class WorkItemsHelper {
  /**Liste des id pour cocher les cases du treeView component*/
  public expandFilter(filterKey: string): Array<any> {
    if (filterKey != null && filterKey != undefined) {
      let filterObject = this.getFilterArray().find((x) => x.name == filterKey);
      if (filterObject?.value != null && filterObject?.value != undefined) {
        let arrayFilterObject = [filterObject].map((element) => {
          return element?.value;
        });
        return arrayFilterObject[0];
      }
    }
    return [];
  }

  /**Transforme notre "keepfilter" en tableau d'objet */
  public getFilterArray() {
    let hasStorageFilter = JSON.parse(sessionStorage.getItem('keepfilter'));
    let filterKeep: Array<{
      name: string;
      value: Array<any>;
    }> = [];

    if (hasStorageFilter != undefined && hasStorageFilter != null) {
      filterKeep = hasStorageFilter as Array<{
        name: string;
        value: Array<any>;
      }>;
    }
    return filterKeep;
  }

  /**Par rapport au filtre DevExtreme on coche quoi*/
  public getNode(array: Array<any>) {
    let getAllKey = [];
    if (array != null && array != undefined) {
      if (array.includes('or')) {
        array?.map((element) => {
          let isArrayType = Array.isArray(element);
          if (isArrayType) {
            let result = (element as Array<any>).join('');
            let filterValueId = result.split('=')[1];
            getAllKey.push(filterValueId);
          }
        });
      } else if (array.includes('contains')) {
        array?.map((element) => {
          let isArrayType = Array.isArray(element);
          if (isArrayType) {
            (element as Array<any>).map((filterValue) => {
              getAllKey.push(filterValue);
            });
          }
        });
      } else if (array.includes('=')) {
        let result = array.join('');
        let filterValueId = result.split('=')[1];
        getAllKey.push(filterValueId);
      } else {
        console.error('Something is missing ...');
      }
    }
    return getAllKey;
  }

  public getParameterTypeName(parametersType: string) {
    let pathname = window.location.pathname.replace(/\//g, '');
    if (parametersType != undefined && parametersType != null) {
      return `${pathname}${parametersType}`;
    }
    return pathname;
  }

  public canAccumulateFitler(canCumulateFilter: boolean) {
    let currentFilterPermission =
      canCumulateFilter == undefined || canCumulateFilter == null
        ? true
        : canCumulateFilter;

    return currentFilterPermission;
  }

  public deleteSession() {
    // let filterLocalStorageKey = this.getFilterArray().map((element) => {
    //   return element.name;
    // });

    sessionStorage.removeItem('keepfilter');
    sessionStorage.removeItem('cumulFilter');
    // sessionStorage.removeItem('customLayout');
    // filterLocalStorageKey.map((element) => {
    //   sessionStorage.removeItem(`${element}`);
    //   sessionStorage.getItem(element);
    // });
  }

  /**Permet de récuperer le meta-filtre */
  getKeepFilter(filterName: string = null) {
    let concatFilter = [];
    //if (filterName != undefined) {
    let filterKeep = this.getFilterArray();

    filterKeep.forEach((element, index) => {
      if (element?.value != undefined && element.value != null) {
        // Ajouter la condition valide
        if (element?.value[0] instanceof Array) {
          concatFilter.push([element.value]);
        } else {
          concatFilter.push(element.value);
        }

        // Ajouter 'and' uniquement si ce n'est pas le dernier élément valide
        if (index < filterKeep.length - 1) {
          concatFilter.push('and');
        }
      }
    });
    //  }
    return concatFilter;
  }
}
