import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@clarilog/shared2/services';
import {
  DxPopoverComponent,
  DxPopupComponent,
  DxTextBoxComponent,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ListType } from '../list/list/list.component-base';
import { CoreSelectListComponent } from '@clarilog/shared2/components/list';
import { CoreGraphQLDataSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { Column, Filter } from '@clarilog/shared2/models/schema';
import { TemplatesService } from '../templates/templates.service';
import CustomStore from 'devextreme/data/custom_store';
import { TranslatedFieldHelperService } from '../translate-field';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

/** Représente la valeur pour les champs dynamiq */
export class DynamicLinkValue {
  id: string;
  data: any;
}

/** Représente le composent Link. Permet de lier un élément à un élément. */
@Component({
  selector: 'clc-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreLinkComponent),
      multi: true,
    },
  ],
})
export class CoreLinkComponent
  implements ControlValueAccessor, OnDestroy, AfterViewInit
{
  @Input() params: string;
  @Input() debugName: string;
  /** Obtient ou définit si l s'agit d'un champs dynamique */
  @Input() isDynamicProperty: boolean = false;

  placeholder: string;
  /** Affiche ou cache le dropdown. */
  showPopup: boolean = false;
  /** Texte saisi. */
  text: string;
  /** La souscription du observable. */
  subscription: Subscription;
  /** Représente les champs de recherche. */
  searchExpr: string[];
  /** La valeur recherchée. */
  searchValue: string;
  /** représente la valeur de l'objet sélectionné */
  selectValue: string = '';
  /** représente l'affichage du popover */
  popOverVisibility: boolean = false;
  /** L'observable permettant de gérer le keyUp. */
  keyUp = new Subject<any>();
  /** Affiche ou cache le popup de la fenêtre d'association. */
  showAssociatePopup: boolean = false;
  /** Titre du popup de la fenêtre d'association. */
  titleAssociatePopup: string = '';
  /** Titre du popup de la fenêtre d'association. */
  @Input() titleAssociate: string = '';
  /** Définis si la propriété est en lecture seul */
  @Input() readOnly: boolean = false;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  /** Obtient ou définit la valeur. */
  @Input() value: string | DynamicLinkValue;

  /**Liste des proprétés à utiliser pour la création du filtre */
  @Input() filterList: string[] = [];
  @Input()
  public get _value(): string | DynamicLinkValue {
    return this.value;
  }

  public set _value(value: string | DynamicLinkValue) {
    this.value = value;

    setTimeout(() => this.loadValue());
  }
  @Input() showOnEnlargeClick: boolean = true;
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit l'état d'activation. */
  @Input() iRefDisplayExpr: string;
  /** Obtient ou définit l'état d'activation. */
  @Input() iRefValueExpr: string;

  /** Obtient le composant selectlist. */
  @ViewChild(CoreSelectListComponent) selectList: CoreSelectListComponent;
  /** Obtient le composant textbox. */
  @ViewChild(DxTextBoxComponent, { static: true }) textbox: DxTextBoxComponent;
  /** Obtient le composant popup. */
  @ViewChild('popup') popup: DxPopupComponent;
  /** Obtient le composant popover. */
  @ViewChild(DxPopoverComponent, { static: true }) popover: DxPopoverComponent;
  /** Obtient le composant liste. */
  list: any;
  treeList: any;
  @ViewChild('clPictogramme') clPictogramme;
  /** Represente le modelState du formulaire */
  @Input() modelState: ModelState;
  /** Obtient ou définit le champ représentant la clé. */
  @Input() valueExpr: string = 'id';
  @Input() translatable: boolean = false;
  /** Obtient ou définit si la croix de suppression est visible. */
  @Input() clearVisibility: boolean = true;
  _displayExpr: string;
  /** Obtient ou définit le champ à afficher. */
  @Input() get displayExpr(): string {
    return this._displayExpr;
  }

  @Input() showClearButton: boolean = true;

  set displayExpr(value: string) {
    if (this.translatable === true) {
      value = this.translatedFieldHelperService.setColumnTranslateField(value);
    }
    this._displayExpr = value;
  }

  /** Obtient ou définit le champ à afficher. */
  @Input() enabledExp: string;

  /** Obtient ou définit la valeur du template  */
  @Input() template: string = undefined;

  /** Obtient ou définit le champ représentant la clé parent. */
  @Input() parentIdExpr: string = 'parentId';

  /** Obtient ou définit le type de la liste. */
  @Input() type: ListType = 'Grid';

  /** Obtient ou définit la source de données. */
  @Input() source: DataSource;

  /** Obtient ou définit les colonnes. */
  @Input() columns: Column[];

  /** Obtient ou définit le champ pour la decription . */
  @Input() itemHint: string;

  /** Obtient ou définit la route de gestion (TODO : Voir pour trouver une meilleur solution). */
  @Input() route: string;

  @Input() filters: Filter[];

  originalValue: string | DynamicLinkValue;

  /** Filtre supplémentaire  */
  filterAdd: boolean = true;

  /** Indique si l'on provient du boutons clear */
  clearAction: boolean = false;

  /** Indique qu'il s'agit d'une valeur supprimée */
  valueInTrash: boolean = false;

  /** Obtient ou définit le content principal de scroll */
  contentScroll: any;

  /** Obtient ou définit l'id du timeOut pour le popover' */
  timeOutId: any;

  /** Obtient ou définit les donné de l'item sélectioné' */
  dataItem: any;

  /** Obtient ou définit l'état de chargement. */
  loading: boolean = false;

  @Input() clearFilterOnClick: boolean = false;

  /** Obtient ou définit l'activation du popup. */
  @Input() class_zindex_popup: string;

  /** Obtient ou définit si le component est initialiser. */
  componentIsInit: boolean = false;

  /** Permet d'afficher le hint  */
  showHint: boolean = false;
  itemElementHint: any;
  itemDescriptionHint: string;
  lastItemSelected: any;

  /** Permet de changer la couleur */
  @Input() useClass: ModelFnContext = null;

  /** Pour savoir si on doit forcer le filter dans certains cas ex: la catégorie d'un ticket lorsqu'on applique un modèle */
  @Input() forceFilter: boolean = false;

  @Output() onValueChanged = new EventEmitter();

  /** Permet de changer la valeur saisie pour le display expression */
  @Input() customDisplayExpr: ModelFnContext = null;
  public _localStorageService: LocalStorageService;
  @Input() isMobile: boolean = false;

  @Input() triggerPopupOnEnlargeClick: boolean = false;
  constructor(
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    public templateService: TemplatesService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private localStorageService: LocalStorageService,
  ) {
    this._localStorageService = localStorageService;
    this.isMobile = this._localStorageService.isMobile as boolean;
    // Ajouter l'ouverture du popup au clique dans le textbox lorsque le
    // textbox à déjà le focus et que l'on à fermer via Echap.
    this.onCloseOnOutsideClick = this.onCloseOnOutsideClick.bind(this);
    this.onEnlargeClick = this.onEnlargeClick.bind(this);

    this.onGoTo = this.onGoTo.bind(this);
    this.onDropDown = this.onDropDown.bind(this);
    this.subscription = this.keyUp
      .pipe(
        debounceTime(400),
        map((e) => ({ event: e.event, text: this.text })),
      )
      .subscribe(this.onTextBoxInput.bind(this));
  }

  eventScroll() {
    this.showPopup = false;
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
    if (this.contentScroll != undefined) {
      this.contentScroll.removeEventListener('scroll', this.eventScroll, true);
    }
  }

  async ngOnInit() {
    if (this.modelState != undefined) {
      this.modelState.on.subscribe((event) => {
        if (
          event.eventName === TranslatedFieldHelperService.ModelStateLanguageKey
        ) {
          let displayExpr = this._displayExpr.substring(
            0,
            this._displayExpr.indexOf('.') + 1,
          );
          this._displayExpr = displayExpr + event.eventData;
          this.loadValue();
        }
      });
    }
  }

  /** @inheritdoc */
  ngAfterViewInit(): void {
    if (this.list != undefined) {
      this.list.registerKeyHandler('escape', () => {
        this.textbox.instance.focus();
        this.showPopup = false;
      });
    }
    this.textbox.instance.registerKeyHandler('escape', () => {
      this.showPopup = false;
    });
    // this.textbox.instance
    //   .element()
    //   .querySelector('.dx-texteditor-input')
    //   .addEventListener('focusin', () => {
    //     if (!this.clearAction) {
    //       this.onDropDown();
    //     }
    //   });

    this.titleAssociatePopup =
      TranslateService.get('addNewElements') + ': ' + this.titleAssociate;

    this.componentIsInit = true;
  }

  onTextBoxFocusIn(e): void {
    if (!this.clearAction) {
      this.onDropDown();
    }
    this.popOverVisibility = false;
    this.clearAction = false;
    this.text = e.component.option('value');
  }

  selectListInit() {
    this.searchExpr = this.columns.map((c) => c.field);

    this.selectList.source = new ModelDataSourceContext({
      datasource: new CoreGraphQLDataSource(this.source.store() as CustomStore),
    });
    if (this.type == 'Tree') {
      this.selectList.source.datasource.pageSize(500000);
    }

    if (this.filterAdd) {
      this.selectList.source.datasource.filter([
        this.displayExpr,
        'contains',
        '',
      ]);
    }
    this.selectList.onSelect.subscribe((results: any[]) => {
      if (results.length > 0) {
        this.dataItem = results[0];
        this.selectValue = this.getExprValue(this.dataItem, this.displayExpr);
        this.text = this.selectValue;

        if (this.isDynamicProperty === true) {
          let data = {};

          this.translatedFieldHelperService
            .getLanguageDatasource()
            .forEach((f) => {
              data[f.key] = this.dataItem[f.key];
            });

          this.value = {
            id: this.dataItem[this.valueExpr],
            data: data,
          };
        } else {
          this.value = this.dataItem[this.valueExpr];
        }

        this.onChange(this.value);
        this.onValueChanged.emit(this.value);
        // this.onTouched();
        this.showAssociatePopup = false;
      }
    });
  }

  /** @inheritdoc */
  public writeValue(value: any) {
    // Si meme valeur on annuler
    this.lastValue = this.value;

    this.value = value;
    if (value != undefined) {
      setTimeout(() => this.loadValue());
    } else {
      this.text = '';
      this.source.filter(this.getParentFilterSource());
    }
  }

  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  //Changement suite au passage d'angular 15
  public setDisabledState?(isDisabled: boolean): void {
    // Si problème de champs grisé qui ne se dégrise pas revoir cette option
    this.disabled = isDisabled;
    if (this.disabled && this.value != undefined) {
      this.originalValue = this.value;
    } else if (
      !this.disabled &&
      this.originalValue != undefined &&
      this.value == undefined
    ) {
      this.value = this.originalValue;

      this.onChange(this.value);
      this.onValueChanged.emit(this.value);
    } else if (this.disabled && this.value == undefined) {
      this.value = undefined;
      if (this.componentIsInit) {
        this.onChange(this.value);
        this.onValueChanged.emit(this.value);
      }
    }
  }

  /** Se déclenche sur le click du boutton gérer. */
  public onManageClick(_) {
    let queryParams = {};

    if (this.params != undefined) {
      let split = this.params.split('=');

      let param1 = split[0];
      let param2 = split[1];

      let res = {
        [param1]: param2,
      };

      queryParams = res;
    }

    let navigate;
    if (typeof this.route == 'object') {
      let functionRoute: ModelFnContext;
      functionRoute = this.route;
      functionRoute.context.params.set('item', () => undefined);
      let datatype = this.modelState?.sharedContext?.params?.get('types');
      if (datatype != undefined) {
        functionRoute.context.params.set('types', () => datatype);
      }
      functionRoute.fnCall().subscribe((data) => {
        navigate = data;
      });
    } else {
      navigate = this.route;
    }

    let url = this._router.createUrlTree([navigate], {
      queryParams: queryParams,
    });
    let win = window.open(url.toString(), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  onShowPopup(e) {
    this._changeDetectorRef.detectChanges();
    this.selectListInit();

    let filter = this.source.filter();
    if (filter != undefined) {
      if (
        filter.length >= 3 &&
        typeof filter[0] == 'string' &&
        typeof filter[1] == 'string' &&
        typeof filter[2] == 'string'
      ) {
        let tempFilter = filter.slice(0, 3);

        this.selectList.source.datasource.filter(tempFilter);
      }
    }
    this.showPopup = false;
    setTimeout(() => {
      this.selectList.refresh(true);
    }, 200);
  }

  /** Se déclenche sur le click du boutton agrandir. */
  public onEnlargeClick(_) {
    if (this.triggerPopupOnEnlargeClick) {
      // Si triggerPopupOnEnlargeClick est vrai, émettez l'événement
      this.modelState.on.emit({
        eventName: 'enlarge_click',
        eventData: null,
      });
    } else {
      // Sinon, ouvrez directement le popup associé
      this.showAssociatePopup = true;
    }
  }

  onDropDown() {
    if (this.text != undefined && this.text.trim().length == 0) {
      this.source.filter(this.getParentFilterSource());
    }

    // N'ouvre que si un text est saisi eou si forcer
    this.loadList();
    this.showPopup = true;
    this._changeDetectorRef.detectChanges();
  }

  /** Se déclenche sur le click du boutton accéder. */
  public async onGoTo(_) {
    this.showPopup = false;
    let queryParams = {};
    let item: any;
    let navigate: string;

    let valueId = this.value;
    if (this.isDynamicProperty === true) {
      valueId = (this.value as DynamicLinkValue)?.id;
    } else if (this.valueExpr != 'id') {
      valueId = this.dataItem['id'];
    }

    if (this.parentIdExpr != undefined) {
      let tempSource = new ModelDataSourceContext({
        datasource: new CoreGraphQLDataSource(
          this.source.store() as CustomStore,
        ),
      });

      tempSource.datasource.filter([this.valueExpr, '=', valueId]);
      let results = await tempSource.datasource.load();
      if (results.length > 0) {
        item = results[0];
        queryParams[this.parentIdExpr] = item[this.parentIdExpr];
      }
    }

    if (typeof this.route == 'object') {
      let functionRoute: ModelFnContext;
      functionRoute = this.route;
      functionRoute.context.params.set('item', () => item);
      let datatype = this.modelState?.sharedContext?.params?.get('types');
      if (datatype != undefined) {
        functionRoute.context.params.set('types', () => datatype);
      }
      functionRoute.fnCall().subscribe((data) => {
        navigate = data;
      });
    } else {
      navigate = this.route;
    }

    let url = this._router.createUrlTree([navigate, 'edit', valueId], {
      queryParams: queryParams,
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  createFilter(text: string) {
    let filter = this.source.filter();
    if (text.trim().length == 0) {
      filter = null;
    } else {
      if (filter != null) {
        if (this.filterList?.length > 0) {
          filter = [this.createListFilter(text)];
        } else {
          let searchIndex = filter.findIndex(
            (x) => x[0] == this.displayExpr && x[1] == 'contains',
          );
          if (searchIndex != -1) filter[searchIndex][2] = text;
          else {
            filter.push('and');
            filter.push([this.displayExpr, 'contains', text]);
          }
        }

        this.source.filter(filter);
      } else {
        if (this.filterList?.length > 0) {
          filter = [this.createListFilter(text)];
        } else {
          filter = [[this.displayExpr, 'contains', text]];
        }
      }
    }
    this.source.filter(filter);
    this.searchValue = text;
    this.refresh();
  }

  /**Création d'un filtre sur le nonm, le prénonm, le nom complet et le email */
  private createListFilter(text: string): any[] {
    let filter = [];
    this.filterList?.map((value, index) => {
      filter.push([value, 'contains', text]);
      if (index != this.filterList?.length) {
        filter.push('or');
      }
    });
    return filter;
  }

  /** Se déclenche lors du relachement du clavier dans le textbox. */
  protected onTextBoxInput(e) {
    if (
      !this.showPopup &&
      e?.event?.charCode != undefined &&
      e?.event?.charCode > 0
    ) {
      this.showPopup = true;
    }
    let isWordCharacter = e.event.key.length === 1;
    let isBackspaceOrDelete = e.event.keyCode === 8 || e.event.keyCode === 46;
    if (isWordCharacter || isBackspaceOrDelete) {
      this.createFilter(e.text);
    }
  }

  onTextBoxInitialized(e) {
    // Recherche du className : work-item-container
    let element = e?.component?.element();
    let maxRetry = 50;

    if (element != undefined) {
      while (element != undefined) {
        if (element.className == 'work-item-container') {
          this.contentScroll = element;
          this.eventScroll = this.eventScroll.bind(this);
          this.contentScroll.addEventListener('scroll', this.eventScroll, true);
        }
        element = element.parentElement;
        maxRetry--;
        if (maxRetry <= 0) {
          break;
        }
      }
    }
  }

  getParentFilterSource() {
    if (this.source['parentFilterSource'] != undefined) {
      return this.source['parentFilterSource'];
    }
    return null;
  }

  /** Se déclenche au chargement du textbox. */
  onTextBoxContentReady(e) {
    let input = e.element.querySelector('input');
    input.setAttribute('autocomplete', 'new-password');
  }

  /** Se déclenche lorsque le textbox perd le focus. */
  public async onTextBoxFocusOut(e) {
    let target = e.event.relatedTarget;
    if (target != null) {
      if (
        this.popup == undefined ||
        !(
          this.popup.instance.content() === target ||
          this.popup.instance.content().contains(target)
        )
      ) {
        this.showPopup = false;

        if (this.searchValue != undefined) {
          let defaultFilter = this.source.filter();
          if (defaultFilter != null) {
            let searchIndex = defaultFilter.findIndex(
              (x) => x[0] == this.displayExpr && x[1] == 'contains',
            );
            if (searchIndex > -1) {
              defaultFilter.splice(searchIndex, 1);
              let searchIndexAnd = defaultFilter.findIndex((x) => x == 'AND');
              defaultFilter.splice(searchIndexAnd, 1);

              if (defaultFilter.length == 0) {
                defaultFilter = null;
                this.source.filter(this.getParentFilterSource());
              }
            }
          }
        }
        this.text = this.selectValue;
        if (this.selectValue != null && this.forceFilter === true) {
          this.createFilter(this.selectValue);
        }
      }
    }
  }

  /** Se déclenche lors du changement de valeur. */
  public onTextBoxValueChanged(e) {
    if (e?.event?.type != 'keyup') {
      if (
        e.value != undefined &&
        e.value.trim() == '' &&
        this.selectValue != e.value.trim()
      ) {
        this.value = null;
        this.text = '';
        this.selectValue = '';

        this.onChange(this.value);
        this.onValueChanged.emit(this.value);
        this.clearAction = true;
        this.showPopup = false;
        this.valueInTrash = false;
        this.dataItem = undefined;
      }
      if (
        this.loading === false &&
        e.value != undefined &&
        this.selectValue != e.value.trim()
      ) {
        this.createFilter(e.value);
      }

      if (
        this.loading === false &&
        e.value != undefined &&
        this.forceFilter === true
      ) {
        this.text = e.value;
        this.selectValue = e.value;
        this.createFilter(e.value);
      }
    }
  }

  /** Se déclenche lors du changement de valeur d'une liste. */
  public onListSelectionChanged(e) {
    if (e.addedItems.length > 0) {
      this.lastValue = this.value;
      this.showPopup = false;

      //On vérifie qu'une fonction pour la classe est passé
      if (this.useClass) {
        this.useClass.context.params.set('iRefValueExpr', () => e);
        this.useClass.context.params.set('textbox', () => this.textbox);
        this.useClass.context.params.set('modelState', () => this.modelState);
        this.useClass.fnCall().subscribe((data) => {
          return data;
        });
      }

      this.dataItem = e.addedItems[0];
      let item = e.addedItems[0];
      this.selectValue = this.getExprValue(item, this.displayExpr);

      if (this.customDisplayExpr) {
        this.customDisplayExpr.context.params.set('item', () => item);
        this.customDisplayExpr.fnCall().subscribe((data) => {
          this.selectValue = data;
        });
      }

      this.text = this.selectValue;
      if (this.isDynamicProperty === true) {
        let data = {};

        this.translatedFieldHelperService
          .getLanguageDatasource()
          .forEach((f) => {
            data[f.key] = item[f.key];
          });

        this.value = {
          id: item[this.valueExpr],
          data: data,
        };
      } else {
        this.value = item[this.valueExpr];
      }
      this.valueInTrash = false;

      this.onChange(this.value);
      this.onValueChanged.emit(this.value);
      if (this.list != undefined) {
        this.list.unselectAll();
      }
    }
  }

  public onTreeListSelectionChanged(e) {
    this.lastItemSelected = undefined;
    if (e.selectedRowsData.length > 0) {
      if (e.selectedRowsData[0].allowSelection != false) {
        this.showPopup = false;
        this.dataItem = e.selectedRowsData[0];
        let item = e.selectedRowsData[0];
        this.lastItemSelected = item;
        this.selectValue = this.getExprValue(item, this.displayExpr);
        this.text = this.selectValue;
        this.value = item[this.valueExpr];
        this.valueInTrash = false;
        this.onChange(this.value);
        this.onValueChanged.emit(this.value);
      }
    }
  }

  /** Initialisation du composant list */
  onListInitialized(e) {
    this.list = e.component;
  }

  /** Initialisation du composant list */
  onTreeListInitialized(e) {
    this.treeList = e.component;
  }

  /** Se déclenche sur l'appui d'une touche du clavier sur le textbox. */
  public onTextBoxKeyDown(e) {
    if (this.showPopup === false) {
      this.onDropDown();
    }

    if (
      e.event.keyCode === 38 ||
      e.event.keyCode === 40 ||
      e.event.keyCode === 13 ||
      e.event.keyCode === 39 || //right
      e.event.keyCode === 37 // Left
    ) {
      e.event.preventDefault();

      let step = 1;
      if (e.event.keyCode === 38) {
        step = -1;
      }

      this.keyDownGrid(e, step);
    }
  }

  keyDownGrid(e, step) {
    let myclass = '.dx-data-row';
    if (this.type == 'Grid') {
      myclass = '.dx-list-item';
    }
    let index = -1;
    if (this.list != undefined) {
      if (
        e.event.keyCode === 13 ||
        e.event.keyCode === 39 ||
        e.event.keyCode === 37
      ) {
        let element = this.list.element();
        let elements = element.querySelectorAll(myclass);

        elements.forEach((value, key) => {
          if (value.classList.contains('cl-state-focused')) {
            index = key;
          }
        });
        if (e.event.keyCode === 13) {
          if (this.list.selectItem != undefined) {
            this.list.selectItem(index);
          } else if (this.list.selectRowsByIndexes != undefined) {
            this.list.selectRowsByIndexes(index);
          }
        } else {
          if (this.list.getKeyByRowIndex != undefined) {
            let row = this.list.getKeyByRowIndex(index);

            let rowKey = this.list.getNodeByKey(row);

            let reselect = (rowkey) => {
              element = this.list.element();
              elements = element.querySelectorAll(myclass);
              elements.forEach((value, key) => {
                if (value.classList.contains('cl-state-focused')) {
                  value.classList.remove('cl-state-focused');
                }
              });

              let rowTr = this.list.getRowIndexByKey(rowkey);
              let r = this.list.getRowElement(rowTr);
              if (r != undefined && r.length > 0) {
                r[0].classList.add('cl-state-focused');
              }
            };

            if (
              this.list.expandRow != undefined &&
              e.event.keyCode === 39 &&
              rowKey != undefined &&
              rowKey.hasChildren
            ) {
              this.list.expandRow(row).then((f) => {
                reselect(rowKey.key);
              });
            } else if (
              this.list.collapseRow != undefined &&
              e.event.keyCode === 37
            ) {
              if (this.list.isRowExpanded(row)) {
                this.list.collapseRow(row).then((f) => {
                  reselect(rowKey.key);
                });
              } else if (rowKey != undefined) {
                if (
                  rowKey.level > 0 &&
                  rowKey.parent != undefined &&
                  rowKey.parent.key != undefined
                ) {
                  this.list.collapseRow(rowKey.parent.key).then((f) => {
                    reselect(rowKey.parent.key);
                  });
                }
              }
            }
          }
        }
      } else {
        this.moveKey(myclass, step);
      }
    }
  }

  moveKey(myclass, step) {
    let index = -1;
    let element = this.list.element();
    let elements = element.querySelectorAll(myclass);
    if (elements.length === 0) {
      this.textbox.instance.focus();
      return;
    }

    elements.forEach((value, key) => {
      if (value.classList.contains('cl-state-focused')) {
        index = key;
        value.classList.remove('cl-state-focused');
      }
    });

    index = index + step;

    if (index < 0) {
      index = elements.length - 1;
    } else if (index > elements.length - 1) {
      index = 0;
    }
    elements[index].classList.add('cl-state-focused');
    if (this.list.scrollToItem != undefined) {
      this.list.scrollToItem(index);
    } else if (this.list.navigateToRow != undefined) {
      let row = this.list.getKeyByRowIndex(index);
      this.list.navigateToRow(row);
    }
  }

  /** Se déclenche à l'ouverture du popup. */
  public onPopupShowing(e) {
    let content = e.component.content();
    // Supprime l'ombre.
    if (
      !content.parentElement.parentElement.classList.contains(
        'dx-dropdowneditor-overlay',
      )
    ) {
      content.parentElement.parentElement.classList.add(
        'dx-dropdowneditor-overlay',
      );
    }
    if (!content.classList.contains('cl-dropdown-popup')) {
      content.classList.add('cl-dropdown-popup');
    }
    //this.popup.width = this.textbox.instance.element().clientWidth;
    e.component.option('width', this.textbox.instance.element().clientWidth);

    if (
      this.list != undefined &&
      this.list.navigateToRow != undefined &&
      this.lastItemSelected != undefined
    ) {
      this.list.navigateToRow(this.lastItemSelected.id);
    }
  }

  /** Se déclenche lors du clique en dehors du popup. */
  public onCloseOnOutsideClick(e) {
    return !(
      this.textbox.instance.element() === e.target ||
      this.textbox.instance.element().contains(e.target)
    );
  }

  lastValue = undefined;

  /**
   * Permet de récupèrer un timezone en particulier
   * @param results Array of time zones
   * @param timeZoneId time zone id
   * @returns time zone
   */
  getTimezone(results: any[], timeZoneId: string): string {
    return results.find((result) => result.id === timeZoneId);
  }

  private async loadValue() {
    if (
      this.iRefDisplayExpr != undefined &&
      this.iRefValueExpr != undefined &&
      this.value != undefined
    ) {
      let refValueId = this.getExprValue(
        this.modelState.sharedContext.entity,
        this.iRefValueExpr,
      );

      if (this.useClass) {
        this.useClass.context.params.set(
          'iRefValueExpr',
          () => this.iRefValueExpr,
        );
        this.useClass.context.params.set('textbox', () => this.textbox);
        this.useClass.context.params.set('modelState', () => this.modelState);
        this.useClass.fnCall().subscribe((data) => {
          return data;
        });
      }

      if (refValueId == this.value) {
        // Uniquement dans le cas du 1er set de value, on vérifie si le IRef est chargé pour éviter un load
        if (this.modelState?.sharedContext?.entity != undefined) {
          let getValue = this.getExprValue(
            this.modelState.sharedContext.entity,
            this.iRefDisplayExpr,
          );
          if (getValue != undefined) {
            let textValue = this.getExprValue(
              getValue,
              'data.' + this.displayExpr,
            );
            if (textValue != undefined) {
              this.dataItem = getValue.data;
              this.lastValue = this.value;
              this.selectValue = textValue;
              this.text = this.selectValue;
              return;
            }
          }
        }
      }
    }

    let valueId = undefined;
    if (this.isDynamicProperty === true) {
      valueId = (this.value as DynamicLinkValue)?.id;
      if (valueId == (this.lastValue as DynamicLinkValue)?.id) {
        // Ne recherche pas
        return;
      }
    } else {
      valueId = this.value;
      if (this.value == this.lastValue) {
        // Ne recherche pas
        return;
      }
    }

    if (
      !this.loading &&
      valueId != undefined &&
      valueId.trim != undefined &&
      valueId.trim().length > 0
    ) {
      this.loading = true;
      this.text = TranslateService.get('globals/loadingProgress');
      let defaultFilter = this.source.filter();
      if (this.clearFilterOnClick !== true) {
        this.source.filter([this.valueExpr, '=', valueId]);
      }

      if (this.type == 'Tree') {
        this.source.pageSize(500000);
      }

      this.source.load().then((results) => {
        let notFound = true;
        if (results.length > 0) {
          let item = {};

          this.dataItem = results.find((x) => x[this.valueExpr] === valueId);
          item = results.find((x) => x[this.valueExpr] === valueId);

          if (!this.dataItem && !item) {
            let defaultValue: string;
            if (results[0].id === 'Dateline Standard Time') {
              defaultValue = this.getTimezone(results, 'Romance Standard Time');
            } else {
              defaultValue = this.getTimezone(results, 'Europe/Paris');
            }
            this.dataItem = defaultValue;
            item = defaultValue;
          }

          if (item != undefined) {
            notFound = false;
            this.selectValue = this.getExprValue(item, this.displayExpr);

            if (this.customDisplayExpr) {
              this.customDisplayExpr.context.params.set('item', () => item);
              this.customDisplayExpr.fnCall().subscribe((data) => {
                this.selectValue = data;
              });
            }

            this.text = this.selectValue;
          } else {
            this.text = '';
          }
        }

        if (notFound) {
          this.valueInTrash = true;
          this.text = TranslateService.get('globals/valueInTrash');
          this.selectValue = this.text;
        }

        this.reset(defaultFilter);
        this.loading = false;
      });
    } else if (typeof valueId == 'number' && valueId != undefined) {
      this.loading = true;
      this.text = TranslateService.get('globals/loadingProgress');
      let defaultFilter = this.source.filter();

      this.source.load().then((results) => {
        if (results.length > 0) {
          let item = {};

          this.dataItem = results.find((x) => x[this.valueExpr] === valueId);
          item = results.find((x) => x[this.valueExpr] === valueId);
          this.selectValue = this.getExprValue(item, this.displayExpr);
          this.text = this.selectValue?.toString();
        } else {
          this.valueInTrash = true;
          this.text = TranslateService.get('globals/valueInTrash');
          this.selectValue = this.text;
        }
        this.reset(defaultFilter);
        this.loading = false;
      });
    }
  }

  private operationId;

  private loadList() {
    // Vérification du trie
    let sortColumns = this.columns.filter(
      (f) =>
        (f.sortIndex != undefined && f.sortIndex == 0) ||
        f.sortOrder != undefined,
    );
    if (this.source.sort() == undefined) {
      if (sortColumns.length > 0 && this.source.sort() == undefined) {
        this.source.sort([
          {
            selector: sortColumns[0].field,
            desc: sortColumns[0].sortOrder == 'asc' ? false : true,
          },
        ]);
      } else if (this.columns.length == 1) {
        this.source.sort([{ selector: this.columns[0].field, desc: false }]);
      }
    }

    if (this.operationId != undefined) {
      (this.source as any).cancel(this.operationId);
      this.operationId = null;
    }

    let promise = this.source.reload();
    this.operationId = (promise as any).operationId;
    promise.then(() => {
      this.operationId = null;
      if (this.popup != undefined) {
        this.popup.instance.repaint();
      }
    });
  }

  /** Permet faire un reset de la recherche. */
  private reset(defaultFilter: any) {
    this.searchValue = undefined;
    if (defaultFilter != undefined) {
      this.source.filter(defaultFilter);
    } else {
      this.source.filter(this.getParentFilterSource());
    }
    // this.source.reload();
  }

  /** Permet de rafraichir. */
  async refresh() {
    await this.loadList();
  }

  /** Permet si il y a un template d'affichage définit, de retourner une chaîne formatter */
  // private getDisplayValue(data: any): string {
  //   switch (this.template) {
  //     case 'userWithMailTemplate':
  //       return data['email'] !== null ? '(' + data['email'] + ')' : '';
  //     default:
  //       return '';
  //   }
  // }
  /** Événement lorsque la souris passe sur le composant */
  public mouseEnter(e) {
    this.timeOutId = setTimeout(() => {
      if (
        this.text != undefined &&
        this.text.length > 0 &&
        !this.valueInTrash
      ) {
        this.popOverVisibility = true;
      }
    }, 500);
  }

  /** Événement lorsque la souris sort sur le composant */
  public mouseLeave(e) {
    clearTimeout(this.timeOutId);
    this.popOverVisibility = false;
  }

  public getExprValue(item, displayExpr) {
    const fields = displayExpr.split('.');
    let value = item;
    if (value != undefined) {
      for (let el of fields) {
        if (value != undefined) {
          value = value[el];
        }
      }
    }
    return value;
  }

  public enterKey(e) {
    this.refresh();
  }

  onTreeListCellPrepared(e) {
    if (this.enabledExp != undefined) {
      if (e.data != undefined && e.data[this.enabledExp] === false) {
        e.cellElement.classList.add('cl-state-node-disabled');
      }
    }
  }

  onTreeListCellClick(e) {
    if (this.enabledExp != undefined) {
      if (e.row?.data != undefined && e.row.data[this.enabledExp] === false) {
        if (e.row?.node?.hasChildren) {
          if (e.row?.isExpanded) {
            e.component?.collapseRow(e.row?.key);
          } else {
            e.component?.expandRow(e.row?.key);
          }
        }
      }
    }
  }

  /** Gestion de l'affichage du popover de decruption des grille */
  onItemRendered(e) {
    this.hintPopover(e.itemElement, e.itemData);
  }

  /** Gestion de l'affichage du popover de decruption des treelist */
  onCellHoverChanged(e) {
    this.hintPopover(e.cellElement, e.data);
  }

  hintPopover(cellElement, data) {
    if (this.itemHint != undefined && data != undefined) {
      let obj = data;
      let split = this.itemHint.split('.');
      split.forEach((f) => {
        if (obj != undefined && obj[f] != undefined) obj = obj[f];
      });
      if (obj != undefined && obj != '') {
        cellElement.onmouseover = (args) => {
          this.showHint = true;
          this.itemElementHint = cellElement;
          this.itemDescriptionHint = obj;
        };
        cellElement.onmouseleave = (args) => {
          this.showHint = false;
        };
      }
    }
  }

  exitTimerPopup: any;
  popupComponent;

  onPopupShown(e) {
    this.popupComponent = e.component;
    // Posibilité de gérer par la perte de focus
    // if (e.component.content() != undefined) {
    //   e.component.content().onmouseleave = () => {
    //     this.exitTimerPopup = setTimeout(() => {
    //       e.component.hide();
    //     }, 5000);
    //   };
    //   e.component.content().onmouseenter = () => {
    //     clearTimeout(this.exitTimerPopup);
    //   };
    // }
  }
  dynamicPopUpFormLoaded($event: any) {
    throw new Error('Method not implemented.');
  }
  dynamicPopUpSaved($event: any) {
    throw new Error('Method not implemented.');
  }
}
