import { Component } from '@angular/core';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { WarningMessageCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { WarningMessage } from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslateService } from '@clarilog/shared/services';
import { ModelFieldCompilerService } from '@clarilog/shared2';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';

/** Représente un composant d'affichage de message d'avertissement client*/
@Component({
  selector: 'cl-warning-message-item',
  templateUrl: './warning-message-item.component.html',
  styleUrls: ['./warning-message-item.component.scss'],
})
export class WarningMessageItemComponent {
  /** Définit si le message de warning doit etre visble */
  showWarning: boolean = false;

  /** Permet d'afficher ou masque le pop de notification */
  showPopup: boolean = false;

  /** Propriété du buton */
  okButton: any;

  /** Liste des avertissements */
  warnings: WarningMessage[] = [];

  /** Définit la langue */
  lang = 'fr';

  /** Définit l'id de l'utilisateur en cours */
  userId: string;

  /** Interval automatique d'actualisation */
  intervalCheck: any;

  constructor(
    private warningMessageService: WarningMessageCoreService,
    private translatedService: TranslatedFieldHelperService,
    private authorizationService: AuthorizationCoreService,
  ) {
    this.okButton = {
      width: 300,
      text: TranslateService.get('ok'),
      type: 'default',
      stylingMode: 'contained',
      onClick: () => {
        this.showPopup = false;
        // Enregistre la validation de vue
        this.warningMessageService
          .warningMessageMultipleReading(
            ModelFieldCompilerService.createServiceSingleResultScalar(),
            this.warnings.map((m) => m.id),
          )
          .subscribe((f) => {});
      },
    };
  }

  /** Initialisation du composant */
  ngOnInit() {
    setTimeout((_) => this.refresh(true), 1000);

    // 1 heure
    let interval = 1000 * 60 * 60;

    this.intervalCheck = setInterval(() => {
      this.refresh(false);
    }, interval);
  }

  /** Suppression du composant */
  ngOnDestroy(): void {
    clearInterval(this.intervalCheck);
  }

  /** Actualisation des avertissement */
  refresh(displayMessage) {
    this.lang = this.translatedService.getTranslateKey();
    this.userId = this.authorizationService.user.getClaim('userId');

    if (this.lang != 'fr' && this.lang != 'en') {
      this.lang = 'en';
    }

    let helpMe =
      localStorage.getItem('hasHelpDeskUser') == 'true' ? true : false;

    let serViceFunc =
      helpMe === true ? 'findEnable' : 'findWarningMessageOperator';

    // Recherche des messages
    this.warningMessageService[serViceFunc](
      [
        GqlSubField.create('data', [
          GqlSubField.create('title', [GqlField.create(this.lang)]),
          GqlSubField.create('content', [GqlField.create(this.lang)]),
          GqlField.create('id'),
          GqlField.create('start'),
          GqlField.create('end'),
          GqlField.create('enable'),
          GqlField.create('type'),
          GqlField.create('warningMessageOperatorUsersIds'),
        ]),
      ],
      undefined,
    ).subscribe((result) => {
      this.warnings = [];

      if (result?.data != undefined) {
        result.data.forEach((f) => {
          let date = new Date();
          let dateStart = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0,
          );
          let endStart = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            23,
            59,
            59,
          );
          let canAdd = true;
          if (f.start != undefined) {
            let convertLocalDate = new Date(f.start);
            convertLocalDate = new Date(
              convertLocalDate.getFullYear(),
              convertLocalDate.getMonth(),
              convertLocalDate.getDate(),
              0,
              0,
              0,
            );
            if (convertLocalDate > dateStart) {
              canAdd = false;
            }
          }

          if (f.end != undefined) {
            let convertLocalDate = new Date(f.end);
            convertLocalDate = new Date(
              convertLocalDate.getFullYear(),
              convertLocalDate.getMonth(),
              convertLocalDate.getDate(),
              23,
              59,
              59,
            );
            if (convertLocalDate < endStart) {
              canAdd = false;
            }
          }

          if (canAdd) {
            this.warnings.push(f);
          }
        });

        // Vérification si toutes la alerte on déjà été lu par l'utilisateur
        if (
          this.warnings.length > 0 &&
          this.userId != undefined &&
          displayMessage
        ) {
          let allView = false;
          allView =
            this.warnings.filter(
              (el) =>
                el.warningMessageOperatorUsersIds?.includes(this.userId) ==
                false,
            ).length > 0;
          if (helpMe === true) {
            this.showPopup = true;
          } else {
            this.showPopup = allView;
          }
        }
      }

      this.showWarning = this.warnings.length > 0;
    });
  }

  /** Evenement de click */
  onMessageClick() {
    this.showPopup = true;
  }
}
