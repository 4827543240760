import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMyOrganizationsBaseVariables, FirstMyOrganizationsBaseVariables, CountMyOrganizationsBaseVariables, FindMyOrganizationsBaseVariables, SearchMyOrganizationsBaseVariables, ExportSchemaMyOrganizationsBaseVariables, ExportDataMyOrganizationsBaseVariables, CustomQueryMyOrganizationsBaseVariables, CustomQueryIdMyOrganizationsBaseVariables, FindFilesMyOrganizationsBaseVariables, UsedMyOrganizationsBaseVariables, ExistMyOrganizationsBaseVariables, FindRecyclesMyOrganizationsBaseVariables, CountRecyclesMyOrganizationsBaseVariables, ReadOnlyMyOrganizationsBaseVariables, FindArchivedMyOrganizationsBaseVariables, CountAllMyOrganizationsBaseVariables, FindDynamicPropertyFieldsMyOrganizationsBaseVariables, OrganizationByUrlMyOrganizationsBaseVariables, IamAdministratorMyOrganizationsBaseVariables, CheckUrlInvitationMyOrganizationsBaseVariables, ModelLanguageMyOrganizationsBaseVariables, IsMcpAlreadyActivatedMyOrganizationsBaseVariables, HasAccessToOrganizationLicenseMyOrganizationsBaseVariables, InsertMyOrganizationsBaseVariables, UpdateMyOrganizationsBaseVariables, ImportDataMyOrganizationsBaseVariables, UpdateManyMyOrganizationsBaseVariables, DeleteMyOrganizationsBaseVariables, UploadChunkFileMyOrganizationsBaseVariables, RestoreMyOrganizationsBaseVariables, RecycleMyOrganizationsBaseVariables, RestoreArchivedMyOrganizationsBaseVariables, ArchivedMyOrganizationsBaseVariables, AddFileDynamicFieldMyOrganizationsBaseVariables, RemoveFileDynamicFieldMyOrganizationsBaseVariables } from '../gqls'
import { GetMyOrganizationsDocument, FirstMyOrganizationsDocument, CountMyOrganizationsDocument, FindMyOrganizationsDocument, SearchMyOrganizationsDocument, ExportSchemaMyOrganizationsDocument, ExportDataMyOrganizationsDocument, CustomQueryMyOrganizationsDocument, CustomQueryIdMyOrganizationsDocument, FindFilesMyOrganizationsDocument, UsedMyOrganizationsDocument, ExistMyOrganizationsDocument, FindRecyclesMyOrganizationsDocument, CountRecyclesMyOrganizationsDocument, ReadOnlyMyOrganizationsDocument, FindArchivedMyOrganizationsDocument, CountAllMyOrganizationsDocument, FindDynamicPropertyFieldsMyOrganizationsDocument, OrganizationByUrlMyOrganizationsDocument, IamAdministratorMyOrganizationsDocument, CheckUrlInvitationMyOrganizationsDocument, ModelLanguageMyOrganizationsDocument, IsMcpAlreadyActivatedMyOrganizationsDocument, HasAccessToOrganizationLicenseMyOrganizationsDocument, InsertMyOrganizationsDocument, UpdateMyOrganizationsDocument, ImportDataMyOrganizationsDocument, UpdateManyMyOrganizationsDocument, DeleteMyOrganizationsDocument, UploadChunkFileMyOrganizationsDocument, RestoreMyOrganizationsDocument, RecycleMyOrganizationsDocument, RestoreArchivedMyOrganizationsDocument, ArchivedMyOrganizationsDocument, AddFileDynamicFieldMyOrganizationsDocument, RemoveFileDynamicFieldMyOrganizationsDocument } from '../gqls'
import { ServiceSingleResultOfOrganization, QueryContextOfOrganization, FilterOfOrganization, ServiceSingleResultOfInt64, ServiceListResultOfOrganization, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOrganization, ServiceSingleResultOfOrganizationResult, QueryContextOfOrganizationResult, QueryContextOfMyOrganizationQueriesResolver, OrganizationInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MyOrganizationBaseService {
    
public modelName = 'myOrganization';
public modelPluralName = 'myOrganizations';

	private getMyOrganizationsQuery: GetMyOrganizationsDocument;
	private firstMyOrganizationsQuery: FirstMyOrganizationsDocument;
	private countMyOrganizationsQuery: CountMyOrganizationsDocument;
	private findMyOrganizationsQuery: FindMyOrganizationsDocument;
	private searchMyOrganizationsQuery: SearchMyOrganizationsDocument;
	private exportSchemaMyOrganizationsQuery: ExportSchemaMyOrganizationsDocument;
	private exportDataMyOrganizationsQuery: ExportDataMyOrganizationsDocument;
	private customQueryMyOrganizationsQuery: CustomQueryMyOrganizationsDocument;
	private customQueryIdMyOrganizationsQuery: CustomQueryIdMyOrganizationsDocument;
	private findFilesMyOrganizationsQuery: FindFilesMyOrganizationsDocument;
	private usedMyOrganizationsQuery: UsedMyOrganizationsDocument;
	private existMyOrganizationsQuery: ExistMyOrganizationsDocument;
	private findRecyclesMyOrganizationsQuery: FindRecyclesMyOrganizationsDocument;
	private countRecyclesMyOrganizationsQuery: CountRecyclesMyOrganizationsDocument;
	private readOnlyMyOrganizationsQuery: ReadOnlyMyOrganizationsDocument;
	private findArchivedMyOrganizationsQuery: FindArchivedMyOrganizationsDocument;
	private countAllMyOrganizationsQuery: CountAllMyOrganizationsDocument;
	private findDynamicPropertyFieldsMyOrganizationsQuery: FindDynamicPropertyFieldsMyOrganizationsDocument;
	private organizationByUrlMyOrganizationsQuery: OrganizationByUrlMyOrganizationsDocument;
	private iamAdministratorMyOrganizationsQuery: IamAdministratorMyOrganizationsDocument;
	private checkUrlInvitationMyOrganizationsQuery: CheckUrlInvitationMyOrganizationsDocument;
	private modelLanguageMyOrganizationsQuery: ModelLanguageMyOrganizationsDocument;
	private isMcpAlreadyActivatedMyOrganizationsQuery: IsMcpAlreadyActivatedMyOrganizationsDocument;
	private hasAccessToOrganizationLicenseMyOrganizationsQuery: HasAccessToOrganizationLicenseMyOrganizationsDocument;
	private insertMyOrganizationsMutation: InsertMyOrganizationsDocument;
	private updateMyOrganizationsMutation: UpdateMyOrganizationsDocument;
	private importDataMyOrganizationsMutation: ImportDataMyOrganizationsDocument;
	private updateManyMyOrganizationsMutation: UpdateManyMyOrganizationsDocument;
	private deleteMyOrganizationsMutation: DeleteMyOrganizationsDocument;
	private uploadChunkFileMyOrganizationsMutation: UploadChunkFileMyOrganizationsDocument;
	private restoreMyOrganizationsMutation: RestoreMyOrganizationsDocument;
	private recycleMyOrganizationsMutation: RecycleMyOrganizationsDocument;
	private restoreArchivedMyOrganizationsMutation: RestoreArchivedMyOrganizationsDocument;
	private archivedMyOrganizationsMutation: ArchivedMyOrganizationsDocument;
	private addFileDynamicFieldMyOrganizationsMutation: AddFileDynamicFieldMyOrganizationsDocument;
	private removeFileDynamicFieldMyOrganizationsMutation: RemoveFileDynamicFieldMyOrganizationsDocument;

	constructor(private injector: Injector) {
		this.getMyOrganizationsQuery = this.injector.get(GetMyOrganizationsDocument);
		this.firstMyOrganizationsQuery = this.injector.get(FirstMyOrganizationsDocument);
		this.countMyOrganizationsQuery = this.injector.get(CountMyOrganizationsDocument);
		this.findMyOrganizationsQuery = this.injector.get(FindMyOrganizationsDocument);
		this.searchMyOrganizationsQuery = this.injector.get(SearchMyOrganizationsDocument);
		this.exportSchemaMyOrganizationsQuery = this.injector.get(ExportSchemaMyOrganizationsDocument);
		this.exportDataMyOrganizationsQuery = this.injector.get(ExportDataMyOrganizationsDocument);
		this.customQueryMyOrganizationsQuery = this.injector.get(CustomQueryMyOrganizationsDocument);
		this.customQueryIdMyOrganizationsQuery = this.injector.get(CustomQueryIdMyOrganizationsDocument);
		this.findFilesMyOrganizationsQuery = this.injector.get(FindFilesMyOrganizationsDocument);
		this.usedMyOrganizationsQuery = this.injector.get(UsedMyOrganizationsDocument);
		this.existMyOrganizationsQuery = this.injector.get(ExistMyOrganizationsDocument);
		this.findRecyclesMyOrganizationsQuery = this.injector.get(FindRecyclesMyOrganizationsDocument);
		this.countRecyclesMyOrganizationsQuery = this.injector.get(CountRecyclesMyOrganizationsDocument);
		this.readOnlyMyOrganizationsQuery = this.injector.get(ReadOnlyMyOrganizationsDocument);
		this.findArchivedMyOrganizationsQuery = this.injector.get(FindArchivedMyOrganizationsDocument);
		this.countAllMyOrganizationsQuery = this.injector.get(CountAllMyOrganizationsDocument);
		this.findDynamicPropertyFieldsMyOrganizationsQuery = this.injector.get(FindDynamicPropertyFieldsMyOrganizationsDocument);
		this.organizationByUrlMyOrganizationsQuery = this.injector.get(OrganizationByUrlMyOrganizationsDocument);
		this.iamAdministratorMyOrganizationsQuery = this.injector.get(IamAdministratorMyOrganizationsDocument);
		this.checkUrlInvitationMyOrganizationsQuery = this.injector.get(CheckUrlInvitationMyOrganizationsDocument);
		this.modelLanguageMyOrganizationsQuery = this.injector.get(ModelLanguageMyOrganizationsDocument);
		this.isMcpAlreadyActivatedMyOrganizationsQuery = this.injector.get(IsMcpAlreadyActivatedMyOrganizationsDocument);
		this.hasAccessToOrganizationLicenseMyOrganizationsQuery = this.injector.get(HasAccessToOrganizationLicenseMyOrganizationsDocument);
		this.insertMyOrganizationsMutation = this.injector.get(InsertMyOrganizationsDocument);
		this.updateMyOrganizationsMutation = this.injector.get(UpdateMyOrganizationsDocument);
		this.importDataMyOrganizationsMutation = this.injector.get(ImportDataMyOrganizationsDocument);
		this.updateManyMyOrganizationsMutation = this.injector.get(UpdateManyMyOrganizationsDocument);
		this.deleteMyOrganizationsMutation = this.injector.get(DeleteMyOrganizationsDocument);
		this.uploadChunkFileMyOrganizationsMutation = this.injector.get(UploadChunkFileMyOrganizationsDocument);
		this.restoreMyOrganizationsMutation = this.injector.get(RestoreMyOrganizationsDocument);
		this.recycleMyOrganizationsMutation = this.injector.get(RecycleMyOrganizationsDocument);
		this.restoreArchivedMyOrganizationsMutation = this.injector.get(RestoreArchivedMyOrganizationsDocument);
		this.archivedMyOrganizationsMutation = this.injector.get(ArchivedMyOrganizationsDocument);
		this.addFileDynamicFieldMyOrganizationsMutation = this.injector.get(AddFileDynamicFieldMyOrganizationsDocument);
		this.removeFileDynamicFieldMyOrganizationsMutation = this.injector.get(RemoveFileDynamicFieldMyOrganizationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganization> {
    
    		let variables: GetMyOrganizationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.get));
            }
            else{
                let result:ServiceSingleResultOfOrganization={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganization,
		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganization> {
    
    		let variables: FirstMyOrganizationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMyOrganizationsBaseVariables = {
    			filter: filter
    		}
    				return this.countMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganization,
		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganization> {
    
    		let variables: FindMyOrganizationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOrganization,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganization> {
    
    		let variables: SearchMyOrganizationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMyOrganizationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMyOrganizationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOrganization,
		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganization> {
    
    		let variables: CustomQueryMyOrganizationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOrganization,
		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganization> {
    
    		let variables: CustomQueryIdMyOrganizationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesMyOrganizationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMyOrganizationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMyOrganizationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganization,
		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganization> {
    
    		let variables: FindRecyclesMyOrganizationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesMyOrganizationsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyMyOrganizationsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganization,
		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganization> {
    
    		let variables: FindArchivedMyOrganizationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllMyOrganizationsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsMyOrganizationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.findDynamicPropertyFields));
    	}

    	/**  */
    	@InjectArgs
    	public organizationByUrl(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('url?') url?: string,
		@Args('options?') options?: QueryContextOfOrganizationResult,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganizationResult> {
    
    		let variables: OrganizationByUrlMyOrganizationsBaseVariables = {
    			url: url,
			options: options
    		}
    				return this.organizationByUrlMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.organizationByUrl));
    	}

    	/**  */
    	@InjectArgs
    	public iamAdministrator(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('organizationId') organizationId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: IamAdministratorMyOrganizationsBaseVariables = {
    			organizationId: organizationId
    		}
    				return this.iamAdministratorMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.iamAdministrator));
    	}

    	/** Permet de savoir si l'utilisateur peut utiliser l'url d'invitation */
    	@InjectArgs
    	public checkUrlInvitation(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('tokenInvite') tokenInvite: string,
		@Args('options?') options?: QueryContextOfMyOrganizationQueriesResolver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CheckUrlInvitationMyOrganizationsBaseVariables = {
    			tokenInvite: tokenInvite,
			options: options
    		}
    				return this.checkUrlInvitationMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.checkUrlInvitation));
    	}

    	/**  */
    	@InjectArgs
    	public modelLanguage(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('lang?') lang?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ModelLanguageMyOrganizationsBaseVariables = {
    			lang: lang
    		}
    				return this.modelLanguageMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.modelLanguage));
    	}

    	/**  */
    	@InjectArgs
    	public isMcpAlreadyActivated(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('token?') token?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: IsMcpAlreadyActivatedMyOrganizationsBaseVariables = {
    			token: token
    		}
    				return this.isMcpAlreadyActivatedMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.isMcpAlreadyActivated));
    	}

    	/**  */
    	@InjectArgs
    	public hasAccessToOrganizationLicense(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('organizationId') organizationId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: HasAccessToOrganizationLicenseMyOrganizationsBaseVariables = {
    			organizationId: organizationId
    		}
    				return this.hasAccessToOrganizationLicenseMyOrganizationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.hasAccessToOrganizationLicense));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OrganizationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganization> {
    
    		let variables: InsertMyOrganizationsBaseVariables = {
    			entity: entity
    		}
    				return this.insertMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganization> {
    
    		let variables: UpdateMyOrganizationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMyOrganizationsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrganization,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMyOrganizationsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMyOrganizationsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.delete));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('visibleByHelpDeskUser') visibleByHelpDeskUser: boolean,
		@Args('size') size: number,
		@Args('outgoingEmails') outgoingEmails: boolean,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileMyOrganizationsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId,
			visibleByHelpDeskUser: visibleByHelpDeskUser,
			outgoingEmails: outgoingEmails
    		}
    				return this.uploadChunkFileMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.uploadChunkFile));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreMyOrganizationsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleMyOrganizationsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedMyOrganizationsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedMyOrganizationsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldMyOrganizationsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldMyOrganizationsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldMyOrganizationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.myOrganizations.removeFileDynamicField));
    	}
    
}