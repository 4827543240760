<div *ngIf="canInit">
  <clc-work-item-field
    *ngFor="let control of controls"
    [mode]="control.mode || mode"
    [label]="control.label"
    [hint]="control.hint"
    [hintVisibility]="control.hintVisibility"
    [loaded]="parent.form.root.loaded"
    [errors]="getFormControl(control.name).errors"
    [required]="getFormControl(control.name).required"
    [visibled]="getVisible(control)"
    [clcSwitchOnlyOne]="control.type"
    [formGroup]="parent.form"
    [(valueChange)]="valueChange"
  >
    <clc-filter-builder
      *clcSwitchOnlyOneCase="'FilterBuilderComponent'"
      [source]="control.options.source"
      [type]="control.options.type"
      [formControlName]="control.name"
      [fieldName]="control.name"
      [state]="state"
      [useChangeValue]="
        control?.options?.useChangeValue != undefined
          ? control.options.useChangeValue
          : false
      "
      [useMethod]="control?.options?.useMethod"
      [customFilterOperations]="control?.options?.customFilterOperations"
    >
    </clc-filter-builder>
    <clc-chrono
      *clcSwitchOnlyOneCase="'ChronoComponent'"
      [state]="state"
      [formControlName]="control.name"
      [readOnly]="control.readOnly"
    >
    </clc-chrono>
    <clc-timespan
      *clcSwitchOnlyOneCase="'TimeSpanComponent'"
      [state]="state"
      [formControlName]="control.name"
      [readOnly]="control.readOnly"
      [enableMinus]="control?.options?.enableMinus"
      [modeChrono]="control.options.enableChrono"
    >
    </clc-timespan>
    <clc-property-change
      *clcSwitchOnlyOneCase="'PropertyChangeComponent'"
      [state]="state"
      [formControlName]="control.name"
      [fieldName]="control.name"
      [type]="control.options.type"
      [fieldTypeToAdd]="control.options.fieldTypeToAdd"
      [clearFieldToExclude]="control.options.clearFieldToExclude"
      [showFilterContidion]="control.options.showFilterContidion"
    ></clc-property-change>
    <cl-star-rating
      *clcSwitchOnlyOneCase="'StarRatingComponent'"
      [state]="state"
      [optionMode]="control?.options?.optionMode ?? false"
      [readonly]="control.readOnly"
      [formControlName]="control.name"
      [fieldName]="control.name"
      [type]="control.options.type"
    ></cl-star-rating>
    <dx-text-box
      #textbox
      *clcSwitchOnlyOneCase="'TextBoxComponent'"
      (mouseenter)="onMouseTextboxEnter($event, textbox, control)"
      (mouseleave)="onMouseTextboxLeave($event, textbox, control)"
      (onInitialized)="onComponentInitialized($event, control)"
      (onPaste)="onPasted($event)"
      (onValueChanged)="onValueChanged($event, control)"
      [inputAttr]="{ autocomplete: 'new-password' }"
      [placeholder]="control.emptyText"
      [value]="control.defaultValue"
      [mask]="control.options.mask"
      [readOnly]="control.readOnly"
      [maskChar]="control.options.maskChar"
      [maskInvalidMessage]="control.options.maskInvalidMessage"
      [maskRules]="control.options.maskRules"
      [maxLength]="control.options.maxLength"
      [mode]="
        control.options.mode !== undefined ? control.options.mode : 'text'
      "
      [showClearButton]="control.options.showClearButton"
      [formControlName]="control.name"
      [ngClass]="
        control.options.showActionToButton === true ? 'cl-action-button' : ''
      "
      valueChangeEvent="keyup"
      validationMessageMode="always"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    >
      <dxi-button
        name="copy"
        [options]="{
          icon: 'fal fa-copy',
          disabled: false,
          onClick: onCopyClick,
          textbox: textbox,
          hint: 'copyToClipboard' | translate,
          tabIndex: -1,
          visible:
            control.options.showCopyButton != undefined
              ? control.options.showCopyButton
              : false
        }"
      ></dxi-button>

      <dxi-button
        name="openLink"
        [options]="{
          icon: 'fal fa-link',
          disabled: false,
          onClick: onLinkClick,
          textbox: textbox,
          tabIndex: -1,
          visible:
            control.options.showLinkButton != undefined
              ? textbox.text !== '' && textbox.text != undefined
              : false
        }"
      ></dxi-button>

      <dxi-button
        name="mailTo"
        [options]="{
          icon: 'fal fa-envelope',
          disabled: false,
          textbox: textbox,
          tabIndex: -1,
          onClick: onMailtoClick,
          visible:
            control.options.showMailToButton != undefined
              ? textbox.text !== '' && textbox.text != undefined
              : false
        }"
      ></dxi-button>

      <dxi-button
        name="actionLink"
        [options]="{
          icon: control.options.iconActionButton,
          disabled: false,
          onClick: onButtonActionClick,
          textbox: textbox,
          tabIndex: -1,
          visible:
            control.options.showActionToButton != undefined
              ? control.options.showActionToButton
              : false
        }"
      ></dxi-button>

      <dxi-button
        name="setDefault"
        [options]="{
          icon: 'fal fa-undo',
          disabled: false,
          hint: 'setDefault' | translate,
          textbox: textbox,
          tabIndex: -1,
          onClick: onDefaultClick,
          visible: isSystemValue && control.fieldName === 'name'
        }"
      ></dxi-button>

      <dxi-mention
        [valueExpr]="control.options?.mentions?.valueExpr"
        [displayExpr]="control.options?.mentions?.displayExpr"
        [searchExpr]="control.options?.mentions?.searchExpr"
        [dataSource]="mentionDataSource"
      ></dxi-mention>
    </dx-text-box>

    <dx-popover
      *clcSwitchOnlyOneCase="'TextBoxComponent'"
      [target]="target?.element.nativeElement"
      position="bottom"
      [width]="'auto'"
      [visible]="
        defaultVisible === control.name &&
        text != undefined &&
        text !== '' &&
        control.options.mode !== 'password'
      "
    >
      <div *dxTemplate="let data = model; of: 'content'">
        {{ text }}
      </div>
    </dx-popover>
    <clc-memo-area
      *clcSwitchOnlyOneCase="'MemoAreaComponent'"
      [value]="control.defaultValue"
      [height]="control.height"
      [contentType]="control.contentType"
      [formControlName]="control.name"
    >
    </clc-memo-area>
    <dx-text-area
      #textbox
      *clcSwitchOnlyOneCase="'TextAreaComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      [inputAttr]="{ autocomplete: 'new-password' }"
      [placeholder]="control.emptyText"
      [value]="control.defaultValue"
      [readOnly]="control.readOnly"
      [height]="control.height"
      [maxLength]="control.options.maxLength"
      [formControlName]="control.name"
      valueChangeEvent="keyup"
      validationMessageMode="always"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    >
      <dxi-button name="clear"></dxi-button>
      <dxi-button
        name="copy"
        [options]="{
          icon: 'fal fa-copy',
          disabled: false,
          onClick: onCopyClick,
          textbox: textbox,
          hint: 'copyToClipboard' | translate,
          tabIndex: -1,
          visible:
            control.options.showCopyButton != undefined
              ? control.options.showCopyButton
              : false
        }"
      ></dxi-button>

      <dxi-button
        name="openLink"
        [options]="{
          icon: 'fal fa-link',
          disabled: false,
          onClick: onLinkClick,
          textbox: textbox,
          tabIndex: -1,
          visible:
            control.options.showLinkButton != undefined
              ? textbox.text !== '' && textbox.text != undefined
              : false
        }"
      ></dxi-button>

      <dxi-button
        name="mailTo"
        [options]="{
          icon: 'fal fa-envelope',
          disabled: false,
          textbox: textbox,
          tabIndex: -1,
          onClick: onMailtoClick,
          visible:
            control.options.showMailToButton != undefined
              ? textbox.text !== '' && textbox.text != undefined
              : false
        }"
      ></dxi-button>
    </dx-text-area>

    <dx-number-box
      *clcSwitchOnlyOneCase="'NumberBoxComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event, control)"
      [placeholder]="control.emptyText"
      [value]="control.defaultValue"
      [format]="control.options.format"
      [readOnly]="control.readOnly"
      [min]="
        control.options.min != undefined ? control.options.min : -2147483647
      "
      [max]="
        control.options.max != undefined ? control.options.max : 2147483647
      "
      [showSpinButtons]="true"
      [showClearButton]="control.options.showClearButton"
      [useLargeSpinButtons]="false"
      valueChangeEvent="keyup"
      validationMessageMode="always"
      style="width: 50%"
      [formControlName]="control.name"
      (onFocusOut)="onFocusOutNumberBox($event, control)"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    ></dx-number-box>

    <dx-date-box
      *clcSwitchOnlyOneCase="'DateTimeComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      (onFocusOut)="
        onDateTimeComponentFocusOut($event, getFormControl(control.name))
      "
      [placeholder]="control.emptyText"
      [readOnly]="control.readOnly"
      [min]="control.options.min"
      [max]="control.options.max"
      [interval]="control.options.interval"
      [showClearButton]="control.options.showClearButton"
      [displayFormat]="control.options.displayFormat"
      [useMaskBehavior]="true"
      [dateSerializationFormat]="control.options.dateSerializationFormat"
      [acceptCustomValue]="
        control.options.acceptCustomValue != undefined
          ? control.options.acceptCustomValue
          : true
      "
      [disabled]="control.readOnly && isMobile ? true : false"
      [type]="control.options.type"
      [openOnFieldClick]="false"
      validationMessageMode="always"
      style="width: 100%"
      [formControlName]="control.name"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    ></dx-date-box>

    <dx-calendar
      *clcSwitchOnlyOneCase="'CalendarComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      [value]="control.defaultValue"
      [readOnly]="control.readOnly"
      [min]="control.options.min"
      [max]="control.options.max"
      validationMessageMode="always"
      style="width: 100%"
      [formControlName]="control.name"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    ></dx-calendar>

    <dx-color-box
      *clcSwitchOnlyOneCase="'ColorBoxComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event, control)"
      [formControlName]="control.name"
      [value]="control.defaultValue"
      [readOnly]="control.readOnly"
      [placeholder]="'globals/colorChoice' | translate"
      [showDropDownButton]="false"
      [showClearButton]="true"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
      [editAlphaChannel]="control.editAlphaChannel"
      [cpSaveClickOutside]="false"
      [cpPresetLabel]="'clHtmlEditor-defaultColor' | translate"
      [(colorPicker)]="control.defaultValue"
      [cpPresetColors]="arrayColors"
      [cpDialogDisplay]="'popup'"
      [cpHeight]="'515px'"
      [cpOKButton]="true"
      [cpOKButtonText]="'ok' | translate"
      [cpOKButtonClass]="'dx-button dx-button-mode-text'"
      [cpCancelButtonText]="'cancel' | translate"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'dx-button dx-button-mode-text'"
    />

    <dx-check-box
      *clcSwitchOnlyOneCase="'CheckBoxComponent'"
      [value]="control.defaultValue"
      [readOnly]="control.readOnly"
      [visible]="control.visible !== undefined ? control.visible : true"
      validationMessageMode="always"
      style="width: 100%"
      [formControlName]="control.name"
      (onInitialized)="onInitializedCheckBox($event, control)"
      (onValueChanged)="onValueChanged($event, control)"
      [text]="control.options?.text"
      (onContentReady)="onCheckboxContentReady($event, control)"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    ></dx-check-box>
    <clc-translate-field
      *clcSwitchOnlyOneCase="'TranslateFieldComponent'"
      [formControlName]="control.name"
      [state]="state"
      [control]="control"
      [readOnly]="control.readOnly"
    >
    </clc-translate-field>
    <dx-switch
      *clcSwitchOnlyOneCase="'SwitchComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      [value]="control.defaultValue"
      [readOnly]="control.readOnly"
      valueChangeEvent="keyup"
      validationMessageMode="always"
      [formControlName]="control.name"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    ></dx-switch>
    <clc-html-editor
      *clcSwitchOnlyOneCase="'HtmlEditorComponent'"
      [control]="control"
      [fg]="parent.form"
      [state]="state"
    >
    </clc-html-editor>
    <clc-html-editor-translated
      *clcSwitchOnlyOneCase="'HtmlEditorTranslatedComponent'"
      [control]="control"
      [formControlName]="control.name"
      [fg]="parent.form"
      [state]="state"
    >
    </clc-html-editor-translated>
    <clc-html-editor-translated
      *clcSwitchOnlyOneCase="'HtmlEditorSimpleTranslatedComponent'"
      [control]="control"
      [formControlName]="control.name"
      [fg]="parent.form"
      [state]="state"
    >
    </clc-html-editor-translated>
    <clc-html-editor
      *clcSwitchOnlyOneCase="'HtmlEditorSimpleComponent'"
      [control]="control"
      [fg]="parent.form"
      [state]="state"
    >
    </clc-html-editor>

    <dx-radio-group
      *clcSwitchOnlyOneCase="'RadioButtonComponent'"
      [layout]="control.options.layout"
      [dataSource]="control.options.source"
      [displayExpr]="control.options.displayExpr"
      [valueExpr]="control.options.valueExpr"
      [value]="control.defaultValue"
      [readOnly]="control.readOnly"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
      [formControlName]="control.name"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
    >
    </dx-radio-group>
    <dx-select-box
      *clcSwitchOnlyOneCase="'SelectBoxComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      [readOnly]="control.readOnly"
      [placeholder]="control.emptyText"
      [value]="control.defaultValue"
      [displayExpr]="control.options.displayExpr"
      [valueExpr]="control.options.valueExpr"
      [searchExpr]="control.options.searchExpr"
      [showClearButton]="control.options.showClearButton"
      [searchMode]="control.options.searchMode"
      [searchEnabled]="control.options.searchExpr !== undefined"
      [dataSource]="control.options.source"
      [formControlName]="control.name"
      [itemTemplate]="'item'"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    >
      <!-- Application des différents templates -->
      <!-- changement template a tester BM -->
      <ng-container>
        <div
          *dxTemplate="
            let data of 'item'
              | filter : 'control.options.template' : undefined : true
          "
        >
          <ng-template
            *ngTemplateOutlet="
              templateService.get(control.options.template);
              context: { $implicit: data }
            "
          >
          </ng-template>
        </div>
      </ng-container>
    </dx-select-box>
    <dx-autocomplete
      *clcSwitchOnlyOneCase="'AutoCompleteBoxComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      [readOnly]="control.readOnly"
      [placeholder]="control.emptyText"
      [value]="control.defaultValue"
      [dataSource]="control.options.source['datasource']"
      [valueExpr]="control.options.valueExpr"
      [searchExpr]="control.options.searchExpr"
      [showClearButton]="control.options.showClearButton"
      [searchMode]="control.options.searchMode"
      [formControlName]="control.name"
      [openOnFieldClick]="true"
      [minSearchLength]="0"
      [maxItemCount]="10"
      [showDropDownButton]="true"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
      [inputAttr]="{ autocomplete: 'new-password' }"
    >
    </dx-autocomplete>

    <clc-simple-list
      *clcSwitchOnlyOneCase="'SimpleListComponent'"
      [fieldName]="control.name"
      [formControlName]="control.name"
      [state]="state"
      [readOnly]="control.readOnly"
      [allowAdding]="control.options.allowAdding"
      [batchMode]="control.options.batchMode"
      [allowDelete]="control.options.allowDeleting"
      [columns]="control.options.columns"
      [showColumnHeaders]="control.options.showColumnHeaders"
      [generateId]="control.options.generateId"
      [source]="control.options?.source"
    >
    </clc-simple-list>

    <clc-select-simple-list
      *clcSwitchOnlyOneCase="'SelectSimpleListComponent'"
      [fieldName]="control.name"
      [formControlName]="control.name"
      [state]="state"
      [readOnly]="control.readOnly"
      [allowAdding]="control.options.allowAdding"
      [batchMode]="control.options.batchMode"
      [allowDelete]="control.options.allowDeleting"
      [columns]="control.options.columns"
      [showColumnHeaders]="control.options.showColumnHeaders"
      [multiple]="control.options.multiple"
      [generateId]="control.options.generateId"
      [source]="control.options?.source"
      [showFilterRow]="control.options.showFilterRow"
    >
    </clc-select-simple-list>

    <cl-tagBox
      *clcSwitchOnlyOneCase="'TagBoxComponent'"
      [control]="control"
      [formControlName]="control.name"
      [translatable]="control.options?.translatable"
      [displayExpr]="control.options?.displayExpr"
    >
    </cl-tagBox>

    <cl-tagBox-custom-item
      *clcSwitchOnlyOneCase="'TagBoxCustomItemComponent'"
      [control]="control"
      [formControlName]="control.name"
    >
    </cl-tagBox-custom-item>

    <cl-license
      *clcSwitchOnlyOneCase="'LicenseComponent'"
      [formControlName]="control.name"
    ></cl-license>

    <clc-link
      *clcSwitchOnlyOneCase="'LinkComponent'"
      [debugName]="control.name"
      [valueExpr]="control.options.valueExpr"
      [isDynamicProperty]="control.isDynamicProperty"
      [filters]="control.options.filters"
      [readOnly]="control.readOnly"
      [displayExpr]="control.options.displayExpr"
      [clearVisibility]="control?.options?.clearVisibility"
      [source]="control.options.source.datasource"
      [columns]="control.options.columns"
      [type]="control.options.type"
      [route]="control.options.route"
      [template]="control.options.template"
      [formControlName]="control.name"
      [params]="control.options.params"
      [filterList]="control.options.filterList"
      [showOnEnlargeClick]="control.options.showOnEnlargeClick"
      [triggerPopupOnEnlargeClick]="control.options.triggerPopupOnEnlargeClick"
      [titleAssociate]="
        control?.titleAssociate !== undefined
          ? control.titleAssociate
          : control.label
      "
      [forceFilter]="control.options['forceFilter']"
      [clearFilterOnClick]="control.options['clearFilterOnClick']"
      [enabledExp]="control.options.enabledExp"
      [itemHint]="control.options.itemHint"
      [translatable]="control.options.translatable"
      [class_zindex_popup]="control.options.class_zindex_popup"
      [iRefDisplayExpr]="control?.iRefExpr"
      [iRefValueExpr]="control?.fieldName"
      [modelState]="state"
      [useClass]="control?.options?.useClass"
      [customDisplayExpr]="control?.options?.customDisplayExpr"
      [showClearButton]="
        control?.options?.showClearButton != undefined
          ? control.options.showClearButton
          : true
      "
    ></clc-link>
    <dx-drop-down-box
      *clcSwitchOnlyOneCase="'DropDownBoxComponent'"
      (onInitialized)="onComponentInitialized($event, control)"
      (onValueChanged)="onValueChanged($event)"
      #dropdown
      [readOnly]="control.readOnly"
      [placeholder]="control.emptyText"
      [value]="
        !control.options.multiple &&
        control.defaultValue &&
        control.defaultValue.length > 0
          ? control.defaultValue[0]
          : control.defaultValue
      "
      [dataSource]="control.options.source"
      [showClearButton]="control.options.showClearButton"
      [displayExpr]="control.options.displayExpr"
      [valueExpr]="control.options.valueExpr"
      validationMessageMode="always"
      valueChangeEvent="keyup"
      [openOnFieldClick]="true"
      [formControlName]="control.name"
      (onValueChanged)="dropDownReset($event, control)"
      [isValid]="
        parent.form.root.loaded === false ||
        getFormControl(control.name).enabled === false ||
        !getFormControl(control.name).errors
      "
    >
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid
          *ngIf="control.options.mode === 'Grid'"
          [showColumnHeaders]="
            control.options.showColumnHeaders !== undefined
              ? control.options.showColumnHeaders
              : true
          "
          [keyExpr]="
            control.options.source !== undefined &&
            control.options.source.length
              ? control.options.valueExpr
              : undefined
          "
          [dataSource]="control.options.source"
          [selection]="{
            mode: control.options.multiple ? 'multiple' : 'single',
            showCheckBoxesMode: 'always'
          }"
          [hoverStateEnabled]="true"
          [(selectedRowKeys)]="control.defaultValue"
          [remoteOperations]="
            control.options.source !== undefined &&
            control.options.source.length
              ? undefined
              : { paging: true, filtering: true, sorting: true }
          "
          [height]="'100%'"
          [showRowLines]="
            control.options.showRowLines !== undefined
              ? control.options.showRowLines
              : true
          "
          [columnAutoWidth]="true"
          (onSelectionChanged)="
            control.options.onSelectionChanged !== undefined
              ? null
              : dropdown.instance.close()
          "
        >
          <dxi-column
            *ngFor="let column of control.options.columns"
            [dataField]="column.field"
            [caption]="column.label"
          >
          </dxi-column>
        </dx-data-grid>
        <dx-tree-list
          *ngIf="control.options.mode === 'Tree'"
          [dataSource]="control.options.source"
          [(selectedRowKeys)]="control.defaultValue"
          [columnAutoWidth]="true"
          [keyExpr]="
            control.options.source !== undefined &&
            control.options.source.length
              ? control.options.valueExpr
              : undefined
          "
          [parentIdExpr]="control.options.parentIdExpr"
          dataStructure="plain"
          [rootValue]="null"
          height="100%"
          [remoteOperations]="
            control.options.source !== undefined &&
            control.options.source.length
              ? undefined
              : { filtering: false, sorting: true }
          "
          [showRowLines]="false"
          (onSelectionChanged)="
            control.options.multiple ? undefined : dropdown.instance.close()
          "
        >
          <dxo-selection
            [mode]="control.options.multiple ? 'multiple' : 'single'"
            [recursive]="control.options.recursive"
          ></dxo-selection>
          <dxi-column
            *ngFor="let column of control.options.columns"
            [dataField]="column.field"
            [caption]="column.label"
          >
          </dxi-column>
        </dx-tree-list>
      </div>
    </dx-drop-down-box>

    <clc-tag-box-email
      *clcSwitchOnlyOneCase="'TagBoxEmailComponent'"
      [formControlName]="control.name"
      [source]="control.options?.source"
      [loadRoles]="
        control.options?.loadRoles == undefined
          ? false
          : control.options.loadRoles
      "
      [loadUsers]="
        control.options?.loadUsers == undefined
          ? false
          : control.options.loadUsers
      "
      [loadConnectors]="
        control.options?.loadConnectors == undefined
          ? false
          : control.options.loadConnectors
      "
      [onlyVisibleHelpDesk]="
        control.options?.onlyVisibleHelpDesk == undefined
          ? false
          : control.options.onlyVisibleHelpDesk
      "
    >
    </clc-tag-box-email>

    <!-- <dx-html-editor [ngClass]="'htmleditorsimple'" *clcSwitchOnlyOneCase="'HtmlEditorSimpleComponente'"
    (onInitialized)="onComponentInitialized($event, control)" (onContentReady)="onContentReady($event)"
    (onValueChanged)="onValueChanged($event)" [placeholder]="control.emptyText" [value]="control.defaultValue"
    valueType="Markdown" [readOnly]="control.readOnly" style="height: 44px" [formControlName]="control.name"
    (onFocusIn)="onFocusIn($event, control.name)" [isValid]="
      parent.form.root.loaded === false ||
      getFormControl(control.name).enabled === false ||
      !getFormControl(control.name).errors
    ">
    <dxi-mention [valueExpr]="control.options?.mentions?.valueExpr"
      [displayExpr]="control.options?.mentions?.displayExpr" [searchExpr]="control.options?.mentions?.searchExpr"
      [dataSource]="control.options?.mentions?.source || mentionDataSource"></dxi-mention>
    <dxo-toolbar [multiline]="
        control.options?.multiline == undefined
          ? false
          : control.options?.multiline
      ">
      <dxi-item name="insertTable" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="deleteTable" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="insertRowAbove" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="insertRowBelow" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="deleteRow" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="insertColumnLeft" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="insertColumnRight" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="deleteColumn" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="cellProperties" *ngIf="control.options?.enableTable"></dxi-item>
      <dxi-item name="tableProperties" *ngIf="control.options?.enableTable"></dxi-item>
    </dxo-toolbar>
  </dx-html-editor> -->

    <clc-matrix
      *clcSwitchOnlyOneCase="'MatrixComponent'"
      [formControlName]="control.name"
      [modelState]="state"
    >
    </clc-matrix>

    <clc-work-time
      *clcSwitchOnlyOneCase="'WorkTimeComponent'"
      [formControlName]="control.name"
      [modelState]="state"
      [useTicks]="control.useTicks"
    >
    </clc-work-time>

    <clc-file-uploader
      *clcSwitchOnlyOneCase="'FileUploaderComponent'"
      [formControlName]="control.name"
      [removeItems]="control.options.removeItems"
      [addItems]="control.options.addItems"
      [modelState]="state"
      [dynamicFieldName]="control.options.dynamicFieldName"
      [source]="control.options.source"
      [readOnly]="control.readOnly"
    >
    </clc-file-uploader>

    <clc-file-manager
      *clcSwitchOnlyOneCase="'FileManagerComponent'"
      [formControlName]="control.name"
      [source]="control.options.source"
      [canRemove]="control.options.canDelete"
      [readOnly]="control.readOnly"
      [canCommentary]="control.options.canCommentary"
      [canVisibleHelpDesk]="control.options.canVisibleHelpDesk"
      [canOutgoingEmails]="control.options.canOutgoingEmails"
      [modelState]="state"
    >
    </clc-file-manager>

    <clc-password
      *clcSwitchOnlyOneCase="'PasswordComponent'"
      [formControlName]="control.name"
      [title]="control.label"
      [type]="control.fieldName"
      [text]="'entities/networkCredential/reset' | translate"
      [resetPassword]="control.options.resetPassword"
    ></clc-password>
    <clc-day-off
      *clcSwitchOnlyOneCase="'DayOffComponent'"
      [formControlName]="control.name"
      [modelState]="state"
    >
    </clc-day-off>

    <clc-column-chooser
      *clcSwitchOnlyOneCase="'ColumnChooserComponent'"
      [formControlName]="control.name"
      [modelState]="state"
      [includeColunms]="control.options?.includeColunms"
    ></clc-column-chooser>

    <clc-field-chooser
      *clcSwitchOnlyOneCase="'FieldChooserComponent'"
      [formControlName]="control.name"
      [fieldType]="control?.options?.type"
      [modelState]="state"
      [useRemoveFieldsArray]="control?.options?.useRemoveFieldsArray"
      [addFields]="control?.options?.addFields"
    ></clc-field-chooser>
  </clc-work-item-field>
</div>
