import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketStatisticsBaseVariables, FirstTicketStatisticsBaseVariables, CountTicketStatisticsBaseVariables, FindTicketStatisticsBaseVariables, SearchTicketStatisticsBaseVariables, ExportSchemaTicketStatisticsBaseVariables, ExportDataTicketStatisticsBaseVariables, CustomQueryTicketStatisticsBaseVariables, CustomQueryIdTicketStatisticsBaseVariables, UsedTicketStatisticsBaseVariables, FindWhithoutTicketDeletedTicketStatisticsBaseVariables, ExistTicketStatisticsBaseVariables, FindRecyclesTicketStatisticsBaseVariables, CountRecyclesTicketStatisticsBaseVariables, ReadOnlyTicketStatisticsBaseVariables, FindArchivedTicketStatisticsBaseVariables, CountAllTicketStatisticsBaseVariables, FindDynamicPropertyFieldsTicketStatisticsBaseVariables, InsertTicketStatisticsBaseVariables, UpdateTicketStatisticsBaseVariables, ImportDataTicketStatisticsBaseVariables, UpdateManyTicketStatisticsBaseVariables, DeleteTicketStatisticsBaseVariables, RestoreTicketStatisticsBaseVariables, RecycleTicketStatisticsBaseVariables, RestoreArchivedTicketStatisticsBaseVariables, ArchivedTicketStatisticsBaseVariables, AddFileDynamicFieldTicketStatisticsBaseVariables, RemoveFileDynamicFieldTicketStatisticsBaseVariables } from '../gqls'
import { GetTicketStatisticsDocument, FirstTicketStatisticsDocument, CountTicketStatisticsDocument, FindTicketStatisticsDocument, SearchTicketStatisticsDocument, ExportSchemaTicketStatisticsDocument, ExportDataTicketStatisticsDocument, CustomQueryTicketStatisticsDocument, CustomQueryIdTicketStatisticsDocument, UsedTicketStatisticsDocument, FindWhithoutTicketDeletedTicketStatisticsDocument, ExistTicketStatisticsDocument, FindRecyclesTicketStatisticsDocument, CountRecyclesTicketStatisticsDocument, ReadOnlyTicketStatisticsDocument, FindArchivedTicketStatisticsDocument, CountAllTicketStatisticsDocument, FindDynamicPropertyFieldsTicketStatisticsDocument, InsertTicketStatisticsDocument, UpdateTicketStatisticsDocument, ImportDataTicketStatisticsDocument, UpdateManyTicketStatisticsDocument, DeleteTicketStatisticsDocument, RestoreTicketStatisticsDocument, RecycleTicketStatisticsDocument, RestoreArchivedTicketStatisticsDocument, ArchivedTicketStatisticsDocument, AddFileDynamicFieldTicketStatisticsDocument, RemoveFileDynamicFieldTicketStatisticsDocument } from '../gqls'
import { ServiceSingleResultOfTicketStatistic, QueryContextOfTicketStatistic, FilterOfTicketStatistic, ServiceSingleResultOfInt64, ServiceListResultOfTicketStatistic, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicketStatistic, TicketStatisticInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketStatisticBaseService {
    
public modelName = 'ticketStatistic';
public modelPluralName = 'ticketStatistics';

	private getTicketStatisticsQuery: GetTicketStatisticsDocument;
	private firstTicketStatisticsQuery: FirstTicketStatisticsDocument;
	private countTicketStatisticsQuery: CountTicketStatisticsDocument;
	private findTicketStatisticsQuery: FindTicketStatisticsDocument;
	private searchTicketStatisticsQuery: SearchTicketStatisticsDocument;
	private exportSchemaTicketStatisticsQuery: ExportSchemaTicketStatisticsDocument;
	private exportDataTicketStatisticsQuery: ExportDataTicketStatisticsDocument;
	private customQueryTicketStatisticsQuery: CustomQueryTicketStatisticsDocument;
	private customQueryIdTicketStatisticsQuery: CustomQueryIdTicketStatisticsDocument;
	private usedTicketStatisticsQuery: UsedTicketStatisticsDocument;
	private findWhithoutTicketDeletedTicketStatisticsQuery: FindWhithoutTicketDeletedTicketStatisticsDocument;
	private existTicketStatisticsQuery: ExistTicketStatisticsDocument;
	private findRecyclesTicketStatisticsQuery: FindRecyclesTicketStatisticsDocument;
	private countRecyclesTicketStatisticsQuery: CountRecyclesTicketStatisticsDocument;
	private readOnlyTicketStatisticsQuery: ReadOnlyTicketStatisticsDocument;
	private findArchivedTicketStatisticsQuery: FindArchivedTicketStatisticsDocument;
	private countAllTicketStatisticsQuery: CountAllTicketStatisticsDocument;
	private findDynamicPropertyFieldsTicketStatisticsQuery: FindDynamicPropertyFieldsTicketStatisticsDocument;
	private insertTicketStatisticsMutation: InsertTicketStatisticsDocument;
	private updateTicketStatisticsMutation: UpdateTicketStatisticsDocument;
	private importDataTicketStatisticsMutation: ImportDataTicketStatisticsDocument;
	private updateManyTicketStatisticsMutation: UpdateManyTicketStatisticsDocument;
	private deleteTicketStatisticsMutation: DeleteTicketStatisticsDocument;
	private restoreTicketStatisticsMutation: RestoreTicketStatisticsDocument;
	private recycleTicketStatisticsMutation: RecycleTicketStatisticsDocument;
	private restoreArchivedTicketStatisticsMutation: RestoreArchivedTicketStatisticsDocument;
	private archivedTicketStatisticsMutation: ArchivedTicketStatisticsDocument;
	private addFileDynamicFieldTicketStatisticsMutation: AddFileDynamicFieldTicketStatisticsDocument;
	private removeFileDynamicFieldTicketStatisticsMutation: RemoveFileDynamicFieldTicketStatisticsDocument;

	constructor(private injector: Injector) {
		this.getTicketStatisticsQuery = this.injector.get(GetTicketStatisticsDocument);
		this.firstTicketStatisticsQuery = this.injector.get(FirstTicketStatisticsDocument);
		this.countTicketStatisticsQuery = this.injector.get(CountTicketStatisticsDocument);
		this.findTicketStatisticsQuery = this.injector.get(FindTicketStatisticsDocument);
		this.searchTicketStatisticsQuery = this.injector.get(SearchTicketStatisticsDocument);
		this.exportSchemaTicketStatisticsQuery = this.injector.get(ExportSchemaTicketStatisticsDocument);
		this.exportDataTicketStatisticsQuery = this.injector.get(ExportDataTicketStatisticsDocument);
		this.customQueryTicketStatisticsQuery = this.injector.get(CustomQueryTicketStatisticsDocument);
		this.customQueryIdTicketStatisticsQuery = this.injector.get(CustomQueryIdTicketStatisticsDocument);
		this.usedTicketStatisticsQuery = this.injector.get(UsedTicketStatisticsDocument);
		this.findWhithoutTicketDeletedTicketStatisticsQuery = this.injector.get(FindWhithoutTicketDeletedTicketStatisticsDocument);
		this.existTicketStatisticsQuery = this.injector.get(ExistTicketStatisticsDocument);
		this.findRecyclesTicketStatisticsQuery = this.injector.get(FindRecyclesTicketStatisticsDocument);
		this.countRecyclesTicketStatisticsQuery = this.injector.get(CountRecyclesTicketStatisticsDocument);
		this.readOnlyTicketStatisticsQuery = this.injector.get(ReadOnlyTicketStatisticsDocument);
		this.findArchivedTicketStatisticsQuery = this.injector.get(FindArchivedTicketStatisticsDocument);
		this.countAllTicketStatisticsQuery = this.injector.get(CountAllTicketStatisticsDocument);
		this.findDynamicPropertyFieldsTicketStatisticsQuery = this.injector.get(FindDynamicPropertyFieldsTicketStatisticsDocument);
		this.insertTicketStatisticsMutation = this.injector.get(InsertTicketStatisticsDocument);
		this.updateTicketStatisticsMutation = this.injector.get(UpdateTicketStatisticsDocument);
		this.importDataTicketStatisticsMutation = this.injector.get(ImportDataTicketStatisticsDocument);
		this.updateManyTicketStatisticsMutation = this.injector.get(UpdateManyTicketStatisticsDocument);
		this.deleteTicketStatisticsMutation = this.injector.get(DeleteTicketStatisticsDocument);
		this.restoreTicketStatisticsMutation = this.injector.get(RestoreTicketStatisticsDocument);
		this.recycleTicketStatisticsMutation = this.injector.get(RecycleTicketStatisticsDocument);
		this.restoreArchivedTicketStatisticsMutation = this.injector.get(RestoreArchivedTicketStatisticsDocument);
		this.archivedTicketStatisticsMutation = this.injector.get(ArchivedTicketStatisticsDocument);
		this.addFileDynamicFieldTicketStatisticsMutation = this.injector.get(AddFileDynamicFieldTicketStatisticsDocument);
		this.removeFileDynamicFieldTicketStatisticsMutation = this.injector.get(RemoveFileDynamicFieldTicketStatisticsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatistic> {
    
    		let variables: GetTicketStatisticsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.get));
            }
            else{
                let result:ServiceSingleResultOfTicketStatistic={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatistic> {
    
    		let variables: FirstTicketStatisticsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketStatisticsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: FindTicketStatisticsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: SearchTicketStatisticsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketStatisticsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketStatisticsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: CustomQueryTicketStatisticsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: CustomQueryIdTicketStatisticsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketStatisticsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.used));
    	}

    	/**  */
    	@InjectArgs
    	public findWhithoutTicketDeleted(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: FindWhithoutTicketDeletedTicketStatisticsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findWhithoutTicketDeletedTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.findWhithoutTicketDeleted));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketStatisticsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: FindRecyclesTicketStatisticsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTicketStatisticsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTicketStatisticsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatistic,
		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatistic> {
    
    		let variables: FindArchivedTicketStatisticsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTicketStatisticsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTicketStatisticsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTicketStatisticsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketStatisticInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatistic> {
    
    		let variables: InsertTicketStatisticsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatistic> {
    
    		let variables: UpdateTicketStatisticsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketStatisticsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatistic,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketStatisticsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketStatisticsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTicketStatisticsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTicketStatisticsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTicketStatisticsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTicketStatisticsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTicketStatisticsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTicketStatisticsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTicketStatisticsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatistics.removeFileDynamicField));
    	}
    
}