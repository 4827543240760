import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { EnvironmentService } from '@clarilog/core/services/environment';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { CoreFeatureService } from '@clarilog/shared2/services/features/feature.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Observable } from 'rxjs';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { RoleBaseService } from '../service-bases';
import {
  FilterOfRole,
  QueryContextOfRole,
  ServiceListResultOfRole,
  ServiceSingleResultOfBoolean,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('administration.permission')
export class RoleCoreService extends RoleBaseService {
  constructor(
    injector: Injector,
    private envService: EnvironmentService,
    public featureService: CoreFeatureService,
  ) {
    super(injector);
  }

  /** Recherche les éléments par défaut. */
  @InjectArgs
  public findDefaults(
    @Args('fields') fields: GqlFields,
  ): Observable<ServiceListResultOfRole> {
    let filter: FilterOfRole = { key: { eq: 'valid-user' } };

    return this.find(fields, null, filter);
  }

  /** Vérifie si la valeur du champ existe sur une entité. */
  @InjectArgs
  public existCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: string,
    @Args('parentId?') parentId?: string,
    @Args('parentFieldName?') parentFieldName?: string,
    @Args('fieldValue?') fieldValue?: string,
    @Args('fieldName?') fieldName?: string,
    @Args('excludeId?') excludeId?: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    type = '';
    return this.exist(
      fields,
      type,
      parentId,
      parentFieldName,
      fieldValue,
      fieldName,
      excludeId,
      extendedVariables,
    );
  }

  async getRoleType() {
    let sd = await this.featureService.hasServiceDeskAsync();

    let type = [
      {
        id: 'Manager',
        name: TranslateService.get('entities/role/managerTypeLabel'),
      },
    ];
    if (sd) {
      type.push({
        id: 'HelpDeskOperator',
        name: TranslateService.get('entities/role/operatorTypeLabel'),
      });
      type.push({
        id: 'HelpDeskUser',
        name: TranslateService.get('entities/role/userTypeLabel'),
      });
    }
    return type;
  }

  @InjectArgs
  findManagerOrOperatorRole(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfRole,
    @Args('filter?') filter?: FilterOfRole,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    filter = {
      or: [
        {
          type: {
            eq: 'Operator',
          },
        },
        {
          type: {
            eq: 'Manager',
          },
        },
      ],
    };
    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  findOperatorRole(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfRole,
    @Args('filter?') filter?: FilterOfRole,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    filter = {
      type: {
        eq: 'Operator',
      },
    };

    return this.find(fields, options, filter, extendedVariables);
  }
}
