import { GqlField } from '../helpers';
import { MailboxObserverLogBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { FileManagerCoreService } from './file-manager.service';

@Injectable({ providedIn: 'root' })
export class MailboxObserverLogCoreService extends MailboxObserverLogBaseService {
  constructor(
    injector: Injector,
    public fileManagerService: FileManagerCoreService,
  ) {
    super(injector);
  }

  async download(fileId, fileName) {
    if (fileId != undefined) {
      let fields = [GqlField.create('data')];

      var sourceFile = await this.fileManagerService
        .downloadFile(fields, fileId)
        .toPromise();
      var b64Data = sourceFile.data;
      var bin = atob(b64Data);
      const contentType = 'message/rfc822';
      const byteNumbers = new Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        byteNumbers[i] = bin.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: contentType });

      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    }
  }
}
